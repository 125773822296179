import React, { useCallback, useEffect, useState } from 'react'
import BodyClassName from 'react-body-classname'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Helmet } from 'react-helmet'
import { useHistory, withRouter } from 'react-router'

import useCommunityIdOrSlug from '../../hooks/useCommunityIdOrSlug'
import Routes from '../../startup/routes'
import { isEmbedded, LocalStorage } from '../../util/window'

const RESOURCES = ['events', 'headlines', 'stories', 'places'] as const
const RESOURCE_PLURAL = {
  event: 'events',
  headline: 'headlines',
  story: 'stories',
  place: 'places',
} as const
const RESOURCE_SINGULAR = {
  events: 'event',
  headlines: 'headline',
  stories: 'story',
  places: 'place',
} as const

const App: React.FC = ({ children }) => {
  const history = useHistory()
  const communitySlug = useCommunityIdOrSlug()
  const [parentInitiatedNavigation, setParentInitiatedNavigation] = useState(false)

  useEffect(() => {
    communitySlug && LocalStorage.setItem('customDomainOrigin', communitySlug)
  }, [communitySlug])

  const receiveMessage = useCallback(
    event => {
      if (!event || !event.data || event.data === 'undefined' || typeof event.data !== 'string') return

      try {
        const messageData = JSON.parse(event.data)
        switch (messageData.type) {
          case 'NAVIGATE_FORWARD':
            setParentInitiatedNavigation(true)
            const href = `${Routes.optional(communitySlug)}${messageData.href}`
            history.push(href)
            break
          default:
            break
        }
      } catch (e) {
        window.Rollbar && window.Rollbar.error(e, { eventData: event.data })
      }
    },
    [history, communitySlug],
  )

  useEffect(() => {
    let unlisten

    if (isEmbedded()) {
      window.addEventListener('message', receiveMessage)
      unlisten = history.listen(location => {
        if (parentInitiatedNavigation) {
          setParentInitiatedNavigation(false)
          return
        }

        let recordSlug
        const urlPaths = location.pathname.split('/') as string[]
        let resourceName: (typeof RESOURCE_SINGULAR)[keyof typeof RESOURCE_SINGULAR] | undefined
        urlPaths.forEach(urlPath => {
          if (RESOURCES.indexOf(urlPath as keyof typeof RESOURCE_SINGULAR) !== -1) {
            // keep resourceName in singular
            resourceName = RESOURCE_SINGULAR[urlPath as keyof typeof RESOURCE_SINGULAR]
          } else if (resourceName) {
            recordSlug = urlPath
          }
        })

        if (resourceName) {
          // /events?event=event-slug
          let href = `/${RESOURCE_PLURAL[resourceName]}`
          if (location.search) href += location.search
          if (recordSlug) href += `${location.search ? '&' : '?'}${resourceName}=${recordSlug}`

          // /feeds/:id/events
          // /feeds/:feedId/events/:id
          // if (location.pathname.startsWith('/feeds')) {
          //   resourceName = 'event'
          //   recordSlug = urlPaths.at(-1)
          // }
          // location.search && (href += location.search)

          window.parent.postMessage(JSON.stringify({ type: 'NAVIGATE_FORWARD', href }), '*')
        }
      })
    }

    return () => {
      if (isEmbedded()) {
        window.removeEventListener('message', receiveMessage)
        unlisten()
      }
    }
  }, [parentInitiatedNavigation, history, receiveMessage])

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <Helmet defaultTitle="CivicLift - Community Website & Engagement Platform" titleTemplate="%s | CivicLift">
          <html lang="en" />
          <meta
            name="keywords"
            content="civiclift,community,community website,communities,social,community events,community stories, community news, community places"
          />
          <meta
            name="description"
            content="When people love where they live, they create a community of social and economic prosperity. Build community engagement for your town or city today."
          />
          <meta property="og:site_name" content="CivicLift" />
          <meta property="og:locale" content="en_US" />
          <link rel="canonical" href={document.location.href} />
        </Helmet>
        <BodyClassName className={isEmbedded() ? 'is-embedded' : ''}>{children}</BodyClassName>
      </div>
    </DndProvider>
  )
}

export default withRouter(App)
