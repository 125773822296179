import { css } from '@emotion/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BaseField } from 'uniforms'
import { AutoField, BoolField, ErrorField, ErrorsField } from 'uniforms-unstyled'
import { UseOnImageUploadStateChangeReturnType } from '../../../hooks/useOnImageUploadStateChange'
import { formFieldSpacing } from '../../../styles/shared'
import { marginBottom } from '../../../styles/vars'
import CommunityField from '../form-fields/CommunityField'
import DatePicker from '../form-fields/DatePicker'
import SelectCollectionField from '../form-fields/SelectCollectionField'
import UniformMediaUploadField from '../form-fields/uniform-fields/UniformMediaUploadField'
import RichTextEditor from '../form-fields/uniform-fields/UniformRichTextEditor'
import { CommunitiesContextTypes } from '../../../hooks/CommunitiesContextProvider'

interface StoryFieldsProps {
  communities: CommunitiesContextTypes
  onImageUploadStateChange: UseOnImageUploadStateChangeReturnType
}

export default class StoryFields extends React.Component<StoryFieldsProps> {
  renderCategoryPicker(): JSX.Element {
    const model = this.context.uniforms.model
    const { communities } = this.props
    const selectedCommunity = model.community || communities.find(c => c.id === model.primaryCommunityId)

    if (!selectedCommunity) {
      return (
        <SelectCollectionField
          name="category"
          allowedValues={[]}
          valueKey="name"
          placeholder="Select Category"
          className="input-row isRequired"
        />
      )
    }

    const { storyCategories } = selectedCommunity
    const value = storyCategories.includes(model.category) ? model.category : undefined
    const options = storyCategories.map(category => ({ name: category }))

    return (
      <SelectCollectionField
        name="category"
        allowedValues={options}
        valueKey="name"
        value={value}
        placeholder="Select Category"
        className="input-row isRequired"
      />
    )
  }

  render(): JSX.Element {
    const model = this.context.uniforms.model
    let title = 'Story'
    title = `${title} ${model.isNew ? 'Add' : 'Edit'}`
    title = `${title}${model.title ? `: ${model.title}` : ''}`

    return (
      <fieldset>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <CommunityField communities={this.props.communities} />
        <div className="form-error">
          <ErrorField name="primaryCommunityId" />
        </div>

        {this.renderCategoryPicker()}
        <div className="form-error">
          <ErrorField name="category" />
        </div>

        <AutoField name="title" className="input-row" placeholder=" " />
        <div className="form-error">
          <ErrorField name="title" />
        </div>

        <AutoField name="excerpt" className="input-row" placeholder=" " />
        <div className="form-error">
          <ErrorField name="excerpt" />
        </div>

        <div
          // temporary hard-coded margin, since other inputs set this margin by themselves
          // new approach will have parent positioning it's children so this will be applied
          // at the form level once we migrate to a react-hook-forms in this file
          css={css`
            ${formFieldSpacing};
            max-width: 267px;
            height: 165px;
            ${marginBottom};
          `}
        >
          <UniformMediaUploadField
            allowVideo={false}
            folder="stories"
            name="image"
            label="Add Image"
            onUploadingStateChange={this.props.onImageUploadStateChange}
          />
        </div>

        <RichTextEditor
          isRequired
          name="content"
          className="input-row"
          onUploadingStateChange={this.props.onImageUploadStateChange}
          toolbar={[
            [{ header: [2, 3, false] }],
            ['bold', 'italic'],
            ['link', 'blockquote', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ]}
        />
        <div className="form-error">
          <ErrorField name="content" />
        </div>

        <BoolField name="isFeatured" className="checkbox-row" />
        <div className="form-error">
          <ErrorField name="isFeatured" />
        </div>

        <div className="input-row">
          <label htmlFor="publishedAt">Published At*</label>
          <DatePicker className="dateInput" name="publishedAt" placeholder=" " />
        </div>
        <div className="form-error">
          <ErrorField name="publishedAt" />
        </div>

        <div
          className="form-error"
          css={css`
            padding-top: 30px;
            margin-bottom: -10px;
          `}
        >
          <ErrorsField />
        </div>
      </fieldset>
    )
  }
}

StoryFields.contextTypes = BaseField.contextTypes
