import * as React from 'react'
import BodyClassName from 'react-body-classname'
import { useParams } from 'react-router'
import { Event } from '../../../models'
import ContentFeedCalendarContainer from '../../containers/misc/ContentFeedCalendarContainer'
import ContentFeedEventsListEvents from './ContentFeedEventsListEvents'
import EventsListPlaceholder from './EventsListPlaceholder'

type Props = {
  loading: boolean
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
  events: Event[]
  selectedDay: Date
}
const ContentFeedEventsList: React.FC<Props> = ({ loading, fetchNextPage, hasNextPage, events, selectedDay }) => {
  const { id } = useParams<{ id: string }>()

  return (
    <BodyClassName className="path-events">
      <section className="events-overview">
        <div className="two-col">
          <section className="events-main">
            {loading ? (
              <EventsListPlaceholder />
            ) : events.length > 0 ? (
              <ContentFeedEventsListEvents events={events} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
            ) : (
              'No upcoming events'
            )}
          </section>

          <div className="events-sidebar">
            <aside>
              <ContentFeedCalendarContainer id={id} selectedDay={selectedDay} />
            </aside>
          </div>
        </div>
      </section>
    </BodyClassName>
  )
}

export default ContentFeedEventsList
