import { createContext } from 'react'

export type CommunitiesContextTypes = { id: string; displayName: string; storyCategories?: string[] }[]
export const CommunitiesContext = createContext<CommunitiesContextTypes>([])

const CommunitiesContextProvider: React.FC<{ value: CommunitiesContextTypes }> = ({ value, children }) => (
  <CommunitiesContext.Provider value={value}>{children}</CommunitiesContext.Provider>
)

export default CommunitiesContextProvider
