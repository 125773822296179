import dayjs, { Dayjs } from 'dayjs'
import { WeekdaysType } from './constants'

export type DateLike = Dayjs | Date | string

export const dayOfTheWeek = (date: DateLike): WeekdaysType => dayjs(date).format('dddd') as WeekdaysType

export const formatTime = (date: DateLike): string => dayjs(date).format('h:mma')
export const formatDate = (date: Date): string => dayjs(date).format('MMMM D, YYYY')
export const formatDateAndTime = (date: DateLike): string => dayjs(date).format('ddd, MMM Do @ h:mma')
export const formatDayAndDate = (date: Date): string => dayjs(date).format('ddd, MMM D, YYYY')

export const formatMonthDay = (date: DateLike): string => dayjs(date).format('MMMM Do')

export const formatShortDate = (date: DateLike): string => dayjs(date).format('MM/DD/Y')

export const formatLongDate = (date: DateLike): string => dayjs(date).format('MMM DD, Y')

export const getTimeFromNow = (date?: DateLike): string => dayjs(date).fromNow()

export const getDuration = (start: DateLike, end: DateLike = new Date()): string => dayjs.duration(dayjs(start).diff(dayjs(end))).humanize()

export const isSameDay = (start?: DateLike, end?: DateLike): boolean => dayjs(start).isSame(end, 'day')
