import gql from 'graphql-tag'
import { eventFragment } from './fragments'

export const EventDelete = gql`
  mutation EventDelete($input: EventDeleteInput!) {
    eventDelete(input: $input) {
      event {
        ...eventFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${eventFragment}
`
