export type IconNameType = (typeof IconName)[keyof typeof IconName]

export const IconName = {
  activityFeed: 'e93c',
  activity: 'e907',
  addToQueue: 'e930',
  add: 'e930',
  admin: 'e91f',
  aoi: 'e93a',
  arrowDown3: 'edc1',
  arrowLeft3: 'edc5',
  arrowLeft: 'e903',
  arrowRight3: 'edbd',
  arrowRight: 'e925',
  arrowUp3: 'edb9',
  billing: 'e916',
  blocked2: 'ed67',
  bullhorn: 'e900',
  bus: 'e91a',
  calendar: 'e923',
  cancelCircle2: 'ed66',
  cancelCircle: 'ed6e',
  car: 'e91b',
  category: 'e922',
  checkCircle: 'e94b',
  checkmark: 'e90d',
  circleEmpty: 'e90c',
  circlePlay: 'e90b',
  circlePlus: 'e930',
  close2: 'e909',
  close: 'e941',
  cog: 'e93b',
  connection: 'e97c',
  cross: 'e941',
  date: 'e923',
  description: 'e920',
  doc: 'e93d',
  draft: 'e93d',
  dressCode: 'e921',
  edit: 'e90a',
  envelop2: 'e924',
  eventQueue: 'e90f',
  events: 'e938',
  expand: 'e919',
  eyeBlocked: 'e908',
  facebook: 'e913',
  fb: 'e913',
  flag: 'e940',
  folder: 'e922',
  freeWall: 'ec5d',
  gear: 'e93b',
  globe: 'ec91',
  googlePlus: 'e915',
  googleplus: 'e915',
  gplus: 'e915',
  grid: 'ec5d',
  group: 'e936',
  headlines: 'e900',
  history: 'e931',
  idea: 'e906',
  ig: 'e914',
  imageCompare: 'e926',
  info: 'e920',
  instagram: 'e914',
  instances: 'e930',
  kite: 'e939',
  li: 'e910',
  lightbulb: 'e906',
  link: 'e937',
  linkedin: 'e910',
  list: 'e90f',
  location: 'e934',
  logout: 'e905',
  network: 'ec91',
  newsletter: 'e924',
  o: 'e90c',
  online: 'e97c',
  pencil: 'e90a',
  pending2: 'e91d',
  pending: 'e908',
  phone: 'e917',
  piggyBank: 'e916',
  pin: 'e934',
  pinterest: 'e904',
  places: 'e934',
  private: 'e908',
  profile: 'e93b',
  remove2: 'e909',
  repeating: 'e931',
  search: 'e935',
  server: 'e91c',
  share3: 'eee0',
  share: 'e90e',
  shirt: 'e921',
  shrink: 'e918',
  sphere: 'ec91',
  star: 'e944',
  stats: 'e907',
  sync: 'e93c',
  systemStatus: 'e91c',
  tag: 'e901',
  ticket: 'e933',
  tw: 'e911',
  twitter: 'e911',
  upload: 'e902',
  user: 'e91e',
  users: 'e936',
  warning: 'e91d',
  watch: 'e932',
  weatherCloudSun: 'e928',
  weatherCloudWind: 'e92b',
  weatherCloud: 'e92d',
  weatherClouds: 'e929',
  weatherRain: 'e92c',
  weatherSnow: 'e92f',
  weatherStorm: 'e92e',
  weatherSunWind: 'e92a',
  weatherSun: 'e93f',
  weatherWind: 'e927',
  wifi: 'e97c',
  x: 'e941',
  youtube: 'e912',
  yt: 'e912',
} as const
