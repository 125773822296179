import dayjs from 'dayjs'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { groupEventsByDay, mapEventIdToCommunityIndex, splitEventsToFullSizeAndTableEvents } from '../../../api/events/helpers'
import { Community, Event } from '../../../models'
import Loading from '../misc/Loading'
import EventsListDay from './EventsListDay'

type Props = {
  communities: Community[]
  events: Event[]
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
}
const EventsListEvents: React.FC<Props> = ({ communities, events, fetchNextPage, hasNextPage }) => {
  if (!events || !events.length) return null

  const eventIdToCommunityIndexMap = mapEventIdToCommunityIndex(communities, events)
  const eventsGroupedByDay = Object.entries(groupEventsByDay(events))
  return (
    <InfiniteScroll
      dataLength={events.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<Loading className="isCentered isDark" />}
      style={{ overflow: 'hidden' }}
    >
      {eventsGroupedByDay.map(([dayDate, dailyEvents]) => {
        const { fullSizeEvents, tableEvents } = splitEventsToFullSizeAndTableEvents(dailyEvents)
        const activeDay = dayjs(dayDate).startOf('day')

        return (
          <EventsListDay
            key={activeDay.toString()}
            activeDay={activeDay}
            fullSizeEvents={fullSizeEvents}
            tableEvents={tableEvents}
            eventIdToCommunityIndexMap={eventIdToCommunityIndexMap}
          />
        )
      })}
    </InfiniteScroll>
  )
}

export default EventsListEvents
