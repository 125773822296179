import { DocumentNode } from 'graphql'
import get from 'lodash/get'
import mergeWith from 'lodash/mergeWith'
import set from 'lodash/set'
import uniqBy from 'lodash/uniqBy'
import { useCallback } from 'react'
import { OperationVariables, QueryHookOptions } from 'react-apollo'
import { useQuery } from './graphql'

const customizer = (objValue: unknown) => (Array.isArray(objValue) ? objValue : undefined)

type ReturnType = { data; loading: boolean; fetchNextPage: () => Promise<void>; hasNextPage: boolean }
const usePaginatedQuery = (query: DocumentNode, property: string, options?: QueryHookOptions<unknown, OperationVariables>): ReturnType => {
  const { data, loading, fetchMore } = useQuery(query, options)
  const { endCursor: cursor, hasNextPage } = get(data, `${property}.pageInfo`, {})

  const fetchNextPage = useCallback(async () => {
    try {
      await fetchMore({
        variables: { cursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const key = `${property}.nodes`
          const prevRecords = get(previousResult, key, [])
          const newRecords = get(fetchMoreResult, key, [])
          if (newRecords.length === 0) return previousResult

          const newResult = mergeWith(mergeWith({}, previousResult, customizer), fetchMoreResult, customizer)
          const allRecords = uniqBy([...prevRecords, ...newRecords], 'id')
          set(newResult, key, allRecords)
          return newResult
        },
      })
    } catch {
      // ignore this error as it's thrown when user navigates away while fetching more
      // this unmounts the component and makes it raise the error
    }
  }, [fetchMore, cursor, property])

  return { data, loading, fetchNextPage, hasNextPage }
}

export default usePaginatedQuery
