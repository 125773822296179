import gql from 'graphql-tag'
import React, { useCallback, useContext, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import toastr from 'toastr'
import { AutoForm, ErrorField } from 'uniforms-unstyled'
import { useMutation } from '../../../api/graphql'
import { CurrentUserContext, CurrentUserQuery, currentUserFragment } from '../../../providers/CurrentUserContext'
import ResetPasswordSchema from '../../../schemas/users/resetPasswordSchema'
import Routes from '../../../startup/routes'
import { fullMessageErrors } from '../../../translations/en'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import MessageForm from '../misc/MessageForm'
import AccountField from './AccountField'

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      user {
        ...currentUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${currentUserFragment}
`

const ResetPassword: React.FC = () => {
  const client = useApolloClient()
  const { token } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { currentUser } = useContext(CurrentUserContext)
  const [resetPassword] = useMutation(RESET_PASSWORD)

  const showError = useCallback(
    newMessage => {
      setMessage({ content: newMessage, type: 'danger' })
    },
    [setMessage],
  )

  const handleSubmit = useCallback(
    doc => {
      if (!token) {
        showError('Missing reset password token.')
      } else {
        setIsLoading(true)
        resetPassword({ variables: { input: doc } }).then(
          ({
            data: {
              resetPassword: { user, errors },
            },
          }) => {
            if (errors) {
              setIsLoading(false)
              showError(fullMessageErrors(errors))
            } else {
              toastr.success('Welcome back!', 'Password reset')
              client.writeQuery({ query: CurrentUserQuery, data: { currentUser: user } })
            }
          },
        )
      }
    },
    [token, setIsLoading, showError, client, resetPassword],
  )

  if (currentUser) return <Redirect to={Routes.dash()} />

  return (
    <div className="account-overlay">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <section className="account-modal">
        <h1>Reset Password</h1>
        <MessageForm {...message} />
        <AutoForm schema={ResetPasswordSchema} onSubmit={handleSubmit} model={{ resetPasswordToken: token }} className="form account-form">
          <AccountField name="password" type="password" label="Enter new password" />

          <div className="form-error">
            <ErrorField name="password" />
          </div>

          <UniformsButton shouldShowLoadingSpinner={isLoading} text="Reset Password" />
        </AutoForm>
      </section>
    </div>
  )
}

export default ResetPassword
