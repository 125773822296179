/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import $ from 'jquery'
import React from 'react'
import 'slick-carousel'

import Lightbox from '../react-image-lightbox/ReactImageLightbox'
import ResponsiveImage, { mediaSrc, VideoTag } from './MediaPresets'

class MediaSlider extends React.Component {
  state = {
    dir: null,
    isLightboxOpen: false,
  }

  slider = null

  componentDidMount() {
    // https://github.com/kenwheeler/slick#settings
    const defaultSettings = {
      autoplay: true,
      infinite: false,
      mobileFirst: true,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            variableWidth: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            variableWidth: true,
            centerMode: true,
            centerPadding: '50px',
            slidesToShow: 3,
          },
        },
      ],
    }
    const slickSettings = { ...defaultSettings, ...this.props.settings }

    const slider = $('.slider')
    this.slider = slider
    slider.slick(slickSettings)

    const $images = $('.slider img')
    let nrOfImagesToLoad = $images.length
    $images.each(function () {
      this.onerror = function () {
        nrOfImagesToLoad--
      }
    })
    const poll = setInterval(() => {
      let imagesToLoad = nrOfImagesToLoad
      $('.slider img').each(function () {
        if (this.naturalWidth) {
          imagesToLoad--
        }
      })

      if (imagesToLoad === 0) {
        clearInterval(poll)
        slider.slick('unslick')
        slider.slick({ ...slickSettings, infinite: true })
      }
    }, 100)
  }

  componentDidUpdate() {
    this.playVideo()
  }

  playVideo = () => {
    const prevMedia = $('.ril-image-current > *')
    if (prevMedia.length > 0 && this.state.dir) {
      if (prevMedia.prop('tagName').toLowerCase() === 'video') {
        prevMedia[0].pause()
      }

      let currentMedia
      if (this.state.dir === 'prev') {
        currentMedia = $('.ril-image-prev > *')
      } else {
        currentMedia = $('.ril-image-next > *')
      }

      if (currentMedia.prop('tagName').toLowerCase() === 'video') {
        currentMedia[0].play()
      }
    }
  }

  slideClicked = event => {
    const $element = $(event.target)
    const tagName = $element.prop('tagName').toLowerCase()
    if (tagName === 'img' || tagName === 'video' || $element.parent().hasClass('video-overlay')) {
      const nrOfSlides = this.props.media.length
      const clickedSlideIndex = ($element.closest('.slick-slide').data('slick-index') + nrOfSlides) % nrOfSlides
      this.setState({ currentSlideIndex: clickedSlideIndex, isLightboxOpen: true })
      this.slider.slick('slickPause')
    }
  }

  renderItem(srcType, media) {
    const shouldAutoPlay = media.type === 'video' && srcType === 'mainSrc'

    return media.type === 'video' ? (
      <VideoTag key={media.publicId} publicId={media.publicId} autoPlay={shouldAutoPlay} controls loop={false} muted={false} />
    ) : (
      <ResponsiveImage key={media.publicId} publicId={media.publicId} alt="" height="400" responsive={false} />
    )
  }

  render() {
    const { className, media } = this.props

    return (
      <div>
        <div className={`slider ${className}`} onClick={this.slideClicked}>
          {media.map(image =>
            image.type === 'video' ? (
              <div key={image.publicId} className="video-overlay">
                <i className="icon-circle-play" />
                <img src={mediaSrc({ ...image, height: 400 })} alt="" />
              </div>
            ) : (
              <ResponsiveImage key={image.publicId} publicId={image.publicId} alt="" height="400" responsive={false} />
            ),
          )}
        </div>
        {this.state.isLightboxOpen && (
          <Lightbox
            renderItem={item => item}
            enableZoom={false}
            mainSrc={this.renderItem('mainSrc', media[this.state.currentSlideIndex])}
            nextSrc={this.renderItem('nextSrc', media[(this.state.currentSlideIndex + 1) % media.length])}
            prevSrc={this.renderItem('prevSrc', media[(this.state.currentSlideIndex + media.length - 1) % media.length])}
            onAfterOpen={this.playVideo}
            onCloseRequest={() => {
              this.setState({ isLightboxOpen: false })
              this.slider.slick('slickPlay')
            }}
            onMovePrevRequest={() =>
              this.setState({
                dir: 'prev',
                currentSlideIndex: (this.state.currentSlideIndex + media.length - 1) % media.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                dir: 'next',
                currentSlideIndex: (this.state.currentSlideIndex + 1) % media.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default MediaSlider
