import ReactModal from 'react-modal'

ReactModal.setAppElement('#react-root')

type Props = {
  className?: string
  children: React.ReactNode
  isOpen: boolean
  onRequestClose: () => void
}
const Modal: React.FC<Props> = ({ children, className, ...props }) => (
  <ReactModal
    closeTimeoutMS={500}
    className={{
      base: `ReactModal-modal-base ${className ? className : ''}}`,
      afterOpen: 'ReactModal-modal-base_after-open',
      beforeClose: 'ReactModal-modal-base_before-close',
    }}
    overlayClassName={{
      base: 'ReactModal-overlay-base',
      afterOpen: 'ReactModal-overlay-base_after-open',
      beforeClose: 'ReactModal-overlay-base_before-close',
    }}
    {...props}
  >
    {children}
  </ReactModal>
)

export default Modal
