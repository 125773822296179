import React from 'react'
import Select from 'react-select'
import { connectField } from 'uniforms'
import { operatingHoursValues } from '../../../api/places/operatingHoursModel'

class OperatingHoursInput extends React.Component {
  onOpenChange = (option?: { value?: string }) => {
    if (option && option.value !== undefined) {
      const newOpenTime = option.value
      this.props.onChange(newOpenTime, `${this.props.name}.openTime`)

      const closeTime = this.props.value.closeTime
      if (closeTime === undefined) {
        this.props.onChange(newOpenTime, `${this.props.name}.closeTime`)
      }
    } else {
      this.props.onChange({}, `${this.props.name}`)
    }
  }

  onCloseChange = (option?: { value?: string }) => {
    if (option && option.value !== undefined) {
      const newCloseTime = option.value
      this.props.onChange(newCloseTime, `${this.props.name}.closeTime`)
    } else {
      this.props.onChange({}, `${this.props.name}`)
    }
  }

  render() {
    const { id, label, name, value } = this.props

    return (
      <div className="day-group">
        {label && <label htmlFor={id}>{label}</label>}

        <Select
          name={`${name}.openTime`}
          value={value.openTime}
          placeholder="Closed"
          options={operatingHoursValues}
          className="time-input open-time"
          onChange={this.onOpenChange}
        />

        <Select
          name={`${name}.closeTime`}
          value={value.closeTime}
          placeholder=""
          options={operatingHoursValues}
          className="time-input close-time"
          onChange={this.onCloseChange}
          disabled={value.openTime === undefined}
        />
      </div>
    )
  }
}

export default connectField(OperatingHoursInput)
