import gql from 'graphql-tag'
import React, { useCallback, useContext, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { Link, Redirect } from 'react-router-dom'
import { AutoForm, ErrorField } from 'uniforms-unstyled'
import { useMutation } from '../../../api/graphql'
import { CurrentUserContext, CurrentUserQuery, currentUserFragment } from '../../../providers/CurrentUserContext'

import SignInSchema from '../../../schemas/users/signInSchema'
import Routes from '../../../startup/routes'
import { fullMessageErrors } from '../../../translations/en'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import AccountField from './AccountField'

const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      user {
        ...currentUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${currentUserFragment}
`

const SignIn: React.FC = () => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { currentUser } = useContext(CurrentUserContext)
  const [signIn] = useMutation(SIGN_IN)

  const handleSubmit = useCallback(
    doc => {
      setLoading(true)
      setError(null)
      signIn({ variables: { input: doc } }).then(
        ({
          data: {
            signIn: { user, errors },
          },
        }) => {
          setLoading(false)
          if (errors) {
            setLoading(false)
            setError(fullMessageErrors(errors))
          } else {
            setError(null)
            client.writeQuery({ query: CurrentUserQuery, data: { currentUser: user } })
          }
        },
      )
    },
    [client, signIn, setLoading, setError],
  )

  if (currentUser) return <Redirect to={Routes.dash()} />

  return (
    <div className="account-overlay">
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <section className="account-modal">
        <h1 className="sign-in-heading">Sign In</h1>

        <AutoForm schema={SignInSchema} onSubmit={handleSubmit} className="form account-form">
          {error && (
            <h4 className="form-error" style={{ fontWeight: 'normal' }}>
              <div>{error}</div>
            </h4>
          )}

          <AccountField name="email" />

          <div className="form-error">
            <ErrorField name="email" />
          </div>

          <AccountField name="password" type="password" />

          <div className="form-error">
            <ErrorField name="password" />
          </div>

          <UniformsButton className="sign-in-btn" shouldShowLoadingSpinner={loading} text="Sign in" />
        </AutoForm>

        <p>
          <Link to={Routes.forgotPassword()}>Forgot password?</Link>
        </p>

        <p>
          Don't have an account yet? <Link to={Routes.signUp()}>Sign up now!</Link>
        </p>
      </section>
    </div>
  )
}

export default SignIn
