import SimpleSchema from './schema'

const AddressSchema = new SimpleSchema({
  streetAddress: { type: String, label: 'Street Address' },
  streetAddress2: { type: String, label: 'Street Address Line 2', optional: true },
  city: { type: String, label: 'City / Town' },
  state: { type: String, label: 'State / Province' },
  zipCode: { type: String, label: 'Zip Code', optional: true },
})

export default AddressSchema
