import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { AutoForm, ErrorField } from 'uniforms-unstyled'
import { useMutation } from '../../../api/graphql'
import ForgotPasswordSchema from '../../../schemas/users/forgotPasswordSchema'
import Routes from '../../../startup/routes'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import MessageForm from '../misc/MessageForm'
import AccountField from './AccountField'

const SEND_RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation SendResetPasswordInstructions($input: SendResetPasswordInstructionsInput!) {
    sendResetPasswordInstructions(input: $input) {
      success
    }
  }
`

const ForgotPassword: React.FC = () => {
  const [sendResetPasswordInstructions] = useMutation(SEND_RESET_PASSWORD_INSTRUCTIONS)

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({})

  const handleSubmit = useCallback(
    doc => {
      setIsLoading(true)
      sendResetPasswordInstructions({ variables: { input: doc } }).then(() => {
        setIsLoading(false)
        setMessage({ content: 'We just sent you email with link to reset your password.', type: 'success' })
      })
    },
    [setIsLoading, sendResetPasswordInstructions, setMessage],
  )

  return (
    <div className="account-overlay">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <section className="account-modal">
        <h1>Recover Password</h1>
        <MessageForm {...message} />
        <AutoForm schema={ForgotPasswordSchema} onSubmit={handleSubmit} className="form account-form">
          <AccountField name="email" />

          <div className="form-error">
            <ErrorField name="email" />
          </div>

          <UniformsButton shouldShowLoadingSpinner={isLoading} text="Reset Password" />
        </AutoForm>
        <p>
          <Link to={Routes.signIn()}>Back to Sign in</Link>
        </p>
      </section>
    </div>
  )
}

export default ForgotPassword
