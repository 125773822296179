import { Community, Event, Place, Story } from '../../models'
import BaseModel from '../model'

class User extends BaseModel {
  public readonly objectType = 'user'

  public firstName?: string
  public lastName?: string
  public email?: string
  public profilePic?: { publicId?: string }
  public primaryCommunity?: Community
  public subscribedEvents?: Event[]
  public subscribedStories?: Story[]
  public subscribedPlaces?: Place[]
  public signature?: string

  public isCurator?: boolean
  public isAuthor?: boolean

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor(doc) {
    super()
    const id = doc.id || doc._id
    Object.assign(this, { ...doc, _id: id, id })
  }

  public get fullName(): string {
    return [this.firstName, this.lastName].filter(Boolean).join(' ')
  }
}

export default User
