import React from 'react'
import { Link } from 'react-router-dom'
import { eventIdToCommunityIndexMapType } from '../../../api/events/helpers'
import { Event } from '../../../models'
import { formatTime } from '../../../util/dateTime'
import Routes from '../../../startup/routes'

type Props = { events: Event[]; eventIdToCommunityIndexMap?: eventIdToCommunityIndexMapType }

const EventsListTableItems: React.FC<Props> = ({ events, eventIdToCommunityIndexMap }) => {
  if (events.length === 0) return null

  return (
    <ul
      className="ongoingEvents-container row"
      style={{
        maxHeight: `${events.length * 25}px`,
      }}
    >
      {events.map(event => {
        const communityIndex = eventIdToCommunityIndexMap && eventIdToCommunityIndexMap[event.id]
        return (
          <li key={event.id} className={`ongoingEvent columns small-12 medium-12 large-6 type${communityIndex}-tiny`}>
            <Link to={location => Routes.event(event, event.community, location)} className="row">
              <div className="ongoingEvent-field columns small-9 large-8">
                <span className="ongoingEvent-title">{event.title}</span>
                {/*{event.day && ` (${event.day})`}*/}
              </div>
              <div className="ongoingEvent-field columns small-3 large-4">
                {formatTime(event.startAt)}
                {event.endAt && <span className="show-for-large"> - {formatTime(event.endAt)}</span>}
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default EventsListTableItems
