import { css } from '@emotion/react'
import React from 'react'
import { connectField, filterDOMProps } from 'uniforms'

class Select extends React.Component {
  updateValue(value) {
    this.props.onChange(value)
  }

  render() {
    return (
      <select
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        onChange={event => this.updateValue(event.target.value)}
        ref={this.props.inputRef}
        value={this.props.value}
      >
        {!!this.props.placeholder && <option value="">{this.props.placeholder}</option>}

        {this.props.allowedValues.map(value => (
          <option key={value[this.props.valueKey]} value={value[this.props.valueKey]}>
            {this.props.transform ? this.props.transform(value[this.props.displayKey]) : value[this.props.displayKey]}
          </option>
        ))}
      </select>
    )
  }
}

const SelectCollection = ({
  allowedValues,
  disabled,
  id,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  transform,
  value,
  valueKey = 'id',
  displayKey = 'name',
  ...props
}) => (
  <section
    {...filterDOMProps(props)}
    css={css`
      width: 100%;
      flex-wrap: wrap;
    `}
  >
    {label && <label htmlFor={id}>{label}</label>}

    <Select
      allowedValues={allowedValues}
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      transform={transform}
      value={value}
      placeholder={placeholder}
      inputRef={inputRef}
      displayKey={displayKey}
      valueKey={valueKey}
    />
  </section>
)

export default connectField(SelectCollection)
