import { MAPBOX_ACCESS_TOKEN } from './constants'

// needs to be synced with
//  - app/models/place.rb
//  - app/javascript/api/places/placeCategories.ts
//  - app/javascript/ui/components/mapbox/markers.ts
export const placeCategoryIconMapping = {
  Default: {
    icon: '',
    color: '#7ace75',
  },
  'Food & Drinks': {
    icon: 'restaurant',
    color: '#f44336',
  },
  'Culture & Recreation': {
    icon: 'theatre',
    color: '#4caf50',
  },
  'Local Farm / Agricultural': {
    icon: 'farm',
    color: '#4caf50',
  },
  Services: {
    icon: 'town-hall',
    color: '#2196f3',
  },
  'Shopping & Retail': {
    icon: 'shop',
    color: '#9c27b0',
  },
  'Public Resources': {
    icon: 'town-hall',
    color: '#f69300',
  },
  'Travel & Lodging': {
    icon: 'lodging',
    color: '#e91e63',
  },
} as const

// https://www.mapbox.com/api-documentation/#retrieve-a-standalone-marker
export const iconUrlForCategory = (categoryName?: string): string => {
  categoryName = categoryName || 'Default'
  const categoryIconMapping = placeCategoryIconMapping[categoryName] || placeCategoryIconMapping['Default']
  const iconName = categoryIconMapping.icon
  const color = categoryIconMapping.color.slice(1) // remove # from color hex
  let markerSize
  if (iconName.length > 0) {
    markerSize = 'pin-l-'
  } else {
    markerSize = 'pin-l'
  }
  return `https://api.mapbox.com/v4/marker/${markerSize}${iconName}+${color}@2x.png?access_token=${MAPBOX_ACCESS_TOKEN}`
}
