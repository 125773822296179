import values from 'lodash/values'
import { PublishState } from '../util/constants'
import SimpleSchema from './schema'

const SubmissionCommonSchema = new SimpleSchema({
  primaryCommunityId: { type: String, label: 'Community' },
  tags: { type: Array, label: 'Tags', optional: true, defaultValue: [] },
  'tags.$': { type: String },
  submittedBy: { type: String, optional: true },
  publishState: { type: String, allowedValues: values(PublishState), defaultValue: PublishState.PENDING, optional: true },
})

export default SubmissionCommonSchema
