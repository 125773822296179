import * as React from 'react'

export const createMarkup = (__html: undefined | string = ''): { __html: string } => ({ __html })

export const toggleBlocked =
  (setter: React.Dispatch<React.SetStateAction<string[]>>): React.ChangeEventHandler<HTMLInputElement> =>
  (e): void => {
    const { name } = e.target

    setter(blocked => {
      if (!name) return []
      if (blocked.includes(name)) return blocked.filter(value => value !== name)
      return [...blocked, name]
    })
  }
