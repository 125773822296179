import { css } from '@emotion/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BaseField } from 'uniforms'
import { AutoField, BoolField, ErrorField, ErrorsField, ListField, LongTextField, NestField, SelectField } from 'uniforms-unstyled'
import PlaceCategories from '../../../api/places/placeCategories'
import AddressFields from '../form-fields/AddressFields'
import CommunityField from '../form-fields/CommunityField'
import GeocodeAddressFields from '../form-fields/GeocodeAddressFields'
import MapPicker from '../form-fields/MapPicker'
import OperatingHoursInput from '../form-fields/OperatingHoursInput'
import SelectTags from '../form-fields/SelectTags'
import UniformMediaUploadField from '../form-fields/uniform-fields/UniformMediaUploadField'
import RichTextEditor from '../form-fields/uniform-fields/UniformRichTextEditor'
import PhoneField from '../misc/PhoneField'

interface PlaceFieldsProps {
  onUploadingStateChange?(...args: unknown[]): unknown
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const PlaceFields: React.FC<PlaceFieldsProps> = ({ onUploadingStateChange }, context) => {
  const model = context.uniforms.model
  const tags = [...(model.tags || []), ...PlaceCategories]

  let title = 'Place'
  title = `${title} ${model.isNew ? 'Add' : 'Edit'}`
  title = `${title}${model.name ? `: ${model.name}` : ''}`

  return (
    <fieldset>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <fieldset>
        <CommunityField />
        <div className="form-error">
          <ErrorField name="primaryCommunityId" />
        </div>

        <AutoField name="name" className="input-row" placeholder=" " />

        <div className="form-error">
          <ErrorField name="name" />
        </div>

        <SelectField name="category" className="input-row isRequired" placeholder="Select Category" />
        <div className="form-error">
          <ErrorField name="category" />
        </div>

        <LongTextField
          name="shortDescription"
          placeholder="Describe your business as you would describe it to a friend."
          className="input-row"
        />
        <div className="form-error">
          <ErrorField name="shortDescription" />
        </div>

        <SelectTags name="tags" label="Keywords" tags={tags} />

        <RichTextEditor className="input-row" name="longDescription" onUploadingStateChange={onUploadingStateChange} />
        <div className="form-error">
          <ErrorField name="longDescription" />
        </div>

        <AutoField name="webUrl" placeholder="https://example.com" className="input-row" />
        <div className="form-error">
          <ErrorField name="webUrl" />
        </div>
      </fieldset>

      <fieldset>
        <h2 className="hr-heading">Contact &amp; Place Info</h2>

        <BoolField name="submitterOwnsPlace" className="checkbox-row" />

        <NestField name="contact" label={null}>
          <div className="phone">
            <PhoneField name="phone" id="contact.phone" label="Phone" />
            <div className="form-error">
              <ErrorField name="phone" />
            </div>
          </div>

          <div className="email">
            <AutoField className="input-row" name="email" placeholder="name@example.com" disabled={model.submitterOwnsPlace} />
            <div className="form-error">
              <ErrorField name="email" />
            </div>
          </div>
        </NestField>

        <GeocodeAddressFields name="address" className="input-row isRequired" address={model.address} />

        <AddressFields name="address" address={model.address} />
        <div className="form-error">
          <ErrorField name="address" />
        </div>
      </fieldset>

      <fieldset>
        <h2 className="hr-heading">Pinpoint Your Place</h2>
        <p>
          By taking your time and being precise with this step, you can be assured that the people looking for you will have an easy time
          finding you.
        </p>

        <MapPicker name="geo.geometry" coordinatesQuery={model.address} />
      </fieldset>

      <fieldset>
        <h2 className="hr-heading">Images & Video</h2>
        <p>
          Upload a main image and up to six images and/or videos. We suggest uploading photos and videos of your place, happy customers,
          your team, and even your logo.
        </p>

        <div className="media-upload">
          <div className="main-image">
            <UniformMediaUploadField
              allowVideo={false}
              folder="media"
              label="Upload your main image"
              name="mainImage"
              onUploadingStateChange={onUploadingStateChange}
            />
            <div
              className="form-error"
              css={css`
                & > div {
                  margin-top: 0;
                }
                &:before {
                  content: none;
                }
              `}
            >
              <ErrorField name="mainImage" />
            </div>
          </div>

          <div className="add-media">
            <ListField
              name="media"
              className="addListField"
              label={null}
              itemProps={{
                component: UniformMediaUploadField,
                folder: 'media',
                label: 'Image or Video',
                onUploadingStateChange,
              }}
              initialCount={6}
            />
          </div>
        </div>
      </fieldset>

      <fieldset>
        <h2 className="hr-heading">Social Outreach</h2>

        <p>Provide links for the social media platforms your business currently utilizes.</p>

        <NestField name="socialUrls" label={null}>
          <div className="double-input-row">
            <div className="input-col">
              <AutoField name="facebook" placeholder=" " />

              <div className="form-error">
                <ErrorField name="facebook" />
              </div>
            </div>

            <div className="input-col">
              <AutoField name="twitter" label="Twitter" placeholder=" " />

              <div className="form-error">
                <ErrorField name="twitter" />
              </div>
            </div>
          </div>

          <div className="double-input-row">
            <div className="input-col">
              <AutoField name="linkedin" placeholder=" " />

              <div className="form-error">
                <ErrorField name="linkedin" />
              </div>
            </div>

            <div className="input-col">
              <AutoField name="instagram" placeholder=" " />

              <div className="form-error">
                <ErrorField name="instagram" />
              </div>
            </div>
          </div>

          <div className="double-input-row">
            <div className="input-col">
              <AutoField name="youtube" placeholder=" " />

              <div className="form-error">
                <ErrorField name="youtube" />
              </div>
            </div>

            <div className="input-col">
              <AutoField name="pinterest" placeholder=" " />

              <div className="form-error">
                <ErrorField name="pinterest" />
              </div>
            </div>
          </div>
        </NestField>
      </fieldset>

      <fieldset>
        <h2 className="hr-heading">Hours of Operation</h2>

        <NestField name="operatingHours" label={null} className="hours-inputs row collapse">
          <div className="hours-col small-12 medium-6 column">
            <OperatingHoursInput name="monday" />
            <OperatingHoursInput name="tuesday" />
            <OperatingHoursInput name="wednesday" />
            <OperatingHoursInput name="thursday" />
          </div>
          <div className="hours-col small-12 medium-6 column">
            <OperatingHoursInput name="friday" />
            <OperatingHoursInput name="saturday" />
            <OperatingHoursInput name="sunday" />
          </div>
        </NestField>
      </fieldset>

      <div
        className="form-error"
        css={css`
          padding-top: 30px;
          margin-bottom: -10px;
        `}
      >
        <ErrorsField />
      </div>
    </fieldset>
  )
}

PlaceFields.contextTypes = BaseField.contextTypes

export default PlaceFields
