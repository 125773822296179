import SimpleSchema from '../schema'
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2'
import MediaSchema from '../mediaSchema'
import SlugSchema from '../slugSchema'
import SubmissionCommonSchema from '../submissionCommonSchema'

const StorySchema = new SimpleSchema({
  title: {
    type: String,
    max: 70,
    label: 'Title',
  },
  category: {
    type: String,
    label: 'Category',
  },
  excerpt: {
    type: String,
    max: 250,
    label: 'Excerpt',
  },
  content: {
    type: String,
    label: 'Article',
  },
  image: {
    type: MediaSchema,
    label: 'Image',
    optional: true,
  },
  isFeatured: {
    type: Boolean,
    label: 'Featured',
    optional: true,
  },
  publishedAt: {
    type: Date,
    label: 'Published At',
  },
})
StorySchema.extend(SubmissionCommonSchema)
StorySchema.extend(SlugSchema)

export default new SimpleSchema2Bridge(StorySchema)

export const SubmittableStoryCreateSchema = new SimpleSchema({
  primaryCommunityId: { type: String, optional: true },
  title: { type: String, optional: true },
  category: { type: String, optional: true },
  excerpt: { type: String, optional: true },
  content: { type: String, optional: true },
  imagePublicId: { type: String, optional: true },
  isFeatured: { type: Boolean, optional: true },
  publishedAt: { type: Date, optional: true },
})

export const SubmittableStoryUpdateSchema = new SimpleSchema({
  id: { type: String, optional: true },
  // primaryCommunityId: { type: String, optional: true },
  title: { type: String, optional: true },
  category: { type: String, optional: true },
  excerpt: { type: String, optional: true },
  content: { type: String, optional: true },
  imagePublicId: { type: String, optional: true },
  isFeatured: { type: Boolean, optional: true },
  publishedAt: { type: Date, optional: true },
})
