// needs to be synced with
//  - app/models/place.rb
//  - app/javascript/ui/components/mapbox/markers.ts
//  - app/javascript/util/placeCategoryIconMapping.ts
const PlaceCategories = [
  'Food & Drinks',
  'Local Farm / Agricultural',
  'Shopping & Retail',
  'Culture & Recreation',
  'Services',
  'Public Resources',
  'Travel & Lodging',
]

export default PlaceCategories
