import dayjs, { Dayjs } from 'dayjs'
import * as React from 'react'
import { formatMonthDay } from '../../../util/dateTime'

type Props = { eventDate: Dayjs }

const EventsListDayHeader: React.FC<Props> = ({ eventDate }) => {
  let header

  const eventDay = eventDate.startOf('day')
  const today = dayjs().startOf('day')

  if (eventDay.isSame(today)) {
    const curDate = eventDate.format('dddd, MMMM Do')
    header = (
      <h2 className="events-day-header">
        TODAY
        <span className="events-date">{curDate}</span>
      </h2>
    )
  } else if (today.add(1, 'day').isSame(eventDate)) {
    const curDate = eventDate.format('dddd, MMMM Do')
    header = (
      <h2 className="events-day-header">
        TOMORROW
        <span className="events-date">{curDate}</span>
      </h2>
    )
  } else {
    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
    const day = days[eventDate.day()]
    const curDate = formatMonthDay(eventDate)
    header = (
      <h2 className="events-day-header">
        {day}
        <span className="events-date">{curDate}</span>
      </h2>
    )
  }
  return header
}

export default EventsListDayHeader
