// needs to be synced with
//  - app/models/concerns/event_constants.rb
export const EventCategoriesTree = {
  'Arts & Culture': [
    'Performing Arts (e.g., theater productions, dance, comedy nights, spoken word)',
    'Visual Arts (Art exhibitions, Gallery openings, Photography workshops, Public art tours)',
    'Concerts & Live Music (Rock concerts, Jazz nights, Acoustic sets)',
    'Music Festivals (Multi-genre music festivals, Single-genre music celebrations)',
    'Musical Workshops (Instrument lessons, Vocal training sessions)',
    'Open Mic & Jam Sessions (Community open mic nights, Jamming sessions for musicians)',
    'Cultural & International (Celebrations, Festivals, Food Fairs)',
    'Film, Media & Digital Arts (Film screenings, Digital art exhibitions, Multimedia installations)',
    'Literary Arts (Author readings, Writing workshops, Book club meetings, Poetry slams)',
    'Fashion (Fashion shows, Designer showcases, Styling workshops)',
    'History (Historical society events, Reenactments, Historical tours)',
  ],
  'Community & Family': [
    'Local Festivals (Seasonal fairs, Farmers markets, Craft fairs)',
    'Charity Events (Fundraising galas, Charity runs, Volunteer-driven events)',
    'Social Issues (Awareness campaigns, Community forums, Fundraising events)',
    'Neighborhood Gatherings (Block parties, Neighborhood picnics, Meetups)',
    'Family (Parenting classes, Family bonding activities, Educational outings)',
    'Religious Events (Church services, Interfaith gatherings, Prayer groups)',
    'LGBTQ+ (Support groups, social mixers)',
    'Senior Activities (Social mixers for seniors, Educational seminars, Senior fitness)',
    'Youth Programs (Summer camps, Teen workshops, STEM programs)',
    'Environment & Sustainability (Eco-friendly workshops, Sustainability seminars)',
  ],
  'Food & Drink': [
    'Culinary Events (Food festivals, Gourmet dining experiences, Tasting events)',
    'Drink Events (Wine tastings, Craft brewery tours, Mixology classes)',
    'Food Markets (Farmers markets, Artisan food)',
    'Cooking & Baking (Cooking classes, Baking competitions)',
    'Farm-to-Table Events (Dinners hosted on farms, Cooking classes with local ingredients)',
    'Wine Tastings & Vineyard Tours (Visits to local vineyards, Wine-making tours)',
  ],
  'Fitness, Health & Wellness': [
    'Fitness Challenges (5k runs, Obstacle races, charity walks)',
    'Exercise & Wellness (Fitness classes, Yoga, Meditation retreats)',
    'Group Fitness (Recreational sports, community cycling groups, hiking clubs)',
    'Health & Medical (Community health fairs, Wellness seminars, nutritional workshops)',
    'Healthy Living & Self-Care (Wellness retreats, Self-care workshops)',
  ],
  'Outdoors & Nature': [
    'Hiking and Walking (Nature walks, mountain hikes)',
    'Water Activities (Kayaking, canoeing, paddleboarding, recreational fishing)',
    'Wildlife & Nature Exploration (Wildlife tours, birdwatching excursions, botany hikes)',
    'Camping & Outdoor Survival (Camping weekends, wilderness survival courses',
    'Environmental Conservation (Community clean-up days, native plant restoration, eco-volunteering)',
    'Adventure and Extreme Sports (Rock climbing, mountain biking, zip-lining adventures)',
    'Seasonal Activities (Snowshoeing, Wildflower tours, Leaf-peeping)',
  ],
  'Agricultural & Agritourism': [
    'Farm Visits & Tours (Tours of working farms, Dairy farm experiences)',
    'Pick-Your-Own Farms (Apple picking, berry picking, seasonal offerings)',
    'Farmers Markets & Festivals (Weekly markets, Strawberry festivals, Olive oil tasting)',
    'Agricultural Fairs (County fairs, 4-H competitions, Livestock shows)',
    'Seasonal Harvest Festivals (Pumpkin patches, Corn mazes, Harvest celebrations)',
    'Agricultural Workshops (Beekeeping seminars, Sustainable farming practices, Gardening workshops)',
  ],
  'Professional & Business': [
    'Networking (Industry mixers, Professional networking events)',
    'Business Education (Leadership seminars, Business skills workshops)',
    'Economic Development (Business expos, Trade shows)',
    'Start-Up Events (Startup pitch nights, Incubator graduations)',
    'Chamber of Commerce (Local business mixers, Chamber meetings)',
    'Career Development (Career fairs, Resume writing workshops)',
    'Technology & Innovation (Technology conferences, Hackathons, Startup meetups)',
  ],
  'Education & Learning': [
    'Professional Development Courses (Leadership skills, Career advancement)',
    'Personal Skill-Building Workshops (Public speaking, Time management)',
    'Financial Literacy Workshops (Budgeting basics, Investing for beginners, Retirement planning)',
    'Continuing Education Classes (Adult education courses, Online learning programs)',
    'Language Learning Sessions (Spanish, French, Mandarin classes)',
    'Guest Speaker Events (Industry leaders, Academic figures)',
    'Educational Panels (Discussion on current events, Expert panels)',
    'Public Lectures (Open lectures on various topics)',
  ],
  'Civic & Service Organizations': [
    'Fundraising & Charity Events (Charity auctions, Fundraising dinners)',
    'Service Club Meetings (Rotary Club, Lions Club, Kiwanis meetings)',
    'Community Service Projects (Community clean-ups, Habitat for Humanity builds)',
    'Advocacy & Campaign Events (Political rallies, Awareness campaigns)',
  ],
  'Sports & Games': [
    'Local and School Sports (High school basketball, community soccer matches, Little league baseball games)',
    'Professional Sports Events (Basketball games, Soccer games)',
    'Video Games and eSports (Competitive online gaming events, Local eSports tournaments)',
    'Game Nights and Competitions (Card games, Board game meetups, Chess competitions)',
  ],
  'Hobbies & Crafts': [
    'Crafting Workshops (Knitting classes, Scrapbooking meetups)',
    'Hobbyist Meetups (Photography walks, Model building clubs)',
    "Collectors' Shows (Antique fairs, Collectible trade shows)",
    'Pet & Animal Lovers (Pet adoption fairs, Dog agility competitions)',
  ],
  'Auto, Boat & Air': [
    'Car Shows and Auto Expos (Classic car shows, New model expos)',
    'Air Shows and Aviation Meetups (Stunt flying displays, Vintage aircraft meetups)',
    'Boat Shows and Nautical Events (Boat expos, Sailing regattas)',
    'Motor Races and Motorcycle Rallies (Local drag races, Motorcycle club rallies)',
  ],
  'Seasonal & Holiday': [
    'National Holiday Celebrations (Fourth of July fireworks, New Year’s Eve parties)',
    'Cultural and Religious Holidays (Christmas markets, Diwali festivals, Eid celebrations)',
    'Themed Tours (Ghost tours, haunted hayrides, holiday lights tours)',
    'Seasonal Festivals (Winter carnivals, Spring festivals)',
    'Holiday Markets and Craft Fairs (Handmade gift markets, Holiday-themed fairs)',
  ],
} as const

const EventCategories = Object.keys(EventCategoriesTree)
export default EventCategories
