import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { prepareToSubmit } from '../../../api/events/helpers'

type FeedsListProps = { record: Event }
const FeedsList: React.FC<FeedsListProps> = ({ record }) => {
  const [feeds, setFeeds] = useState([])

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const queryParams = new URLSearchParams({ records_type: 'events' })

        const cleanedEvent = prepareToSubmit(record)
        Object.keys(cleanedEvent)
          .filter(key => !!cleanedEvent[key])
          .forEach(key => queryParams.append('event[' + key + ']', cleanedEvent[key]))

        const response = await fetch(`/feeds?${queryParams}`)
        setFeeds(await response.json())
      } catch (error) {
        console.error('Error fetching feeds:', error)
      }
    }

    const debounceFetch = setTimeout(fetchFeeds, 5 * 1000)

    return () => clearTimeout(debounceFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.startAt?.toISOString(), record.category, record.primaryCommunityId])

  if (feeds.length === 0) return null

  return (
    <div
      css={css`
        background-color: #f9f9f9;

        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 14px;
        padding: 24px;

        @media (min-width: 768px) {
          width: 82.9403914003%;
          margin-left: calc(2.3576515979% + 14.7019570017%);
        }
      `}
    >
      <div style={{ marginBottom: '32px' }}>
        <strong>We’ll help spread the word!</strong>
        <div>
          CivicLift will share your event with the websites below (though each site&apos;s manager will decide whether to include it on
          their calendar).
        </div>
        {/* <br /> */}
        {/* <div>
          <em style={{ fontSize: '12px' }}>Each site’s manager will decide whether to include your event on their calendar.</em>
        </div> */}
      </div>
      <ul style={{ listStyle: 'initial', marginLeft: '16px' }}>
        {feeds.map(feed => (
          <li key={feed.id} style={{ marginBottom: '2px' }}>
            <a href={feed.websiteUrl} style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer noopener">
              {feed.websiteTitle}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FeedsList
