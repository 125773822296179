import { capitalizeFirstLetter } from '../util/string'

const translations = {
  password: {
    wrong: 'Wrong credentials. Please try again.',
  },
}

type ErrorType = { attribute: string; message: string }
// TODO: log to Rollbar when keys are missing
export const errorFromGraphqlResponseErrors = (error: ErrorType): string =>
  (translations[error.attribute] || error.attribute)[error.message] || `${capitalizeFirstLetter(error.attribute)} ${error.message}`

export const errorsFromGraphqlResponseErrors = (errors: ErrorType[]): string[] => (errors || []).map(errorFromGraphqlResponseErrors)

export const fullMessageErrors = (errors: ErrorType[]): string => errorsFromGraphqlResponseErrors(errors).join('\n')
