import * as React from 'react'
import { Children } from 'react'
import { BaseField, nothing } from 'uniforms'

// We have to ensure that there's only one child, because returning an array
// from a component is prohibited.
class DisplayIf extends React.Component {
  render(): JSX.Element {
    const { children, condition } = this.props
    const { uniforms } = this.context
    return condition(uniforms) ? Children.only(children) : nothing
  }
}

DisplayIf.contextTypes = BaseField.contextTypes

export default DisplayIf
