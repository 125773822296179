import dayjs from 'dayjs'
import gql from 'graphql-tag'
import get from 'lodash/get'
import React, { useCallback, useContext, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '../../../api/graphql'
import { useCommunityIdOrSlug } from '../../../hooks'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import { LocalStorage } from '../../../util/window'
import Calendar from '../../components/misc/Calendar'

const CALENDAR_QUERY = gql`
  query CalendarQuery($communitySlug: String!, $timezone: String) {
    community(slug: $communitySlug) {
      id
      slug
      eventsCalendar(timezone: $timezone)
    }
  }
`

type Props = { selectedDay: Date }
const CalendarContainer: React.FC<Props> = ({ selectedDay }) => {
  const idOrName = useCommunityIdOrSlug()
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const communitySlug = idOrName || get(currentUser, 'primaryCommunity.slug') || LocalStorage.getItem('customDomainOrigin')
  const history = useHistory()
  const location = useLocation()

  const timezone = useMemo(() => dayjs.tz.guess() || 'America/New_York', [])

  const { data } = useQuery(CALENDAR_QUERY, {
    variables: { communitySlug, timezone },
    skip: loadingCurrentUser,
  })

  const eventDays: string[] = data?.community?.eventsCalendar || []

  const handleDateChange = useCallback(
    newDate => {
      const fromDate = dayjs(newDate).format('YYYY-MM-DD')
      const paths = location.pathname.split('/')
      const eventsIndex = paths.indexOf('events')
      const newPath = paths.slice(0, eventsIndex + 1).join('/')
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('from', fromDate)
      const search = queryParams.toString()
      history.replace({ pathname: newPath, search })
    },
    [history, location],
  )

  return <Calendar dates={eventDays.map(day => new Date(day))} onChangeDate={handleDateChange} value={selectedDay} />
}

export default CalendarContainer
