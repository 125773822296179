import React from 'react'
import { Link } from 'react-router-dom'
import useCommunityIdOrSlug from '../../../hooks/useCommunityIdOrSlug'
import Routes from '../../../startup/routes'
import UserNav from './UserNav'

const PublicFooter: React.FC = () => {
  const communityIdOrName = useCommunityIdOrSlug()

  return (
    <div className="public-footer">
      <nav className="public-nav">
        <ul>
          <li>
            <Link to={Routes.events(communityIdOrName)}>
              <i className="icon-events-red" aria-hidden="true" />
              <span>Events</span>
            </Link>
          </li>
          <li>
            <Link to={Routes.places(communityIdOrName)}>
              <i className="icon-location-green" aria-hidden="true" />
              <span>Places</span>
            </Link>
          </li>
          <li>
            <Link to={Routes.stories(communityIdOrName)}>
              <i className="icon-bullhorn-blue" aria-hidden="true" />
              <span>Stories</span>
            </Link>
          </li>
        </ul>
        <UserNav />
      </nav>
    </div>
  )
}

export default PublicFooter
