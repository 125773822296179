import * as React from 'react'
import User from '../../../api/users/model'
import { mediaSrc } from '../misc/MediaPresets'

type Props = { user: User | null }
const UserBadge: React.FC<Props> = ({ user }) => {
  const publicId = user?.profilePic?.publicId
  const backgroundImageUrl = publicId ? `url(${mediaSrc({ publicId, width: '150', height: '150' })})` : null

  return <span className="user-badge" style={{ backgroundImage: backgroundImageUrl || undefined }} />
}

export default UserBadge
