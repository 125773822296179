import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2'
import PlaceCategories from '../../api/places/placeCategories'
import { usPhoneRegEx } from '../../util/string'
import AddressSchema from '../addressSchema'
import GeoSchema from '../geoSchema'
import MediaSchema from '../mediaSchema'
import SimpleSchema from '../schema'
import SlugSchema from '../slugSchema'
import SubmissionCommonSchema from '../submissionCommonSchema'
import OperatingHoursSchema from './operatingHoursSchema'

const PlaceSchema = new SimpleSchema({
  category: { type: String, label: 'Category', allowedValues: PlaceCategories },
  subcategory: { type: String, label: 'Subcategory', optional: true },
  name: { type: String, label: 'Place Name' },
  shortDescription: { type: String, max: 250, label: 'Short Description', optional: true },
  longDescription: { type: String, label: 'Long Description', max: 2000, optional: true },
  /* this property indicates a user has submitted a place on
  someone else's behalf, if false the user would input a `contactEmail`
  instead of automatically using their registered email */
  submitterOwnsPlace: { type: Boolean, defaultValue: true, label: 'I am the main point-of-contact for this place', optional: true },
  contact: { type: Object, optional: true },
  'contact.name': { type: String, label: 'Name', optional: true },
  'contact.phone': {
    type: String,
    label: 'Phone',
    custom() {
      const phoneNumber = this.value || this.field('contact.phone').value
      if (phoneNumber && !phoneNumber.match(usPhoneRegEx)) {
        return 'phoneNumberFormat'
      }
      return undefined
    },
    autoValue() {
      let phoneNumber = this.value || this.field('contact.phone').value
      if (phoneNumber && phoneNumber.startsWith('(')) phoneNumber = `+1 ${phoneNumber}`
      return phoneNumber
    },
    optional: true,
  },
  // default to user's registered email
  'contact.email': { type: String, regEx: SimpleSchema.RegEx.Email, label: 'Email', optional: true },
  address: { type: AddressSchema },
  geo: { type: GeoSchema, optional: true },
  mainImage: { type: MediaSchema, label: 'Main Image', optional: true },
  media: { type: Array, label: 'Media', minCount: 1, maxCount: 6, optional: true },
  'media.$': { type: MediaSchema },
  webUrl: { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Website', optional: true },
  socialUrls: { type: Object, optional: true },
  'socialUrls.facebook': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Facebook', optional: true },
  'socialUrls.twitter': {
    type: String,
    autoValue() {
      if (this.value) {
        // regex taken from: http://www.regexplanet.com/cookbook/twitterurl/index.html
        const regex = /^((https?:\/\/)?(www\.)?twitter\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?(\?.*)?$/g
        const regexTokens = regex.exec(this.value)
        const twitterHandle = regexTokens && regexTokens[5]
        return twitterHandle ? `@${twitterHandle}` : this.value
      }
      return undefined
    },
    label: 'Twitter username',
    optional: true,
  },
  'socialUrls.linkedin': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'LinkedIn', optional: true },
  'socialUrls.instagram': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Instagram', optional: true },
  'socialUrls.youtube': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Youtube', optional: true },
  'socialUrls.pinterest': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Pinterest', optional: true },
  'socialUrls.googleplus': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Google+', optional: true },
  'socialUrls.newsletter': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Newsletter', optional: true },
  operatingHours: { type: OperatingHoursSchema, optional: true },
  isFuzzed: { type: Boolean, label: 'Is location hidden for privacy', defaultValue: false, optional: true },
})
PlaceSchema.extend(SubmissionCommonSchema)
PlaceSchema.extend(SlugSchema)

const PlaceSchemaBridged = new SimpleSchema2Bridge(PlaceSchema)
export default PlaceSchemaBridged

const PlaceFormSchema = new SimpleSchema()
PlaceFormSchema.extend(PlaceSchema)
const PlaceFormSchemaBridged = new SimpleSchema2Bridge(PlaceFormSchema)

export { PlaceFormSchemaBridged as PlaceFormSchema }

export const SubmittablePlaceCreateSchema = new SimpleSchema({
  primaryCommunityId: { type: String, optional: true },
  name: { type: String, optional: true },
  shortDescription: { type: String, optional: true },
  longDescription: { type: String, optional: true },
  category: { type: String, optional: true },
  subcategory: { type: String, optional: true },
  submitterOwnsPlace: { type: Boolean, optional: true },
  tags: { type: Array, optional: true },
  'tags.$': { type: String, optional: true },
  geo: { type: Object, blackbox: true, optional: true },
  mainImagePublicId: { type: String, optional: true },
  media: { type: Array, optional: true },
  'media.$': { type: MediaSchema, optional: true },
  webUrl: { type: String, optional: true },
  addressStreetAddress: { type: String, optional: true },
  addressCity: { type: String, optional: true },
  addressState: { type: String, optional: true },
  addressZipCode: { type: String, optional: true },
  addressStreetAddress2: { type: String, optional: true },
  contactName: { type: String, optional: true },
  contactEmail: { type: String, optional: true },
  contactPhone: { type: String, optional: true },
  operatingHoursMondayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursMondayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursTuesdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursTuesdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursWednesdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursWednesdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursThursdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursThursdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursFridayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursFridayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSaturdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSaturdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSundayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSundayCloseTime: { type: SimpleSchema.Integer, optional: true },
  socialUrlsFacebook: { type: String, optional: true },
  socialUrlsTwitter: { type: String, optional: true },
  socialUrlsLinkedin: { type: String, optional: true },
  socialUrlsInstagram: { type: String, optional: true },
  socialUrlsYoutube: { type: String, optional: true },
  socialUrlsPinterest: { type: String, optional: true },
})

export const SubmittablePlaceUpdateSchema = new SimpleSchema({
  id: { type: String, optional: true },
  // primaryCommunityId: { type: String, optional: true },
  name: { type: String, optional: true },
  shortDescription: { type: String, optional: true },
  longDescription: { type: String, optional: true },
  category: { type: String, optional: true },
  subcategory: { type: String, optional: true },
  submitterOwnsPlace: { type: Boolean, optional: true },
  tags: { type: Array, optional: true },
  'tags.$': { type: String, optional: true },
  geo: { type: Object, blackbox: true, optional: true },
  mainImagePublicId: { type: String, optional: true },
  media: { type: Array, optional: true },
  'media.$': { type: MediaSchema, optional: true },
  webUrl: { type: String, optional: true },
  addressStreetAddress: { type: String, optional: true },
  addressCity: { type: String, optional: true },
  addressState: { type: String, optional: true },
  addressZipCode: { type: String, optional: true },
  addressStreetAddress2: { type: String, optional: true },
  contactName: { type: String, optional: true },
  contactEmail: { type: String, optional: true },
  contactPhone: { type: String, optional: true },
  operatingHoursMondayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursMondayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursTuesdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursTuesdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursWednesdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursWednesdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursThursdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursThursdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursFridayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursFridayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSaturdayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSaturdayCloseTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSundayOpenTime: { type: SimpleSchema.Integer, optional: true },
  operatingHoursSundayCloseTime: { type: SimpleSchema.Integer, optional: true },
  socialUrlsFacebook: { type: String, optional: true },
  socialUrlsTwitter: { type: String, optional: true },
  socialUrlsLinkedin: { type: String, optional: true },
  socialUrlsInstagram: { type: String, optional: true },
  socialUrlsYoutube: { type: String, optional: true },
  socialUrlsPinterest: { type: String, optional: true },
})
