import { css } from '@emotion/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../../../startup/routes'
import { YourEventsTabScope } from '../../../../util/constants'

type Props = {
  tabScope: string
}

const YourEventsEmptyTabContentMessage: React.FC<Props> = ({ tabScope }) => {
  switch (tabScope) {
    case YourEventsTabScope.UPCOMING:
      return <p>Looks like you don’t have any upcoming events scheduled, yet.</p>
    case YourEventsTabScope.PAST:
      return <p>No past events found.</p>
    default:
      return <p>No events found.</p>
  }
}

export const YourEventsTabContentEmpty: React.FC<Props> = ({ tabScope }) => {
  return (
    <div
      className="dash-content-item"
      css={css`
        margin-top: 12px;
      `}
    >
      <YourEventsEmptyTabContentMessage tabScope={tabScope} />
      <Link to={Routes.newEvent()} className="btn btn-dash-add-event">
        Submit an Event
      </Link>
    </div>
  )
}

export default YourEventsTabContentEmpty
