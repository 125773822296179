import { User } from '../models'
import { capitalizeFirstLetter } from '../util/string'

class BaseModel {
  public id?: string
  public submitter?: User
  public numberAnalytics?: {
    numberOfImpressions?: number
    numberOfClicks?: number
  }
  public analytics?: {
    name: 'Impressions' | 'Clicks'
    data: { [key: string]: number }
    color: string
  }[]

  public get isNew(): boolean {
    return this.id == null
  }

  // replaces deep objects like
  // contact: { name: 'viktor' }
  // with
  // contactName: 'viktor'
  public flatObjectFields(fieldName: string): void {
    if (!this[fieldName]) {
      return
    }
    Object.entries(this[fieldName]).forEach(
      ([key, value]) => (this[`${fieldName}${capitalizeFirstLetter(key)}`] = typeof value === 'undefined' ? null : value),
      // ([key, value]) => (this[`${fieldName}${capitalizeFirstLetter(key)}`] = value),
    )
    delete this[fieldName]
  }
}

export default BaseModel
