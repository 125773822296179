import * as React from 'react'
import { Link } from 'react-router-dom'

import { Community, Event, Place, Story } from '../../../models'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'

interface Props {
  record?: Event | Community | Place | Story
  resourceName?: 'event' | 'community' | 'place' | 'story'
}

export const ActionControls: React.FC<Props> = ({ record, resourceName = record && record.objectType }) => {
  const { currentUser } = React.useContext(CurrentUserContext)
  if (!record || !currentUser) return null

  if (record.submitter?.id === currentUser.id || currentUser.isCurator) {
    return (
      <p className={`${resourceName}-edit`}>
        <Link to={Routes.editPath(resourceName, record)}>
          <i className={`icon-edit-${resourceName}`} />
          Edit
        </Link>
      </p>
    )
  }

  return null
}
