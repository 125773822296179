import styled from '@emotion/styled'
import React from 'react'
import { joinName } from 'uniforms'
import { SelectField } from 'uniforms-unstyled'
import Grid, { GridItem } from '../../../styles/Grid'
import DatePicker from '../form-fields/DatePicker'

const StyledTimeInput = styled(SelectField)`
  && select {
    width: 100%;
  }
`

type Props = { label?: JSX.Element; name?: string }
const StartDateTimeFields: React.FC<Props> = ({ label, name }) => (
  <>
    {label}
    <Grid>
      <GridItem columns={12} medium={6}>
        <DatePicker name={joinName(name, 'startAtDate')} className="dateAndTimeFields-date" />
      </GridItem>

      <GridItem columns={12} medium={6}>
        <Grid>
          <GridItem columns={6}>
            <StyledTimeInput name={joinName(name, 'startAtTime')} className="dateAndTimeFields-time" />
          </GridItem>
          <GridItem columns={6}>
            <StyledTimeInput name={joinName(name, 'endAtTime')} className="dateAndTimeFields-time" />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  </>
)

export default StartDateTimeFields
