import { css } from '@emotion/react'
import React, { useCallback } from 'react'
import { BaseField } from 'uniforms'
import { BoolField, ErrorField } from 'uniforms-unstyled'
import { marginBottom } from '../../../styles/vars'
import SelectCollectionField from '../form-fields/SelectCollectionField'
import Loading from '../misc/Loading'
import AccountField from './AccountField'

interface SignUpFieldsProps {
  communities?: unknown[]
  loading?: boolean
  selectedCommunityId?: string
}

const SignUpFields: React.FC = ({ loading, communities }: SignUpFieldsProps, { uniforms }) => {
  const renderCommunitiesSelect = useCallback(() => {
    if (loading) {
      return (
        <Loading
          className="isCentered"
          style={{
            marginTop: '17px',
            marginBottom: '16.5px',
          }}
        />
      )
    }

    return (
      <div
        css={css`
          ${marginBottom}
        `}
      >
        <SelectCollectionField
          name="primaryCommunityId"
          allowedValues={communities}
          label="Choose Your Primary Community"
          placeholder="Select Community"
        />
        <div className="form-error">
          <ErrorField
            name="primaryCommunityId"
            css={css`
              margin-top: 0 !important;
            `}
          />
        </div>
      </div>
    )
  }, [communities, loading])

  const renderTermsAndConditions = useCallback(() => {
    const { model } = uniforms
    let communityHasTnC = false
    let community

    if (model.primaryCommunityId) {
      community = communities.find(c => c.id === model.primaryCommunityId)

      if (community && community.termsAndConditionsUrl) {
        communityHasTnC = true
      }
    }

    const civicLiftTnCUrl = 'https://docs.google.com/document/d/1LNzFctl9PupxtZDLUvH_nrTH-NEVEWNgKrxXIpcrCv0/edit?usp=sharing'

    if (communityHasTnC) {
      return (
        <span>
          {`I agree to ${community.name}'s `}
          <a href={community.termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
            {'Terms & Conditions'}
          </a>
          {" and CivicLift's "}
          <a href={civicLiftTnCUrl} target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          .
        </span>
      )
    }

    return (
      <span>
        {"I agree to CivicLift's "}
        <a href={civicLiftTnCUrl} target="_blank" rel="noopener noreferrer">
          Terms of Use
        </a>
        .
      </span>
    )
  }, [communities, uniforms])

  return (
    <div>
      <div className="double-input-row">
        <div
          className="input-col"
          css={css`
            margin-bottom: 0;
          `}
        >
          <AccountField name="firstName" />
          <div className="form-error">
            <ErrorField name="firstName" />
          </div>
        </div>

        <div
          className="input-col"
          css={css`
            margin-bottom: 0;
          `}
        >
          <AccountField name="lastName" />
          <div className="form-error">
            <ErrorField name="lastName" />
          </div>
        </div>
      </div>

      <div className="double-input-row">
        <div
          className="input-col"
          css={css`
            margin-bottom: 0;
          `}
        >
          <AccountField name="email" />
          <div className="form-error">
            <ErrorField name="email" />
          </div>
        </div>

        <div
          className="input-col"
          css={css`
            margin-bottom: 0;
          `}
        >
          <AccountField name="password" type="password" />
          <div className="form-error">
            <ErrorField name="password" />
          </div>
        </div>
      </div>

      {renderCommunitiesSelect()}

      <BoolField
        name="_acceptTerms"
        label={renderTermsAndConditions()}
        className="signUp-terms"
        css={css`
          ${marginBottom};
          text-align: left;
        `}
      />
      <div className="form-error signUp-termsError">
        <ErrorField name="_acceptTerms" />
      </div>
    </div>
  )
}

SignUpFields.contextTypes = BaseField.contextTypes

export default SignUpFields
