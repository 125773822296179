import styled from '@emotion/styled'
import { ErrorMessage, useField } from 'formik'
import React, { InputHTMLAttributes } from 'react'
import { borderRadius, inputBottomGutter, inputFieldHeight, marginBottom } from '../../../styles/vars'

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: ${borderRadius};
  padding: 5px 10px 5px 15px;
  height: ${inputFieldHeight};
  min-width: 130px;
  font-size: 14px;
  margin-bottom: ${inputBottomGutter};
  color: #434343;
`

const StyledError = styled(ErrorMessage)`
  font-size: 12px;
  color: #d95859;
  ${marginBottom};
  margin-top: -10px;
`

interface StringInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  placeholder: string
}

const StringInput: React.FC<StringInputProps> = props => {
  const { name, ...otherProps } = props
  const [field] = useField(name)

  return (
    <>
      <StyledInput type="text" {...field} {...otherProps} />
      <StyledError component="div" name={name} />
    </>
  )
}

StringInput.displayName = 'StringInput'

export default StringInput
