import { css, Global } from '@emotion/react'
import React from 'react'

const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
      }
    `}
  />
)

export default GlobalStyles
