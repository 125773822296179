import compact from 'lodash/compact'
import includes from 'lodash/includes'

function findComponent(components, keys, type) {
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    const result = components.filter(c => includes(c.types, key))
    if (Array.isArray(result) && result.length > 0 && result[0][type]) {
      return result[0][type]
    }
  }

  return undefined
}

type extractAddressComponentsType = (components: { long_name: string; short_name: string; types: string[] }) => {
  street?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}
export const extractAddressComponents: extractAddressComponentsType = components => {
  let street = findComponent(components, ['route'], 'long_name')
  const streetNumber = findComponent(components, ['street_number'], 'long_name')
  const district = findComponent(components, ['sublocality', 'neighborhood', 'sublocality_level_1'], 'long_name')
  const city = findComponent(components, ['locality', 'postal_town', 'administrative_area_level_2'], 'long_name')
  const state = findComponent(components, ['administrative_area_level_1'], 'short_name')
  const zip = findComponent(components, ['postal_code'], 'long_name')
  const country = findComponent(components, ['country'], 'long_name')

  if (!street) {
    street = district
  }

  if (streetNumber) {
    street = `${streetNumber} ${street}`
  }

  return {
    street,
    city,
    state,
    zip,
    country,
  }
}

type joinAddressComponentsType = (address: {
  streetAddress?: string
  streetAddress2?: string
  city?: string
  state?: string
  zipCode?: string
}) => string
export const joinAddressComponents: joinAddressComponentsType = address => {
  const { streetAddress, streetAddress2, city, state, zipCode } = address
  const addressFields = [streetAddress, streetAddress2, city, state, zipCode]
  return compact(addressFields).join(', ')
}
