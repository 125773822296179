import gql from 'graphql-tag'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '../../api/graphql'
import Story from '../../api/stories/model'
import { storyFragment } from '../../graphql/fragments'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import useStoryFormData from '../../hooks/useStoryFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import StoryEdit from '../components/stories/StoryEdit'

const StoryEditQuery = gql`
  query StoryEditQuery($slug: String!) {
    story(slug: $slug) {
      ...storyFragment
      content
    }
  }
  ${storyFragment}
`

const StoryEditContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const { data, loading } = useStoryFormData()

  const { data: storyData, loading: loadingStory } = useQuery(StoryEditQuery, { variables: { slug: id }, skip: loadingCurrentUser })

  // {...story} is needed to convert Story to JS object w/o functions
  // for example `omitBy(isNil)` assigned story.fullAddress as a value
  // while story.fullAddress is a getter
  const story = new Story(omitBy({ ...storyData?.story }, isNil))
  story.contact = omitBy(story.contact, isNil)
  story.address = omitBy(story.address, isNil)
  story.primaryCommunityId = story.community?.id

  const isAnythingLoading = loading || loadingCurrentUser || loadingStory

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <StoryEdit loading={isAnythingLoading} story={isAnythingLoading ? undefined : story} />
    </CommunitiesContextProvider>
  )
}

export default StoryEditContainer
