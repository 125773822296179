import gql from 'graphql-tag'
import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import { placeFragment } from '../../graphql/fragments'
import useRecordSubmissionMutation from '../../hooks/useRecordSubmissionMutation'
import Routes from '../../startup/routes'
import YourPlacesItem from '../components/dashboard/yourPlaces/YourPlacesItem'
import Loading from '../components/misc/Loading'

export const YourPlacesQuery = gql`
  query YourPlacesQuery($cursor: String) {
    currentUser {
      id
      submittedPlaces(first: 30, after: $cursor) {
        nodes {
          id
          slug
          name
          category
          tags
          publishState

          address_city: addressCity
          address_state: addressState
          address_streetAddress: addressStreetAddress
          address_streetAddress2: addressStreetAddress2
          address_zipCode: addressZipCode
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const PlaceDelete = gql`
  mutation PlaceDelete($input: PlaceDeleteInput!) {
    placeDelete(input: $input) {
      place {
        ...placeFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${placeFragment}
`

const YourPlacesContainer: React.FC = () => {
  const query = YourPlacesQuery

  const onSuccess = () => toastr.success('Place removed.')

  const { handleSubmit, mutationLoading } = useRecordSubmissionMutation({
    query: PlaceDelete,
    queryName: 'placeDelete',
    recordName: 'place',
    onSuccess,
    submitOptions: { refetchQueries: () => [{ query }] },
  })

  const removeRecord = useCallback(
    recordId => {
      handleSubmit({ id: recordId })
    },
    [handleSubmit],
  )

  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(query, 'currentUser.submittedPlaces')
  const records = get(data, 'currentUser.submittedPlaces.nodes', [])

  const isLoading = loading || mutationLoading

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loading className="isCentered isDark" />
    }

    if (records.length === 0) {
      return (
        <div className="dash-content-item">
          <p />
          <p>Hmm, looks like you haven't submitted any places yet.</p>
          <Link to={Routes.newPlace()} className="btn btn-dash-add-place">
            Submit a Place
          </Link>
        </div>
      )
    }

    return (
      <InfiniteScroll
        dataLength={records.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<Loading className="isCentered isDark" />}
        style={{ overflow: 'hidden' }}
      >
        {records.map(record => (
          <YourPlacesItem removeRecord={removeRecord} key={record.id} record={record} />
        ))}
      </InfiniteScroll>
    )
  }, [isLoading, records, fetchNextPage, hasNextPage, removeRecord])

  return (
    <section className="dash-your-places">
      <Helmet>
        <title>Your Places</title>
      </Helmet>
      <header className="dash-section-greeting">
        <h1 className="dash-greeting-heading">Your Places</h1>
        <Link to={Routes.newPlace()} className="btn btn-dash-add-place">
          Submit a Place
        </Link>
      </header>

      {renderContent()}
    </section>
  )
}

export default YourPlacesContainer
