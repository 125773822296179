import React from 'react'
import Loading from '../misc/Loading'

type Props = {
  className?: string
  disabled?: boolean
  shouldShowLoadingSpinner?: boolean
  onClick?: () => void
  text: string
  type?: 'submit' | 'button'
}
const Button: React.FC<Props> = ({ className = '', disabled, shouldShowLoadingSpinner = false, onClick, text, type = 'submit' }) => (
  <button
    className={`btn ${className} ${shouldShowLoadingSpinner ? 'isLoading' : ''}`}
    /* The button is always disabled when showing loading spinner */
    disabled={shouldShowLoadingSpinner || disabled}
    onClick={onClick}
    type={type}
  >
    {text}
    {shouldShowLoadingSpinner ? <Loading /> : ''}
  </button>
)

export default Button
