import { DocumentNode } from 'graphql'
import { useCallback } from 'react'
import toastr from 'toastr'
import { useMutation } from '../api/graphql'
import { fullMessageErrors } from '../translations/en'
import { RecordTypeInstance } from '../util/constants'

// const EVENT_EDIT = gql`
//   mutation EventUpdate($input: EventUpdateInput!) {
//     eventUpdate(input: $input) {
//       event {
//         ...eventFragment
//       }
//       errors {
//         attribute
//         message
//       }
//     }
//   }
//   ${eventFragment}
// `;

// const { handleSubmit, mutationLoading, onValidate } = useRecordSubmissionMutation({
//   action, // optional, if it ends with "Draft", validation will be skipped
//   query: EVENT_EDIT,
//   queryName: 'eventUpdate',
//   recordName: 'event',
//   beforeSubmit, // optional
//   onError,
//   onSuccess, // called with onSuccess(data[queryName][recordName])
//   submitOptions, // optional
// });

type Props = {
  action?: string
  query: DocumentNode
  queryName: string
  recordName: string
  beforeSubmit?: (doc: RecordTypeInstance) => RecordTypeInstance
  onError?: () => void
  onSuccess: (record: RecordTypeInstance) => void
  submitOptions?: Record<string, unknown>
}
type ReturnProps = {
  handleSubmit: (doc: unknown) => void
  mutationLoading: boolean
  onValidate: (model: unknown, error: unknown, callback: () => void) => void
}

const useRecordSubmissionMutation = ({
  action = '',
  query,
  queryName,
  recordName,
  beforeSubmit,
  onError,
  onSuccess,
  submitOptions,
}: Props): ReturnProps => {
  const [queryFunction, { loading: mutationLoading }] = useMutation(query)

  const handleSubmit = useCallback(
    doc => {
      const docToSubmit = beforeSubmit ? beforeSubmit(doc) : doc
      queryFunction({ variables: { input: docToSubmit }, ...submitOptions })
        .then(({ data: { [queryName]: response } }) => {
          const hasErrors = response.errors && response.errors.length > 0
          if (hasErrors) {
            const errorMessage = fullMessageErrors(response.errors)
            toastr.error(errorMessage)
            onError && onError()
            return
          }
          const record = response[recordName]
          return onSuccess(record)
        })
        .catch(error => {
          toastr.error('Please try again later', 'Server error')
          console.error(error)
          window.Rollbar && window.Rollbar.error(error)
        })
    },
    [beforeSubmit, onError, onSuccess, queryFunction, queryName, recordName, submitOptions],
  )

  // skip validation if inserting a draft
  const onValidate = useCallback((_model, _error, callback) => (action.endsWith('Draft') ? callback(null) : callback()), [action])

  return { handleSubmit, mutationLoading, onValidate }
}

export default useRecordSubmissionMutation
