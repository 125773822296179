import gql from 'graphql-tag'
import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import { EventDelete } from '../../graphql/eventDelete'
import { submittedEventFragment } from '../../graphql/fragments'
import useRecordSubmissionMutation from '../../hooks/useRecordSubmissionMutation'
import Routes from '../../startup/routes'
import { YourEventsTabScope } from '../../util/constants'
import YourEventsTabContent from '../components/dashboard/yourEvents/YourEventsTabContent'
import YourEventsTabHeader from '../components/dashboard/yourEvents/YourEventsTabHeader'

export const YourUpcomingEventsQuery = gql`
  ${submittedEventFragment}
  query YourUpcomingEventsQuery($cursor: String) {
    currentUser {
      id
      submittedUpcomingEvents(first: 30, after: $cursor) {
        nodes {
          ...submittedEventFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const YourUpcomingEventsContainer: React.FC = () => {
  const query = YourUpcomingEventsQuery

  const onSuccess = () => toastr.success('Event removed.')

  const { handleSubmit, mutationLoading } = useRecordSubmissionMutation({
    query: EventDelete,
    queryName: 'eventDelete',
    recordName: 'event',
    onSuccess,
    submitOptions: { refetchQueries: () => [{ query }] },
  })

  const removeRecord = useCallback(
    recordId => {
      handleSubmit({ id: recordId })
    },
    [handleSubmit],
  )

  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(query, 'currentUser.submittedUpcomingEvents')
  const records = get(data, 'currentUser.submittedUpcomingEvents.nodes', [])
  const isLoading = loading || mutationLoading

  return (
    <section className="dash-your-events">
      <Helmet>
        <title>Your Events</title>
      </Helmet>
      <header className="dash-section-greeting">
        <h1 className="dash-greeting-heading">Your Events</h1>
        <Link to={Routes.newEvent()} className="btn btn-dash-add-event">
          Submit an Event
        </Link>
      </header>

      <YourEventsTabHeader activeTabScope={YourEventsTabScope.UPCOMING} />
      <YourEventsTabContent
        events={records}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        removeRecord={removeRecord}
        isLoading={isLoading}
        tabScope={YourEventsTabScope.UPCOMING}
      />
    </section>
  )
}

export default YourUpcomingEventsContainer
