import React from 'react'
import ContentLoader from 'react-content-loader'

const EventsListItemPlaceholder: React.FC = () => (
  <ContentLoader height={200} width={920} speed={1} style={{ boxShadow: 'none', padding: 0 }} className="event-single">
    {/* image */}
    <rect x="619" y="11" rx="0" ry="0" width="290" height="180" />
    {/* title */}
    <rect x="31" y="11" rx="4" ry="4" width="312" height="33" />
    {/* list item left border color / class type */}
    <rect x="0" y="0" rx="0" ry="0" width="10" height="200" />
    {/* list item meta */}
    <rect x="31" y="54" rx="0" ry="0" width="568" height="18" />
    {/* list item hr */}
    <rect x="31" y="88" rx="0" ry="0" width="568" height="1" />
    {/* list item description line 1 */}
    <rect x="31" y="102" rx="0" ry="0" width="528" height="14" />
    {/* list item description line 2 */}
    <rect x="31" y="126" rx="0" ry="0" width="568" height="14" />
    {/* list item description line 3 */}
    <rect x="31" y="150" rx="0" ry="0" width="268" height="14" />
  </ContentLoader>
)

export default EventsListItemPlaceholder
