import { css } from '@emotion/react'
import get from 'lodash/get'
import { useCallback, useState } from 'react'
import toastr from 'toastr'
import { connectField } from 'uniforms'
import { ErrorField } from 'uniforms-unstyled'
import cloudinaryTools, { CloudinaryUploadFolders } from '../../../../util/cloudinaryTools'
import { UploadState, UploadStateType } from '../../../../util/constants'
import ResponsiveImage, { VideoTag } from '../../misc/MediaPresets'

interface MediaUploadFieldProps {
  className?: string
  label?: string
  name?: string
  folder?: CloudinaryUploadFolders
  onChange(...args: unknown[]): unknown
  onUploadingStateChange?(...args: unknown[]): unknown
  value: string
  allowVideo: boolean
}

const MediaUploadField: React.FC<MediaUploadFieldProps> = props => {
  const { allowVideo = true, folder = 'uploads', label, name, onChange, onUploadingStateChange, value } = props
  const [uploadState, setUploadState] = useState<UploadStateType>(UploadState.IDLE)
  const [isDraggingInProgress, setIsDraggingInProgress] = useState(false)

  const handleRemoveMedia = useCallback(() => {
    // Cloudinary.delete(value.publicId, (err, res) => {});
    // don't wait until Cloudinary API removes the media

    if (allowVideo) {
      onChange({ publicId: '', type: '' })
    } else {
      onChange({ publicId: '' })
    }

    setUploadState(UploadState.IDLE)
  }, [allowVideo, onChange, setUploadState])

  const uploadMediaToCloudinary = useCallback(
    async (file: File) => {
      if (file == null) return

      const fileIsVideo = file.type.indexOf('image') !== 0
      if (fileIsVideo && !allowVideo) {
        toastr.error('Please select valid image file.')
        return
      }

      let newUploadState: UploadStateType = UploadState.UPLOADING
      setUploadState(newUploadState)

      onUploadingStateChange && onUploadingStateChange(newUploadState)

      const options = { folder, resource_type: '' }
      if (fileIsVideo) options.resource_type = 'video'

      await cloudinaryTools.upload(file, options, (error, response) => {
        newUploadState = error ? UploadState.ERROR : UploadState.SUCCESS
        setUploadState(newUploadState)

        onUploadingStateChange && onUploadingStateChange(newUploadState)

        if (error) {
          toastr.error(error)

          allowVideo ? onChange({ publicId: '', type: '' }) : onChange({ publicId: '' })
        } else {
          if (allowVideo) {
            onChange({ publicId: response.public_id, type: fileIsVideo ? 'video' : 'image' })
          } else {
            onChange({ publicId: response.public_id })
          }
        }
      })
    },
    [allowVideo, folder, onChange, onUploadingStateChange],
  )

  const renderUploadState = useCallback(() => {
    switch (uploadState) {
      case UploadState.UPLOADING:
        return <div className="upload-status">Uploading...</div>

      case UploadState.SUCCESS:
        return <div className="upload-status success">Uploaded</div>

      case UploadState.ERROR:
        return <div className="upload-status error">Upload error</div>

      default:
        return <div className="upload-status">Drag and drop or upload from your computer.</div>
    }
  }, [uploadState])

  const fileSelectStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    .upload-status {
      font-weight: normal;
    }
    input[type='file'] {
      display: none;
    }
  `

  const renderFileSelect = useCallback(() => {
    return (
      <div css={fileSelectStyle}>
        <i
          className="icon-upload"
          css={css`
            margin: 10px;
          `}
        />
        <input
          type="file"
          id={`imgInp-${name}`}
          onChange={e => {
            const file = e.currentTarget.files[0]
            uploadMediaToCloudinary(file)
          }}
        />
        {renderUploadState()}
      </div>
    )
  }, [name, fileSelectStyle, renderUploadState, uploadMediaToCloudinary])

  const renderMediaPreview = useCallback(() => {
    const { publicId, type } = value
    return (
      <div
        className="media-preview"
        css={css`
          position: relative;
          height: 100%;
          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        `}
      >
        {type === 'video' ? <VideoTag publicId={publicId} /> : <ResponsiveImage publicId={publicId} />}
        <button
          onClick={handleRemoveMedia}
          type="button"
          // make it easier to hit with the finger with 30x30px
          css={css`
            margin-right: 0;
            margin-top: 0;
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 0;
            width: 30px;
            height: 30px;
            background-color: transparent;
          `}
        >
          <i
            className="icon-remove2"
            css={css`
              margin: 0;
              background-color: white;
              color: #d95859;
              font-size: 16px;
            `}
          />
        </button>
      </div>
    )
  }, [handleRemoveMedia, value])

  const publicId = get(value, 'publicId')
  const body = publicId ? renderMediaPreview() : renderFileSelect()

  const className = props.className ? `${props.className} file-upload` : 'file-upload'

  const uploadBoxStyle = css`
    height: 100%;
    width: 100%;
    & > * {
      padding: 15px;
    }
    border-radius: 5px;
    border: 1px dashed #959595;
    background-color: ${isDraggingInProgress ? 'white' : 'transparent'};
  `

  const preventer = e => {
    e.preventDefault()
  }

  return (
    <div
      className={className}
      css={uploadBoxStyle}
      onDragOver={e => {
        preventer(e)
        setIsDraggingInProgress(true)
      }}
      onDragLeave={e => {
        preventer(e)
        setIsDraggingInProgress(false)
      }}
      onDragEnd={e => {
        preventer(e)
        setIsDraggingInProgress(false)
      }}
      onDrop={e => {
        preventer(e)
        const droppedFiles = e.dataTransfer.files

        setIsDraggingInProgress(false)
        uploadMediaToCloudinary(droppedFiles[0])
      }}
    >
      {publicId ? (
        body
      ) : (
        <label
          css={css`
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
          htmlFor={`imgInp-${name}`}
        >
          <div>{label}</div>
          {body}
        </label>
      )}
      {props.children}
    </div>
  )
}

MediaUploadField.defaultProps = {
  allowVideo: true,
  folder: 'uploads',
}

const UniformMediaUploadField = props => {
  return (
    <>
      <MediaUploadField {...props}></MediaUploadField>
      <div className="form-error">
        <ErrorField name="publicId" />
      </div>
    </>
  )
}

export default connectField(UniformMediaUploadField)
