import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import useCommunityIdOrSlug from '../../../hooks/useCommunityIdOrSlug'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'
import UserNav from './UserNav'

const PublicNav: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext)

  let communitySlug = useCommunityIdOrSlug()
  if (currentUser) {
    communitySlug = undefined
  }

  return (
    <nav className="public-nav">
      <ul>
        <li>
          <Link to={Routes.events(communitySlug)}>
            <i className="icon-events-red" aria-hidden="true" />
            <span>Events</span>
          </Link>
        </li>
        <li>
          <Link to={Routes.places(communitySlug)}>
            <i className="icon-location-green" aria-hidden="true" />
            <span>Places</span>
          </Link>
        </li>
        <li>
          <Link to={Routes.stories(communitySlug)}>
            <i className="icon-bullhorn-blue" aria-hidden="true" />
            <span>Stories</span>
          </Link>
        </li>
      </ul>
      <UserNav />
    </nav>
  )
}

export default PublicNav
