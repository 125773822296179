import gql from 'graphql-tag'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import set from 'lodash/set'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import Event from '../../api/events/model'
import { useQuery } from '../../api/graphql'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import useEventFormData from '../../hooks/useEventFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import EventEdit from '../components/events/EventEdit'

const EventEditQuery = gql`
  query EventEditQuery($slug: String!) {
    event(slug: $slug) {
      id
      slug
      title
      description
      category
      subcategory
      dressCode
      image_publicId: imagePublicId
      startAt
      endAt
      costTier
      costMin
      costMax
      eventType
      repeats
      repeatStopsOn
      repeatingEventId
      publishState
      tags
      useTempPlace
      submitterOwnsEvent
      needTicketOrRsvp
      ticketOrRsvpUrl

      # TODO: many-to-many
      communities {
        id
        displayName
      }
      place {
        id
        slug
        name
      }

      submitter {
        id
      }

      organizer_email: organizerEmail
      organizer_name: organizerName
      organizer_phone: organizerPhone

      tempPlace_name: tempPlaceName
      tempPlace_url: tempPlaceUrl
      tempPlace_address_city: tempPlaceAddressCity
      tempPlace_address_state: tempPlaceAddressState
      tempPlace_address_streetAddress: tempPlaceAddressStreetAddress
      tempPlace_address_streetAddress2: tempPlaceAddressStreetAddress2
      tempPlace_address_zipCode: tempPlaceAddressZipCode

      isVirtual
      url
    }
  }
`

const EventEditContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const { data, loading } = useEventFormData()

  const { data: eventData, loading: loadingEvent } = useQuery(EventEditQuery, { variables: { slug: id }, skip: loadingCurrentUser })

  // {...event} is needed to convert Event to JS object w/o functions
  // for example `omitBy(isNil)` assigned place.fullAddress as a value
  const event = new Event(omitBy({ ...eventData?.event }, isNil))
  event.organizer = omitBy(event.organizer, isNil)
  event.tempPlace = omitBy(event.tempPlace, isNil)
  set(event, 'tempPlace.address', omitBy(event.tempPlace?.address, isNil))
  set(event, 'placeId', event.place?.id)
  event.primaryCommunityId = event.community?.id

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <EventEdit loading={loading || loadingCurrentUser || loadingEvent} event={event} />
    </CommunitiesContextProvider>
  )
}

export default EventEditContainer
