import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useRedirect = (to: void | null | false | string): void => {
  const history = useHistory()

  useEffect(() => {
    if (to) history.replace(to)
  }, [history, to])
}

export default useRedirect
