import gql from 'graphql-tag'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../api/graphql'
import { storyFragment } from '../../graphql/fragments'
import { useRedirect } from '../../hooks'
import Routes from '../../startup/routes'
import StoryView from '../components/stories/StoryView'

const StoryViewQuery = gql`
  ${storyFragment}
  query StoryView($storySlug: String!) {
    story(slug: $storySlug) {
      ...storyFragment
      content
    }
  }
`

const StoryViewContainer: React.FC = () => {
  const { id: storySlug } = useParams<{ id: string }>()

  const { data: { story: story = null } = {} } = useQuery(StoryViewQuery, { variables: { storySlug }, skip: !storySlug })

  useRedirect(story && story.slug !== storySlug && Routes.story(story))

  return <StoryView story={story} />
}

export default StoryViewContainer
