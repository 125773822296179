import { Dayjs } from 'dayjs'
import * as React from 'react'
import { eventIdToCommunityIndexMapType } from '../../../api/events/helpers'
import { Event } from '../../../models'
import EventsListDayFullSizeEvents from './EventsListDayFullSizeEvents'
import EventsListDayHeader from './EventsListDayHeader'
import EventsListTableItems from './EventsListTableItems'

type Props = {
  activeDay: Dayjs
  fullSizeEvents: Event[]
  tableEvents: Event[]
  eventIdToCommunityIndexMap?: eventIdToCommunityIndexMapType
}

const EventsListDay: React.FC<Props> = ({ activeDay, fullSizeEvents, tableEvents, eventIdToCommunityIndexMap }) => {
  if (tableEvents.length === 0 && fullSizeEvents.length === 0) return null

  return (
    <div className="events-day">
      <EventsListDayHeader eventDate={activeDay} />
      <EventsListDayFullSizeEvents events={fullSizeEvents} eventIdToCommunityIndexMap={eventIdToCommunityIndexMap} />
      <EventsListTableItems events={tableEvents} eventIdToCommunityIndexMap={eventIdToCommunityIndexMap} />
    </div>
  )
}

export default EventsListDay
