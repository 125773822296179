import gql from 'graphql-tag'
import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import { storyFragment } from '../../graphql/fragments'
import useRecordSubmissionMutation from '../../hooks/useRecordSubmissionMutation'
import Routes from '../../startup/routes'
import YourStoriesItem from '../components/dashboard/yourStories/YourStoriesItem'
import Loading from '../components/misc/Loading'

export const YourStoriesQuery = gql`
  query YourStoriesQuery($cursor: String) {
    currentUser {
      id
      submittedStories(first: 30, after: $cursor) {
        nodes {
          ...storyFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${storyFragment}
`

const StoryDelete = gql`
  mutation StoryDelete($input: StoryDeleteInput!) {
    storyDelete(input: $input) {
      story {
        ...storyFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${storyFragment}
`

const YourStoriesContainer: React.FC = () => {
  const query = YourStoriesQuery

  const onSuccess = () => toastr.success('Story removed.')

  const { handleSubmit, mutationLoading } = useRecordSubmissionMutation({
    query: StoryDelete,
    queryName: 'storyDelete',
    recordName: 'story',
    onSuccess,
    submitOptions: { refetchQueries: () => [{ query }] },
  })

  const removeRecord = useCallback(
    recordId => {
      handleSubmit({ id: recordId })
    },
    [handleSubmit],
  )

  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(query, 'currentUser.submittedStories')
  const records = get(data, 'currentUser.submittedStories.nodes', [])

  const isLoading = loading || mutationLoading

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loading className="isCentered isDark" />
    }

    if (records.length === 0) {
      return (
        <div className="dash-content-item">
          <p />
          <p>{`Hmm, looks like you haven't submitted any stories yet.`}</p>
          <Link to={Routes.newStory()} className="btn btn-dash-add-story">
            Submit a Story
          </Link>
        </div>
      )
    }

    return (
      <InfiniteScroll
        dataLength={records.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<Loading className="isCentered isDark" />}
        style={{ overflow: 'hidden' }}
      >
        {records.map(record => (
          <YourStoriesItem removeRecord={removeRecord} key={record.id} record={record} />
        ))}
      </InfiniteScroll>
    )
  }, [isLoading, records, fetchNextPage, hasNextPage, removeRecord])

  return (
    <section className="dash-your-stories">
      <Helmet>
        <title>Your Stories</title>
      </Helmet>
      <header className="dash-section-greeting">
        <h1 className="dash-greeting-heading">Your Stories</h1>
        <Link to={Routes.newStory()} className="btn btn-dash-add-story">
          Submit a Story
        </Link>
      </header>

      {renderContent()}
    </section>
  )
}

export default YourStoriesContainer
