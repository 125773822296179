import { Event, Place, Story } from '../models'

export const CommunityLiveState = {
  PRIVATE: 'Private',
  LIVE: 'Live',
} as const

export const YourEventsTabScope = {
  UPCOMING: 'Upcoming',
  PAST: 'Past',
} as const

export const PublishState = {
  ARCHIVED: 'archived',
  APPROVED: 'approved',
  DENIED: 'denied',
  DRAFT: 'draft',
  FLAGGED: 'flagged',
  PENDING: 'pending',
  PENDING_AUTO_REVIEW: 'pending_auto_review',
} as const
export type PublishStateType = (typeof PublishState)[keyof typeof PublishState]

export const RepeatingPatterns = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY_SAME_DATE: 'monthly on the same date of the month',
  MONTHLY_NTH_DOW: 'monthly on nth day of week',
  MONTHLY_LAST_DOW: 'monthly on last occurrence day of week',
  WEEKDAYS: 'every weekday (Mon to Fri)',
  ANNUALLY: 'annually',
  REQUEST_SCHEDULE: 'I need different repeating schedule',
} as const

export const repeatingPatternIsCustom = (repeatingPattern?: RepeatingPatternsType): boolean =>
  !repeatingPattern || !Object.values(RepeatingPatterns).includes(repeatingPattern)

export const Resource = {
  EVENT: 'event',
  STORY: 'story',
  PLACE: 'place',
} as const
export type ResourceType = (typeof Resource)[keyof typeof Resource]

export const EventTypes = {
  SINGLE_EVENT: 'singleEvent',
  REPEATING_EVENT: 'repeatingEvent',
  ONGOING_EVENT: 'ongoingEvent',
} as const

export default EventTypes

export const EventTypeLabel = {
  [EventTypes.SINGLE_EVENT]: 'Single Event',
  [EventTypes.REPEATING_EVENT]: 'Event Series',
}
export const EventTypeDescription = {
  [EventTypes.SINGLE_EVENT]: 'One event on a single day.',
  [EventTypes.REPEATING_EVENT]: 'Repeating or multi-day events.',
}

export type RecordTypeInstance = Event | Story | Place
// https://stackoverflow.com/a/53662389/336806
export type EventTypesType = (typeof EventTypes)[keyof typeof EventTypes]
export type RepeatingPatternsType = (typeof RepeatingPatterns)[keyof typeof RepeatingPatterns]

export const Weekdays = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
} as const
// export type WeekdaysType = valueof Weekdays
export type WeekdaysType = (typeof Weekdays)[keyof typeof Weekdays]

export const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_API_KEY
if (!MAPBOX_ACCESS_TOKEN) {
  throw new Error('You must set MAPBOX_API_KEY in your environment variables')
}

export const UploadState = {
  IDLE: 'idle',
  UPLOADING: 'uploading',
  ERROR: 'error',
  SUCCESS: 'success',
} as const
export type UploadStateType = (typeof UploadState)[keyof typeof UploadState]

export const DEFAULT_MAP_CENTER_COORDINATES = { lat: 41.65649719441145, lng: -72.7294921875 }
