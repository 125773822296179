import { QueryLazyOptions } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { set } from 'lodash'
import * as React from 'react'
import * as Apollo from 'react-apollo'
import * as models from '../models'

/*
Typescript support for circular references is coming soon
https://github.com/microsoft/TypeScript/pull/33050

type Data =
  | null
  | void
  | number
  | string
  | Data[]
  | {
      __typename?: string;
      [key: string]: Data;
    };

type DataWithModels =
  | null
  | void
  | number
  | string
  | DataWithModels[]
  | {
      __typename?: string;
      [key: string]: DataWithModels;
    };
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataWithModels = any

const convertToModels = (data?: Data): DataWithModels => {
  if (Array.isArray(data)) {
    return data.map(convertToModels)
  } else if (data == null || typeof data !== 'object' || Object.getPrototypeOf(data).constructor !== Object) {
    return data
  }

  const Model = models[data.__typename]
  const convertedData: DataWithModels = {}

  for (const [key, value] of Object.entries(data)) {
    /* key.split converts data like:
     * address_city
     * address_state
     * address_streetAddress
     * address_zipCode
     *
     * to:
     *
     * address { city, state, streetAddress, zipCode }
     */
    set(convertedData, key.startsWith('_') ? key : key.split('_'), convertToModels(value))
  }

  return Model ? new Model(convertedData) : convertedData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertResponse = ({ data, ...rest }: any): any => ({
  data: convertToModels(data),
  ...rest,
})

type useQueryType = <TData = unknown, TVariables = Apollo.OperationVariables>(
  query: DocumentNode,
  options?: Apollo.QueryHookOptions<TData, TVariables>,
) => Apollo.QueryResult<DataWithModels, TVariables>
export const useQuery: useQueryType = (query, options) => {
  const response = Apollo.useQuery(query, options)

  return React.useMemo(() => convertResponse(response), [response])
}

type useLazyQueryType = <TData = unknown, TVariables = Apollo.OperationVariables>(
  query: DocumentNode,
  options?: Apollo.QueryHookOptions<TData, TVariables>,
) => [(opts?: QueryLazyOptions<TVariables>) => void, Apollo.QueryResult<TData, TVariables>]

export const useLazyQuery: useLazyQueryType = (query, options) => {
  const [method, response] = Apollo.useLazyQuery(query, options)

  return [method, React.useMemo(() => convertResponse(response), [response])]
}

export const useMutation = <TData = unknown, TVariables = Apollo.OperationVariables>(
  mutation: DocumentNode,
  options?: Apollo.MutationHookOptions<TData, TVariables>,
): Apollo.MutationTuple<DataWithModels, TVariables> => {
  const [method, response] = Apollo.useMutation(mutation, options)

  return [
    React.useCallback((...args) => method(...args).then(convertResponse), [method]),
    React.useMemo(() => convertResponse(response), [response]),
  ]
}
