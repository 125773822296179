import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Link } from 'react-router-dom'
import { Place } from '../../../../models'
import Routes from '../../../../startup/routes'
import { iconUrlForCategory } from '../../../../util/placeCategoryIconMapping'
import Loading from '../../misc/Loading'
import Modal from '../../misc/Modal'
import PublishStateAction from '../publishState/PublishStateAction'
import YourRecordInlineDelete from '../your/YourRecordInlineDelete'

interface YourPlacesItemProps {
  record: Place
}

export default class YourPlacesItem extends React.Component<YourPlacesItemProps> {
  state = {
    isLoading: false,
    isModalOpen: false,
  }

  openConfirmModal = (): void => this.setState({ isModalOpen: true })

  removePlace = (): void => {
    const { record: place } = this.props
    this.closeModal()

    this.setState({ isLoading: true })
    this.props.removeRecord(place.id)
  }

  closeModal = (): void => this.setState({ isModalOpen: false })

  renderModal(): JSX.Element {
    const { record: place } = this.props

    return (
      <Modal isOpen={this.state.isModalOpen} onRequestClose={this.closeModal}>
        <h1>Remove: {get(place, 'title')}</h1>
        <hr style={{ margin: '1em 0' }} />
        <div style={{ textAlign: 'right' }}>
          <div>Are you sure you want to remove this place?</div>
          <div style={{ paddingTop: '20px' }}>
            <button type="button" className="btn btn-grey" onClick={this.closeModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-red" onClick={this.removePlace}>
              Remove
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  render(): JSX.Element {
    const { record: place } = this.props
    const { isLoading } = this.state
    const viewPlaceUrl = place.isPremium ? Routes.premiumPlace(place) : Routes.place(place)

    return (
      <article className="dash-slab">
        {this.renderModal()}
        <div className="dash-slab-place-icon">
          <Link to={viewPlaceUrl}>
            <img src={iconUrlForCategory(place.category)} alt="" />
          </Link>
        </div>
        <div className="dash-slab-info">
          <h2 className="dash-slab-heading">
            <Link className="dash-slab-heading-place" to={viewPlaceUrl}>
              {place.title}
            </Link>
          </h2>
          <ul className="dash-slab-list">
            <li>
              <i className="icon-pin" />
              {place.fullAddress}
            </li>
            {place.category && (
              <li>
                <i className="icon-folder" />
                {place.category}
              </li>
            )}
            {!isEmpty(place.tags) && (
              <li>
                <i className="icon-tag" />
                {place.tags.join(', ')}
              </li>
            )}
          </ul>
        </div>

        <div className="dash-slab-actions">
          <Link to={Routes.editPlace(place)} className="dash-slab-action">
            <PublishStateAction publishState={place.publishState} />
          </Link>
          {isLoading ? (
            <div className="dash-slab-action">
              <Loading className="isCentered isCollapsed isDark" />
            </div>
          ) : (
            <YourRecordInlineDelete onClick={this.openConfirmModal} />
          )}
        </div>
      </article>
    )
  }
}
