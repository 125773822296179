import gql from 'graphql-tag'
import React, { useCallback, useContext, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { Link, Redirect } from 'react-router-dom'
import { AutoForm } from 'uniforms-unstyled'
import { useMutation } from '../../../api/graphql'

import { CurrentUserContext, currentUserFragment, CurrentUserQuery } from '../../../providers/CurrentUserContext'
import SignUpSchema from '../../../schemas/users/signUpSchema'
import Routes from '../../../startup/routes'
import { fullMessageErrors } from '../../../translations/en'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import SignUpFields from './SignUpFields'

const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      user {
        ...currentUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${currentUserFragment}
`

type SignUpProps = {
  selectedCommunityId?: string
}
const SignUp: React.FC<SignUpProps> = props => {
  const client = useApolloClient()
  const { selectedCommunityId } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { currentUser } = useContext(CurrentUserContext)
  const [signUp] = useMutation(SIGN_UP)

  const handleSubmit = useCallback(
    doc => {
      setLoading(true)
      setError(null)
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { _acceptTerms, ...userDoc } = doc
      signUp({ variables: { input: userDoc } }).then(
        ({
          data: {
            signUp: { user, errors },
          },
        }) => {
          if (errors) {
            setLoading(false)
            setError(fullMessageErrors(errors))
          } else {
            setError(null)
            client.writeQuery({ query: CurrentUserQuery, data: { currentUser: user } })
          }
        },
      )
    },
    [client, signUp, setLoading, setError],
  )

  if (currentUser) return <Redirect to={Routes.dash()} />

  return (
    <div className="account-overlay">
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <section className="account-modal">
        <h1 className="sign-up-heading">Create an account.</h1>
        <h5 className="sign-up-subtitle">Yep, it’s free and it always will be!</h5>

        {/* <div className="hr-text">Sign up with:</div> */}
        {/* <div className="hr-text">or sign up with email</div> */}

        <AutoForm
          schema={SignUpSchema}
          model={{ primaryCommunityId: selectedCommunityId }}
          onSubmit={handleSubmit}
          className="form account-form account-form-signUp"
        >
          {error && (
            <h4 className="form-error" style={{ fontWeight: 'normal' }}>
              <div>{error}</div>
            </h4>
          )}

          <SignUpFields {...props} />

          <UniformsButton shouldShowLoadingSpinner={loading} text="Sign up" />
        </AutoForm>

        <p className="prompt-sign-in">
          Already have an account? <Link to={Routes.signIn()}>Sign in!</Link>
        </p>
      </section>
    </div>
  )
}

export default SignUp
