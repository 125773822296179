import cloneDeep from 'lodash/cloneDeep'
import { SubmittableStoryCreateSchema, SubmittableStoryUpdateSchema } from '../../schemas/stories/storySchema'
import Story from './model'

export const prepareToSubmit = (originalStory: Story): Story => {
  const story = cloneDeep(originalStory)

  story.flatObjectFields('image')

  const serialized = JSON.parse(JSON.stringify(story))

  return story.isNew
    ? SubmittableStoryCreateSchema.clean(serialized, { removeEmptyStrings: false })
    : SubmittableStoryUpdateSchema.clean(serialized, { removeEmptyStrings: false })
}
