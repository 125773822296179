import { css } from '@emotion/react'
import debounce from 'lodash/debounce'
import React from 'react'

export default class SearchBox extends React.Component {
  state = {
    value: '',
  }

  doSearch = debounce(async () => {
    const searchTerm = this.search.value
    if (searchTerm.length < 3) {
      return
    }

    try {
      this.props.setSearchResults(await this.props.handleSearch(searchTerm))
    } catch (error) {
      console.error(error.message)
    }
  }, 500)

  emptySearch = (): void => this.handleChange('')

  handleChange = (value: string): void => {
    this.setState({ value })
    this.doSearch()
    this.props.onChange && this.props.onChange(value)
  }

  render(): JSX.Element {
    return (
      <form className="search-widget" onSubmit={e => e.preventDefault()}>
        <i
          className="icon-search"
          css={css`
            && {
              position: absolute;
              left: 14px;
              top: 50%;
              transform: translateY(-50%) scale(1.3);
              color: #434343;
            }
          `}
        />
        <input
          className="sidebar-search-input"
          onChange={e => {
            this.handleChange(e.target.value)
          }}
          placeholder={this.props.placeholder}
          ref={e => (this.search = e)}
          type="search"
          value={this.state.value}
        />
        {this.state.value && (
          <i
            className="icon-cancel-circle"
            onClick={this.emptySearch}
            css={css`
              && {
                cursor: pointer;
                padding: 5px;
                margin: 0;
                width: 32px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) scale(1.5);
              }
            `}
          />
        )}
      </form>
    )
  }
}
