/* eslint-disable */
import cloudinary from 'cloudinary-core'
import { Image, Transformation, Video } from 'cloudinary-react'

export function mediaSrc({ publicId, width = 'auto', height, dpr = 'auto', type = 'image', radius = null }) {
  // terrible hack since cloudinary-react doesn't support returning image src
  // https://github.com/cloudinary/cloudinary-react/issues/21
  if (publicId) {
    const fill = new cloudinary.Transformation()
    fill.width(width).height(height).radius(radius).crop('fill').dpr(dpr).gravity('auto').quality('auto')

    const crop = new cloudinary.Transformation()
    crop.width(width).height(height)
    const src = new cloudinary.Cloudinary({ cloud_name: 'civiclift' }).url(publicId, fill, crop)
    if (type === 'video') {
      // if the file is video, return video poster url generated by cloudinary
      // https://cloudinary.com/documentation/video_manipulation_and_delivery#embedding_videos_in_web_pages
      return `${src.replace(/\/civiclift\/image\/upload\//, '/civiclift/video/upload/')}.jpg`
    }
    return src
  }
  return '/img/fpo.png'
}

interface ResponsiveImageProps {
  publicId?: string
  alt?: string
  width?: string
  height?: string
  responsive?: boolean
}

// scale - doesn't maintain aspect ratio
// fit - scales up images to fit
// mfit - scales up smaller images to fit
// fill - scales down images to fit
// lfill - scales down big images to fit
// limit - scales down so that the whole image is visible
// pad, lpad - fills image outline with white/transparent background
// mpad - does not scale the image down
// crop - crops image to fit exactly given bounds
// thumb - together with gravity="face" creates profile thumbnail from image using face recognition
//         in practice didn't show to be practical
// imagga_crop and imagga_scale - additional add-ons that are currently not available
const ResponsiveImage = ({ publicId, alt = '', width, height, responsive = true }: ResponsiveImageProps) => {
  if (publicId) {
    return (
      <Image cloudName="civiclift" publicId={publicId} responsive={responsive} alt={alt}>
        <Transformation crop="fill" width={width} height={height} dpr="auto" gravity="auto" quality="auto" format="auto" />
        <Transformation crop="crop" width={width} height={height} />
      </Image>
    )
  }

  return <img src="/img/fpo.png" alt={alt} />
}

interface VideoTagProps {
  publicId?: string
}

export const VideoTag = ({ publicId, autoPlay = true, loop = true, muted = true, playsInline = true, ...props }: VideoTagProps) => (
  <Video cloudName="civiclift" publicId={publicId} autoPlay={autoPlay} loop={loop} muted={muted} playsInline={playsInline} {...props} />
)

export default ResponsiveImage
