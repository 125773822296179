import { useCallback } from 'react'
import { UploadState, UploadStateType } from '../util/constants'

export type UseOnImageUploadStateChangeReturnType = (uploadingState: UploadStateType) => void
type setIsSubmitDisabledType = React.Dispatch<React.SetStateAction<boolean>>

const useOnImageUploadStateChange = (setIsSubmitDisabled: setIsSubmitDisabledType): UseOnImageUploadStateChangeReturnType =>
  useCallback((uploadingState: UploadStateType) => setIsSubmitDisabled(uploadingState === UploadState.UPLOADING), [setIsSubmitDisabled])

export default useOnImageUploadStateChange
