import * as React from 'react'
import keyBy from 'lodash/keyBy'
import { featureCollection } from '@turf/helpers'
import { GeoJSONSource } from 'mapbox-gl'
import { Source, Layer, MapContext } from 'react-mapbox-gl'

const sourceId = 'place-source'
const placeMarkerLayerId = 'place-markers'
const placeTextLayerId = 'place-marker-text'
export const placeLayerIds = [placeMarkerLayerId, placeTextLayerId]
const clusterMaxZoom = 17

const PlacesLayer = ({ currentPlace, places, onPlaceClick, onClusterClick }) => {
  const features = React.useMemo(
    () =>
      featureCollection(
        places
          .filter(place => place.geo)
          .map(place => ({
            ...place.geo,
            properties: {
              ...place.geo.properties,
              'is-selected': place.id === currentPlace?.id,
              'premium-image': place.mainImage?.publicId ?? null,
            },
          })),
      ),
    [places, currentPlace],
  )

  const placesById = React.useMemo(() => keyBy(places, 'id'), [places])

  const map = React.useContext(MapContext)

  React.useEffect(() => {
    if (map) {
      const handler = e => {
        if (e.features && e.features.length) {
          const [feature] = e.features
          if (feature.properties.cluster_id) {
            const source = map.getSource(sourceId) as GeoJSONSource
            source.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
              if (!err) {
                onClusterClick(feature.geometry.coordinates, zoom + 2)
              }
            })
          } else {
            const place = placesById[feature.properties.id] ?? null
            onPlaceClick(place)
          }
        }
      }

      map.on('click', placeMarkerLayerId, handler)
      map.on('click', placeTextLayerId, handler)

      return () => {
        map.off('click', placeMarkerLayerId, handler)
        map.off('click', placeTextLayerId, handler)
      }
    }
  }, [map, onPlaceClick, onClusterClick, placesById])

  const accumulator = property => [
    ['case', ['to-boolean', ['get', 'is-selected']], ['get', property], ['coalesce', ['accumulated'], ['get', property]]],
    ['get', property],
  ]

  const setCursor = cursor => {
    if (map) {
      map.getCanvas().style.cursor = cursor
    }
  }

  return (
    <>
      <Source
        id={sourceId}
        geoJsonSource={{
          type: 'geojson',
          data: features,
          cluster: true,
          clusterRadius: 20,
          clusterMaxZoom,
          clusterProperties: {
            'marker-color': accumulator('marker-color'),
            title: accumulator('title'),
            // not used directly, but needed for accumulator to work 🤷
            'is-selected': ['coalesce', ['get', 'is-selected']],
            'cluster-colors': [
              [
                'case',
                ['in', ['get', 'cluster-colors-2'], ['accumulated']],
                ['accumulated'],
                ['concat', ['accumulated'], '_', ['get', 'cluster-colors']],
              ],
              ['get', 'marker-color'],
            ],
          },
        }}
      />
      <Layer
        id={placeTextLayerId}
        sourceId={sourceId}
        type="symbol"
        layout={{
          'text-field': [
            'format',
            ['get', 'title'],
            {},
            ['case', ['has', 'point_count'], ['concat', '\n+', ['-', ['get', 'point_count'], 1], ' more'], ''],
            { 'font-scale': 0.8 },
          ],
          'text-variable-anchor': ['top', 'left', 'bottom', 'right'],
          'text-justify': 'auto',
          'text-radial-offset': ['case', ['to-boolean', ['get', 'premium-image']], 2, 1.5],
          'text-max-width': 9,
          'text-size': 13.5,
          'text-line-height': 1.33,
          'text-optional': true,
        }}
        paint={{
          'text-color': ['get', 'marker-color'],
          'text-halo-width': 1,
          'text-halo-color': 'white',
        }}
        onMouseEnter={() => setCursor('pointer')}
        onMouseLeave={() => setCursor('')}
      />
      <Layer
        id={placeMarkerLayerId}
        sourceId={sourceId}
        type="symbol"
        layout={{
          'icon-image': [
            'case',
            ['has', 'point_count'],
            ['concat', 'marker_cluster', ['min', 3, ['get', 'point_count']], '_', ['get', 'marker-color'], '_', ['get', 'cluster-colors']],
            ['to-boolean', ['get', 'premium-image']],
            ['concat', 'premium-marker_', ['get', 'marker-color'], '_', ['get', 'premium-image']],
            ['concat', 'marker_', ['get', 'marker-symbol'], '_', ['get', 'marker-color']],
          ],
          'icon-allow-overlap': true,
        }}
        paint={{
          'icon-halo-width': 0,
        }}
        onMouseEnter={() => setCursor('pointer')}
      />
    </>
  )
}

export default React.memo(PlacesLayer)
