import cloneDeep from 'lodash/cloneDeep'
import { SubmittablePlaceCreateSchema, SubmittablePlaceUpdateSchema } from '../../schemas/places/placeSchema'
import { Address } from '../types'
import Place from './model'

export const getPlaceAddress = (placeAddress?: Address): string => {
  if (!placeAddress) {
    return 'Unknown address'
  }

  const { streetAddress, streetAddress2, city, state, zipCode } = placeAddress
  const str = `${streetAddress || ''} ${streetAddress2 || ''} ${city || ''} ${state || ''} ${zipCode || ''}`.trim()
  return str.replace(/\s\s+/g, ' ')
}

export const hasValidLatLng = (place: Place): boolean => {
  let valid = false
  if (place && place.geo && place.geo.geometry && place.geo.geometry.coordinates) {
    const coordinates = place.geo.geometry.coordinates
    valid = typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number'
  }
  return valid
}

export const prepareToSubmit = (originalPlace: Place): Place => {
  const place = cloneDeep(originalPlace)

  place.flatObjectFields('address')
  place.flatObjectFields('contact')
  place.flatObjectFields('operatingHours')
  place.flatObjectFields('operatingHoursMonday')
  place.flatObjectFields('operatingHoursTuesday')
  place.flatObjectFields('operatingHoursWednesday')
  place.flatObjectFields('operatingHoursThursday')
  place.flatObjectFields('operatingHoursFriday')
  place.flatObjectFields('operatingHoursSaturday')
  place.flatObjectFields('operatingHoursSunday')
  place.flatObjectFields('socialUrls')
  place.flatObjectFields('mainImage')

  place.media = place.media.filter(media => media && media.publicId)

  const serialized = JSON.parse(JSON.stringify(place))

  return place.isNew
    ? SubmittablePlaceCreateSchema.clean(serialized, { removeEmptyStrings: false })
    : SubmittablePlaceUpdateSchema.clean(serialized, { removeEmptyStrings: false })
}
