import gql from 'graphql-tag'
import React, { useCallback } from 'react'
import { useApolloClient } from 'react-apollo'
import { useMutation } from '../../../api/graphql'

const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      success
    }
  }
`

const LogoutLink: React.FC = ({ children }) => {
  const client = useApolloClient()
  const [signOut] = useMutation(SIGN_OUT)

  const handleLogout = useCallback(
    e => {
      e.preventDefault()
      signOut().then(() => client.resetStore())
    },
    [client, signOut],
  )

  return (
    <a className="link" href="#" onClick={handleLogout}>
      {children || 'Logout'}
    </a>
  )
}
export default LogoutLink
