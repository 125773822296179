import dayjs from 'dayjs'
import get from 'lodash/get'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { Event, Place } from '../../../models'
import Routes from '../../../startup/routes'
import { formatDateAndTime, getTimeFromNow } from '../../../util/dateTime'
import { toMoney } from '../../../util/moneyFormat'
import ResponsiveImage from '../misc/MediaPresets'

const virtualPlaceCta = (event: Event): string => {
  const now = dayjs()
  const isAlreadyStarted = now.isAfter(dayjs(event.startAt))
  if (event.endAt) {
    const isEventHappeningNow = isAlreadyStarted && now.isBefore(dayjs(event.endAt))
    if (isEventHappeningNow) return 'Join Now'
  }

  return 'Online Event'
}

const EventPlace: React.FC<{ event: Event; place?: Place }> = ({ event, place }) => {
  if (event.isVirtual) {
    return (
      <span className="event-meta-item">
        <i className="icon-wifi-green" />
        {virtualPlaceCta(event)}
      </span>
    )
  }
  if (place) {
    if (place.isPremium) {
      return (
        <Link to={Routes.premiumPlace(place, place.community)} className="event-meta-item">
          <i className="icon-pin-green" />
          {place.title}
        </Link>
      )
    }
    return (
      <span className="event-meta-item">
        <i className="icon-pin-green" />
        {place.title}
      </span>
    )
  }
  if (event.tempPlace) {
    return (
      <span className="event-meta-item">
        <i className="icon-pin-green" />
        {event.tempPlace.name}
      </span>
    )
  }
  return null
}

const EventCost: React.FC<{ costMin?: number; costMax?: number }> = ({ costMin, costMax }) => (
  <span className="event-meta-item">
    <i className="icon-ticket-yellow" />
    {costMin === 0 && costMax === 0 ? 'FREE' : costMin === costMax ? toMoney(costMin) : `${toMoney(costMin)} - ${toMoney(costMax)}`}
  </span>
)

const EventTime: React.FC<{ event: Event }> = ({ event }) => {
  if (!event.startAt) return null
  return (
    <span className="event-meta-item">
      <i className="icon-watch-red" />
      {formatDateAndTime(event.startAt)}
      <em> ({getTimeFromNow(event.startAt)})</em>
    </span>
  )
}

const EventDescription: React.FC<{ event: Event }> = ({ event }) => (
  <p>
    {event.excerpt}
    <Link to={location => Routes.event(event, event.community, location)} className="more">
      <i className="icon-arrow-right-red" />
    </Link>
  </p>
)

const EventsListFullSizeItem: React.FC<{ event: Event; place?: Place; type: number }> = ({ event, place, type }) => (
  <li className={`event-single ${event.getClassType(type)}`}>
    <div className="event-image">
      <ResponsiveImage publicId={get(event, 'image.publicId')} width="290" height="180" />
    </div>
    <article className="event-content">
      <h2 className="event-heading">
        <Link to={location => Routes.event(event, event.community, location)}>{event.title}</Link>
      </h2>
      <div className="event-meta">
        <EventTime event={event} />
        <EventPlace event={event} place={place} />
        <EventCost costMin={event.costMin} costMax={event.costMax} />
      </div>
      <div className="event-copy">
        <EventDescription event={event} />
      </div>
    </article>
  </li>
)

export default EventsListFullSizeItem
