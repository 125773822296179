import dayjs from 'dayjs'
import find from 'lodash/find'
import OperatingHoursSchema from '../../schemas/places/operatingHoursSchema'
import { halfHourTimeValues } from '../../util/hoursInput'

export const operatingHoursValues = [{ value: null, label: 'Closed' }, ...halfHourTimeValues]

interface OpenCloseTimes {
  openTime: number
  closeTime: number
}

interface LabelGroup {
  days: string
  label: string
}

type OperatingHoursType = {
  monday: OpenCloseTimes
  tuesday: OpenCloseTimes
  wednesday: OpenCloseTimes
  thursday: OpenCloseTimes
  friday: OpenCloseTimes
  saturday: OpenCloseTimes
  sunday: OpenCloseTimes
}

export default class OperatingHoursModel {
  /* operating hours properties are added to the place.operatingHours object in
      the order the user adds them to the form, re-sorting the properties ensures the
      days are rendered in the correct order. */
  public static get daysOrder(): string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  public constructor(doc: OperatingHoursType) {
    Object.assign(this, doc)
    OperatingHoursSchema.clean(this, { removeEmptyStrings: false })
  }

  public static hoursValueToLabel(value?: number): string {
    if (value == null) {
      return find(operatingHoursValues, { value: null }).label
    }
    const result = find(operatingHoursValues, { value })
    return result ? result.label : value.toString()
  }

  // returns a key-value representation of this object that can be used in views
  // { 'Mon': '5am to 6pm', 'Tue': 'Closed', ... }
  // usage:
  // const { operatingHoursLabels } = operatingHoursModel;
  // Object.keys(operatingHoursLabels).forEach(...)
  public get operatingHoursLabels(): { [key: string]: string } {
    // we want to display: Mon, Tue, Wed... instead of full day names: Monday, Tuesday,...
    const daysLabels = {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    }
    const { daysOrder } = OperatingHoursModel
    const sortedHours = {}
    Object.keys(this)
      .sort((a, b) => a.localeCompare(b))
      .forEach(key => {
        sortedHours[key] = this[key]
      })

    const operatingHoursLabels = {}
    daysOrder.forEach(day => {
      let openTime
      let closeTime
      if (sortedHours[day]) {
        openTime = sortedHours[day].openTime
        closeTime = sortedHours[day].closeTime
      }
      const openTimeText = OperatingHoursModel.hoursValueToLabel(openTime)
      const closeTimeText = OperatingHoursModel.hoursValueToLabel(closeTime)

      const operatingHoursText = openTimeText === closeTimeText ? openTimeText : `${openTimeText} to ${closeTimeText}`

      const dayLabel = daysLabels[day]

      operatingHoursLabels[dayLabel] = operatingHoursText
    })

    return operatingHoursLabels
  }

  // returns a key-value representation grouped for same work hours
  // [{ days: 'Mon-Wed', label: '5am to 6pm' }, { days: 'Thu', label: 'Closed', ... }]
  // usage:
  // operatingHoursModel.labelGroups.map(({ days, label }, index) => <div>{days}</div>);
  public get labelGroups(): LabelGroup[] {
    const { operatingHoursLabels } = this
    let firstRepeatableDayKey
    let prevDayValue
    let lastKey

    const labelGroups: { [key: string]: string } = {}
    Object.keys(operatingHoursLabels).forEach(day => {
      // if the value is the same like for the previous day, merge the hours
      // example: { Mon: '9am to 9pm', Tue: '9am to 9pm', Wed: '9am to 9pm' }
      // becomes: { 'Mon-Wed': '9am to 9pm' }
      if (operatingHoursLabels[day] === prevDayValue) {
        const newKey = `${firstRepeatableDayKey}-${day}`
        labelGroups[newKey] = labelGroups[lastKey]
        delete labelGroups[lastKey]
        lastKey = newKey
      } else {
        labelGroups[day] = operatingHoursLabels[day]
        firstRepeatableDayKey = day
        lastKey = day
      }
      prevDayValue = operatingHoursLabels[day]
    })

    return Object.entries(labelGroups).map(([days, label]) => ({ days, label }))
  }

  public openCloseTimesToday(today = dayjs()): OpenCloseTimes {
    // day of week, 0-based, starting from Monday as the first day
    // date.day() week starts with Sunday as 0, we add 6 to get Monday at 0
    const dayOfWeekNumber = (today.day() + 6) % 7
    const dayOfWeek = OperatingHoursModel.daysOrder[dayOfWeekNumber]
    return this[dayOfWeek]
  }

  // returns whether the place is open since yesterday
  // for example when it's 1am and the place is open until 3am the previous day
  private get isOpenFromYesterday(): boolean {
    const now = dayjs()
    const yesterday = now.subtract(1, 'day')
    const openCloseTimes = this.openCloseTimesToday(yesterday)
    if (!openCloseTimes || !openCloseTimes.openTime || !openCloseTimes.closeTime) {
      return false
    }
    const openTimeMinutes = openCloseTimes.openTime
    let closeTimeMinutes = openCloseTimes.closeTime
    if (closeTimeMinutes >= openTimeMinutes) {
      return false
    }
    closeTimeMinutes += 24 * 60
    const closeTime = yesterday.startOf('day').set('minute', closeTimeMinutes)

    return now.isBefore(closeTime)
  }

  public get closingTimeLabel(): void | string {
    if (!this.isOpenNow) {
      return undefined
    }

    const now = dayjs()
    const yesterday = now.subtract(1, 'day')
    if (this.isOpenFromYesterday) {
      return OperatingHoursModel.hoursValueToLabel(this.openCloseTimesToday(yesterday).closeTime)
    }

    const openCloseTimes = this.openCloseTimesToday()
    if (openCloseTimes.openTime === openCloseTimes.closeTime) {
      // closes at midnight
      return OperatingHoursModel.hoursValueToLabel(24 * 60)
    }

    return OperatingHoursModel.hoursValueToLabel(openCloseTimes.closeTime)
  }

  public get isOpenNow(): boolean {
    const now = dayjs()
    const today = now.startOf('day')
    const openCloseTimes = this.openCloseTimesToday()
    if (!openCloseTimes || !openCloseTimes.openTime) {
      return this.isOpenFromYesterday
    }
    let openTimeMinutes = openCloseTimes.openTime
    if (openTimeMinutes === 24 * 60) {
      openTimeMinutes = 0
    }
    let closeTimeMinutes = openCloseTimes.closeTime
    if (!closeTimeMinutes || openTimeMinutes === closeTimeMinutes) {
      closeTimeMinutes = 24 * 60 // assume the place closes at midnight
    }
    if (closeTimeMinutes < openTimeMinutes) {
      closeTimeMinutes += 24 * 60 // it closes tomorrow am
    }
    const openTime = today.set('minute', openTimeMinutes)
    const closeTime = today.set('minute', closeTimeMinutes)

    // openTime < now && now < closeTime
    if (openTime.isBefore(now) && now.isBefore(closeTime)) {
      return true
    }
    return this.isOpenFromYesterday
  }
}
