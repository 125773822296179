import React from 'react'

type Props = { className?: string }
const Loading: React.FC<Props> = ({ className = '', ...props }) => (
  <span className={`loadingSpinner ${className}`} {...props}>
    Loading...
  </span>
)

export default Loading
