import get from 'lodash/get'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Event } from '../../../../models'
import Routes from '../../../../startup/routes'
import { formatDateAndTime, formatTime, getTimeFromNow } from '../../../../util/dateTime'
import Analytics from '../../misc/Analytics'
import Loading from '../../misc/Loading'
import ResponsiveImage from '../../misc/MediaPresets'
import Modal from '../../misc/Modal'
import PublishStateAction from '../publishState/PublishStateAction'
import YourRecordInlineDelete from '../your/YourRecordInlineDelete'

import 'chartkick/chart.js'
import { LineChart } from './react-chartkick'

const Address: React.FC<{ event: Event }> = ({ event }) => {
  const { place } = event
  const addressName = place ? get(place, 'name') : get(event, 'tempPlace.name')
  const streetAddress = place ? get(place, 'address.streetAddress') : get(event, 'tempPlace.address.streetAddress')

  return (
    <div>
      {addressName}
      <br />
      {streetAddress}
    </div>
  )
}

type Props = { record: Event; removeRecord: (eventId?: string) => void }
const YourEventsItem: React.FC<Props> = ({ record: event, removeRecord }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [singleFollowingOrAll, setSingleFollowingOrAll] = useState<'single' | 'following' | 'all'>('single');

  const openConfirmModal = useCallback((): void => setIsModalOpen(true), [])

  const closeModal = useCallback(() => setIsModalOpen(false), [])

  const removeEvent = useCallback((): void => {
    closeModal()
    setIsLoading(true)
    removeRecord(event.id)
  }, [closeModal, event, removeRecord])

  return (
    <article className="dash-slab">
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <h1>Remove: {get(event, 'title')}</h1>
        <hr style={{ margin: '1em 0' }} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>Are you sure you want to remove this event?</div>
          <div style={{ paddingTop: '20px' }}>
            <button type="button" className="btn btn-grey" onClick={closeModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-red" onClick={removeEvent}>
              Remove
            </button>
          </div>
        </div>
      </Modal>

      <div className="dash-slab-photo">
        <Link to={Routes.event(event)}>
          <ResponsiveImage publicId={get(event, 'image.publicId')} width="140" height="90" />
        </Link>
      </div>

      <div className="dash-slab-info">
        <h2 className="dash-slab-heading">
          <Link className="dash-slab-heading-event" to={Routes.event(event)}>
            {event.title}
          </Link>
        </h2>
        <div className="dash-slab-detail">
          {formatDateAndTime(event.startAt)}
          {event.endAt && ' - ' + formatTime(event.endAt)}
          <br />
          {getTimeFromNow(event.startAt)}
        </div>
        <div className="dash-slab-detail dash-slab-address">
          <Address event={event} />
        </div>
      </div>

      {event.analytics && (
        <LineChart
          legend={false}
          library={{ scales: { x: { display: false }, y: { display: false } }, elements: { point: { pointStyle: false } } }}
          data={event.analytics}
          width={200}
          height={90}
        />
      )}

      {event.numberAnalytics && <Analytics {...event.numberAnalytics} />}

      <div className="dash-slab-actions">
        <Link to={Routes.editEvent(event)} className="dash-slab-action">
          <PublishStateAction publishState={event.publishState} />
        </Link>
        {isLoading ? (
          <div className="dash-slab-action">
            <Loading className="isCentered isCollapsed isDark" />
          </div>
        ) : (
          <YourRecordInlineDelete onClick={openConfirmModal} />
        )}
      </div>
    </article>
  )
}
export default YourEventsItem
