import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import ReactDOM from 'react-dom'
import Rollbar from 'rollbar'
import 'stop-runaway-react-effects/hijack'
import '../schemas/schema'
import App from '../startup/App'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    Rollbar: Rollbar
  }
}

ReactDOM.render(<App />, document.getElementById('react-root'))
