import dayjs from 'dayjs'
import gql from 'graphql-tag'
import sortBy from 'lodash/sortBy'
import queryString from 'query-string'
import React from 'react'
import { useLocation, useParams } from 'react-router'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import { Event } from '../../models'
import ContentFeedEventsList from '../components/events/ContentFeedEventsList'

const EventsListQuery = gql`
  fragment eventsListEventFragment on Event {
    id
    title
    slug
    startAt
    endAt
    eventType
    excerpt
    tempPlace_name: tempPlaceName
    image_publicId: imagePublicId
    costMin
    costMax
    repeats
    repeatingEventId
    day

    # TODO: many-to-many
    communities {
      id
      slug
      displayName
    }
    place {
      id
      name
    }
    isVirtual
  }
  query EventsList($id: String!, $fromDate: ISO8601DateTime!, $cursor: String) {
    feed(id: $id) {
      id
      events(first: 30, fromDate: $fromDate, after: $cursor) {
        nodes {
          ...eventsListEventFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const EventsListContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { search } = useLocation()
  const { from: fromDate } = queryString.parse(search)
  const today = dayjs().startOf('day').toDate()
  const selectedDay = fromDate ? dayjs(fromDate as string).toDate() : today

  const variables = { id, fromDate: selectedDay }
  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(EventsListQuery, 'feed.events', { variables })

  const events: Event[] = sortBy(data?.feed?.events?.nodes || [], 'startAt')

  return (
    <ContentFeedEventsList
      loading={loading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      events={events}
      selectedDay={selectedDay}
    />
  )
}

export default EventsListContainer
