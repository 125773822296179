import gql from 'graphql-tag'
import { useContext } from 'react'
import { useQuery } from '../api/graphql'
import { CurrentUserContext } from '../providers/CurrentUserContext'

export const EventFormQuery = gql`
  query EventFormQuery {
    currentUser {
      id
    }
    communities {
      id
      displayName
    }
  }
`

type Data = {
  currentUser: { id: string }
  communities: { id: string; displayName: string }[]
}
const useEventFormData = (): { data: Data; loading: boolean } => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)

  const { data, loading } = useQuery(EventFormQuery, { skip: loadingCurrentUser })
  return { data, loading: loading || loadingCurrentUser }
}

export default useEventFormData
