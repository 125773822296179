import gql from 'graphql-tag'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import toastr from 'toastr'
import { AutoForm } from 'uniforms-unstyled'
import { prepareToSubmit } from '../../../api/stories/helpers'
import { storyFragment } from '../../../graphql/fragments'
import { CommunitiesContext } from '../../../hooks/CommunitiesContextProvider'
import useOnImageUploadStateChange from '../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../hooks/useRecordSubmissionMutation'
import { Story } from '../../../models'
import StorySchema from '../../../schemas/stories/storySchema'
import Routes from '../../../startup/routes'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import Loading from '../misc/Loading'
import StoryFields from './StoryFields'

const STORY_NEW = gql`
  mutation storyCreate($input: StoryCreateInput!) {
    storyCreate(input: $input) {
      story {
        ...storyFragment
        content
      }
      errors {
        attribute
        message
      }
    }
  }
  ${storyFragment}
`

type Props = { loading: boolean; story: Story }
const StoryNew: React.FC<Props> = ({ loading, story }) => {
  const history = useHistory()
  const [action] = useState('insert')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const formRef = useRef(null)
  const communities = useContext(CommunitiesContext)

  const beforeSubmit = useCallback(doc => prepareToSubmit(doc), [])

  const onSuccess = useCallback(() => {
    history.push(Routes.yourStories())
    toastr.success(`
        Thank you!<br/><br/>
        Your story has been submitted.<br/>
      `)
  }, [history])

  const { handleSubmit, isLoading, onValidate } = useRecordSubmissionMutation({
    action,
    query: STORY_NEW,
    queryName: 'storyCreate',
    recordName: 'story',
    beforeSubmit,
    onSuccess,
  })

  const onImageUploadStateChange = useOnImageUploadStateChange(setIsSubmitDisabled)

  if (loading) return <Loading className="isDark" />

  return (
    <div>
      <h1 className="content-form-heading">Add Story</h1>

      <AutoForm
        ref={formRef}
        schema={StorySchema}
        onSubmit={handleSubmit}
        model={story}
        className="form content-entry-form story-form"
        onValidate={onValidate}
      >
        <StoryFields communities={communities} onImageUploadStateChange={onImageUploadStateChange} />

        <fieldset className="submit-row">
          <UniformsButton disabled={isSubmitDisabled} shouldShowLoadingSpinner={isLoading && action === 'insert'} text="Submit Story" />
        </fieldset>
      </AutoForm>
    </div>
  )
}

export default StoryNew
