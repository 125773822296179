import * as React from 'react'
import BodyClassName from 'react-body-classname'
import Routes from '../../../startup/routes'
import EmbeddedModalLinkBlocker from '../misc/EmbeddedModalLinkBlocker'
import EventSignUpUpsell from './EventSignUpUpsell'
import EventsListEvents from './EventsListEvents'
import EventsListMetadata from './EventsListMetadata'
import EventsListPlaceholder from './EventsListPlaceholder'
import EventsListSidebar from './EventsListSidebar'

type Props = {
  loading
  communities
  communityIdParam
  fetchNextPage
  hasNextPage
  blockedEventCommunities
  toggleEventCommunity
  events
  selectedDay
}
const EventsList: React.FC<Props> = ({
  loading,
  communities,
  communityIdParam,
  fetchNextPage,
  hasNextPage,
  blockedEventCommunities,
  toggleEventCommunity,
  events,
  selectedDay,
}) => {
  return (
    <BodyClassName className="path-events">
      <section className="events-overview">
        <EventsListMetadata communityIdParam={communityIdParam} communities={communities} />
        <div className="mobile-events-nav">
          {/*
            <Link to="#" className="btn-red date-jump">
              <i className="icon-calendar" />
              <span className="button-text">
                Jump to<br /> a Date
              </span>
            </Link>
            */}

          <EmbeddedModalLinkBlocker
            to={Routes.newEvent()}
            className="btn btn-red submit-event"
            modalComponent={EventSignUpUpsell}
            modalCTALink={Routes.signUp(communities[0])}
          >
            Submit
            <br /> an Event
          </EmbeddedModalLinkBlocker>
        </div>

        <div className="two-col">
          <section className="events-main">
            {loading ? (
              <EventsListPlaceholder />
            ) : events.length > 0 ? (
              <EventsListEvents communities={communities} events={events} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
            ) : (
              'No upcoming events'
            )}
          </section>

          <div className="events-sidebar">
            <EventsListSidebar
              communities={communities}
              blockedEventCommunities={blockedEventCommunities}
              selectedDay={selectedDay}
              toggleEventCommunity={toggleEventCommunity}
            />
          </div>
        </div>
      </section>
    </BodyClassName>
  )
}

export default EventsList
