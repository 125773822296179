import { css } from '@emotion/react'
import React from 'react'
import { ErrorField, NestField, TextField } from 'uniforms-unstyled'
import { inputFieldHeight, marginBottom } from '../../../styles/vars'

interface AddressFieldsProps {
  name: string
}

const AddressFields: React.FC<{ name: string }> = ({ name }: AddressFieldsProps) => (
  <NestField name={name} className="address addressFields-container" label={null}>
    <hr />
    <div className="addr-fields">
      <div
        css={css`
          ${marginBottom};
          min-height: ${inputFieldHeight};
        `}
      >
        <TextField name="streetAddress" placeholder="Street" className="staddr" />
        <TextField name="streetAddress2" placeholder="#" className="staddr2" />
      </div>

      <div className="form-error">
        <ErrorField name="streetAddress" />
      </div>

      <div
        css={css`
          ${marginBottom};
          min-height: ${inputFieldHeight};
        `}
      >
        <TextField name="city" placeholder className="city" />
        <TextField name="state" placeholder="State" className="state" />
        <TextField name="zipCode" placeholder="Zip" className="zip" />
      </div>

      <div className="form-error">
        <ErrorField name="city" />
      </div>

      <div className="form-error">
        <ErrorField name="state" />
      </div>

      <div className="form-error">
        <ErrorField name="zipCode" />
      </div>
    </div>
  </NestField>
)

export default AddressFields
