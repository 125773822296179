import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import get from 'lodash/get'
import { LngLat, Marker } from 'mapbox-gl'
import * as React from 'react'
import { useContext, useEffect, useRef } from 'react'
import { MapContext, ZoomControl } from 'react-mapbox-gl'
import { connectField } from 'uniforms'
import { HiddenField } from 'uniforms-unstyled'
import { DEFAULT_MAP_CENTER_COORDINATES, MAPBOX_ACCESS_TOKEN } from '../../../util/constants'
import Mapbox from '../mapbox/Mapbox'

const geocoding = mapboxGeocoding({ accessToken: MAPBOX_ACCESS_TOKEN })

const DraggableMarker = ({ setCenter, center }) => {
  const map = useContext(MapContext)
  const marker = useRef()
  if (!marker.current) {
    marker.current = new Marker({ draggable: true })
  }

  // update the marker position
  useEffect(() => {
    marker.current.setLngLat(center)
  }, [center])

  // update the map center whenever the marker is moved
  useEffect(() => {
    const callback = () => setCenter(marker.current.getLngLat())
    marker.current.on('dragend', callback)

    return () => marker.current.off('dragend', callback)
  }, [setCenter])

  // add the marker to the map
  useEffect(() => {
    marker.current.addTo(map)

    return () => marker.current.remove()
  }, [map])

  return null
}

const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true'
const zoomLevel = 16
const factoryParameters = { scrollZoom: false }
const flyToOptions = { duration: 800 }

interface MapPickerProps {
  name?: string
  onChange?(...args: unknown[]): unknown
  value?: Record<string, unknown>
}

const MapPicker = ({ value, coordinatesQuery }: MapPickerProps) => {
  const [{ zoom, center }, setState] = React.useReducer((prev, next) => ({ ...prev, ...next }), {
    zoom: [value.coordinates ? 20 : zoomLevel],
    center: value.coordinates || DEFAULT_MAP_CENTER_COORDINATES,
  })

  const setCenter = React.useCallback(newCenter => setState({ center: newCenter }), [])

  const geocodingQuery = coordinatesQuery && Object.values(coordinatesQuery).join(',')
  const { lng: initialLng, lat: initialLat } = LngLat.convert(DEFAULT_MAP_CENTER_COORDINATES)

  React.useEffect(() => {
    if (geocodingQuery) {
      const request = geocoding.forwardGeocode({
        query: geocodingQuery,
        proximity: [initialLng, initialLat],
      })

      request.send().then(response => {
        const bestMatch = get(response, 'body.features[0]')
        if (bestMatch) {
          setState({ zoom: [18], center: bestMatch.center })
        }
      })

      return () => request.abort()
    }
  }, [geocodingQuery, initialLng, initialLat])

  const { lng, lat } = LngLat.convert(center)

  return (
    <section>
      <div id="map-picker">
        <Mapbox
          zoom={zoom}
          center={center}
          onClick={(map, event) => {
            setCenter(event.lngLat)
          }}
          style={mapStyle}
          flyToOptions={flyToOptions}
          factoryParameters={factoryParameters}
        >
          <ZoomControl position="top-left" />
          <DraggableMarker setCenter={setCenter} center={center} />
        </Mapbox>
      </div>
      <HiddenField name="type" value="Point" />
      <HiddenField name="coordinates.0" value={lng} />
      <HiddenField name="coordinates.1" value={lat} />
    </section>
  )
}

export default connectField(MapPicker)
