import React from 'react'
import { PublishState, PublishStateType } from '../../../../util/constants'

type Props = { publishState?: PublishStateType }
const PublishStateAction: React.FC<Props> = ({ publishState }) => {
  let actionText = ''
  switch (publishState) {
    case PublishState.DRAFT:
      actionText = 'Complete & Submit'
      break
    case PublishState.APPROVED:
    case PublishState.DENIED:
      actionText = 'Edit'
      break
    default:
      actionText = 'Edit'
  }
  return (
    <>
      <i className="icon-pencil-blue" />
      <span className="dash-slab-action-text">{actionText}</span>
    </>
  )
}
export default PublishStateAction
