import SimpleSchema from './schema'

const MediaSchema = new SimpleSchema({
  publicId: { type: String, label: 'Image or Video', optional: true },
  type: {
    type: String,
    label: 'File type',
    optional: true,
    autoValue() {
      if (!this.value && this.siblingField('publicId').value) return 'image'
      return undefined
    },
    allowedValues: ['audio', 'image', 'video'],
  },
  fileSize: { type: Number, optional: true },
  width: { type: Number, optional: true },
  height: { type: Number, optional: true },
})

export default MediaSchema
