import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'
import { useQuery } from '../../../../api/graphql'
import useOnImageUploadStateChange from '../../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../../hooks/useRecordSubmissionMutation'
import { CurrentUserQuery } from '../../../../providers/CurrentUserContext'
import Routes from '../../../../startup/routes'
import Grid, { GridItem, LARGE, breakpoint } from '../../../../styles/Grid'
import { inputFieldHeight } from '../../../../styles/vars'
import Button from '../../form-fields/Button'
import MediaUploadField from '../../form-fields/MediaUploadField'
import RichTextEditor from '../../form-fields/RichTextEditor'
import StringInput from '../../form-fields/StringInput'

export const ProfileFormQuery = gql`
  query ProfileFormQuery {
    currentUser {
      id
      firstName
      lastName
      email
      signature
      profilePic_publicId: profilePicPublicId
      isCurator
      isAuthor
    }
  }
`

const ProfileEdit: React.FC = () => {
  const { data, loading } = useQuery(ProfileFormQuery)

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false)
  const onUploadingStateChange = useOnImageUploadStateChange(setIsUploadingAvatar)
  const { replace: redirectTo } = useHistory()

  const successNotification = () => toastr.success('Your profile is updated!')

  const handleSuccessfulUpdate = () => {
    successNotification()
    redirectTo(Routes.dashWelcome())
  }

  const { handleSubmit: sendProfileData, mutationLoading: recordUpdateMutationLoading } = useRecordSubmissionMutation({
    query: PROFILE_EDIT,
    queryName: 'profileUpdate',
    recordName: 'profile',
    onSuccess: handleSuccessfulUpdate,
    submitOptions: { refetchQueries: () => [{ query: CurrentUserQuery }] },
  })

  const updateProfile = formData => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { profilePic = '', ...profileData } = { ...formData, profilePicPublicId: formData.profilePic?.publicId }
    sendProfileData(profileData)
  }

  if (loading) return null

  const { currentUser } = data
  const canSubmitStories = currentUser?.isAuthor

  const { firstName, lastName, signature, profilePic } = currentUser

  return (
    <StyledFormBox>
      <StyledFormHeader>
        <h1>Account details</h1>
      </StyledFormHeader>

      <Formik
        onSubmit={updateProfile}
        initialValues={{ firstName, lastName: lastName || '', signature, profilePic }}
        validate={values => {
          const errors: { firstName?: string } = {}

          if (!values.firstName) errors.firstName = 'First name is required'

          return errors
        }}
      >
        <Form>
          <Grid>
            <GridItem columns={12} medium={6}>
              <StringInput name="firstName" placeholder="Your first name" />
            </GridItem>

            <GridItem columns={12} medium={6}>
              <StringInput name="lastName" placeholder="Your last name" />
            </GridItem>
          </Grid>

          {canSubmitStories && <RichTextEditor name="signature" />}

          <StyledAvatarUpload>
            <MediaUploadField name="profilePic" allowVideo={false} folder="users" onUploadingStateChange={onUploadingStateChange} />
          </StyledAvatarUpload>

          <StyledProfileSubmitButton
            disabled={recordUpdateMutationLoading || isUploadingAvatar}
            shouldShowLoadingSpinner={recordUpdateMutationLoading}
            text="Save"
          />
        </Form>
      </Formik>
    </StyledFormBox>
  )
}

export default ProfileEdit

const PROFILE_EDIT = gql`
  mutation ProfileUpdate($input: ProfileUpdateInput!) {
    profileUpdate(input: $input) {
      profile {
        id
        firstName
        lastName
        email
        signature
      }
      errors {
        attribute
        message
      }
    }
  }
`

const FORM_MAX_WIDTH = 552
const FORM_EDGE_DISTANCE_MOBILE = 20
const FORM_EDGE_DISTANCE_LARGE = 60

const StyledFormBox = styled.div`
  width: 100%;
  max-width: ${FORM_MAX_WIDTH + FORM_EDGE_DISTANCE_MOBILE * 2}px;
  padding: ${FORM_EDGE_DISTANCE_MOBILE}px;
  margin: 0 auto;

  ${breakpoint(LARGE)} {
    max-width: ${FORM_MAX_WIDTH + FORM_EDGE_DISTANCE_LARGE * 2}px;
    padding: ${FORM_EDGE_DISTANCE_LARGE}px;
    margin: 0;
  }
`

const StyledFormHeader = styled.div`
  margin-bottom: 40px;
  color: #102638;
`

const StyledAvatarUpload = styled.div`
  margin-top: 40px;
  max-width: 267px;
  height: 165px;
`

const StyledProfileSubmitButton = styled(Button)`
  display: inline-block;
  background-color: #6fbf53;
  color: white;
  width: 164px;
  height: ${inputFieldHeight};
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: none;
  border-radius: 4px;
  padding: 0.625em 1.75em;
  text-align: center;
  opacity: 1;
  transition: all 0.15s ease-out;
  cursor: pointer;
`
