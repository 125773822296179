export default {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'RecordsUnion',
        possibleTypes: [{ name: 'Event' }, { name: 'JobListing' }, { name: 'Place' }, { name: 'Story' }],
      },
    ],
  },
}
