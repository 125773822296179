import gql from 'graphql-tag'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import toastr from 'toastr'
import { AutoForm } from 'uniforms-unstyled'
import { prepareToSubmit } from '../../../api/stories/helpers'
import { storyFragment } from '../../../graphql/fragments'
import { CommunitiesContext } from '../../../hooks/CommunitiesContextProvider'
import useOnImageUploadStateChange from '../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../hooks/useRecordSubmissionMutation'
import { Story } from '../../../models'
import StorySchema from '../../../schemas/stories/storySchema'
import Routes from '../../../startup/routes'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import Loading from '../misc/Loading'
import StoryFields from './StoryFields'

const STORY_EDIT = gql`
  mutation storyUpdate($input: StoryUpdateInput!) {
    storyUpdate(input: $input) {
      story {
        ...storyFragment
        content
      }
      errors {
        attribute
        message
      }
    }
  }
  ${storyFragment}
`

type Props = { loading: boolean; story?: Story }
const StoryEdit: React.FC<Props> = ({ loading, story: originalStory }) => {
  const history = useHistory()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const formRef = useRef(null)
  const communities = useContext(CommunitiesContext)

  const navigate = useCallback(() => history.push(Routes.yourStories()), [history])
  const beforeSubmit = useCallback(doc => prepareToSubmit(doc), [])

  const onSuccess = useCallback(() => {
    toastr.success('Story updated!')
    navigate()
  }, [navigate])

  const {
    handleSubmit,
    mutationLoading: recordUpdateMutationLoading,
    onValidate,
  } = useRecordSubmissionMutation({ query: STORY_EDIT, queryName: 'storyUpdate', recordName: 'story', beforeSubmit, onSuccess })

  const onImageUploadStateChange = useOnImageUploadStateChange(setIsSubmitDisabled)

  if (loading) return <Loading className="isDark" />

  return (
    <div>
      <h1 className="content-form-heading">Edit Story</h1>

      <AutoForm
        className="form content-entry-form story-form"
        model={originalStory}
        onSubmit={handleSubmit}
        onValidate={onValidate}
        ref={formRef}
        schema={StorySchema}
      >
        <StoryFields communities={communities} onImageUploadStateChange={onImageUploadStateChange} />

        <fieldset className="submit-row">
          <UniformsButton
            disabled={recordUpdateMutationLoading || isSubmitDisabled}
            shouldShowLoadingSpinner={recordUpdateMutationLoading}
            text="Submit Changes"
          />
        </fieldset>
      </AutoForm>
    </div>
  )
}

export default StoryEdit
