import get from 'lodash/get'
import React from 'react'
import { Link } from 'react-router-dom'
import { Story } from '../../../../models'
import Routes from '../../../../startup/routes'
import { formatShortDate } from '../../../../util/dateTime'
import Loading from '../../misc/Loading'
import ResponsiveImage from '../../misc/MediaPresets'
import Modal from '../../misc/Modal'
import PublishStateAction from '../publishState/PublishStateAction'
import YourRecordInlineDelete from '../your/YourRecordInlineDelete'

interface YourStoriesItemProps {
  record: Story
}

export default class YourStoriesItem extends React.Component<YourStoriesItemProps> {
  state = {
    isLoading: false,
    isModalOpen: false,
  }

  openConfirmModal = (): void => this.setState({ isModalOpen: true })

  removeStory = (): void => {
    const { record: story } = this.props
    this.closeModal()

    this.setState({ isLoading: true })
    this.props.removeRecord(story.id)
  }

  closeModal = (): void => this.setState({ isModalOpen: false })

  renderModal(): JSX.Element {
    const { record: story } = this.props

    return (
      <Modal isOpen={this.state.isModalOpen} onRequestClose={this.closeModal}>
        <h1>Remove: {get(story, 'title')}</h1>
        <hr style={{ margin: '1em 0' }} />
        <div style={{ textAlign: 'right' }}>
          <div>Are you sure you want to remove this story?</div>
          <div style={{ paddingTop: '20px' }}>
            <button type="button" className="btn btn-grey" onClick={this.closeModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-red" onClick={this.removeStory}>
              Remove
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  render(): JSX.Element {
    const { record: story } = this.props
    const { isLoading } = this.state
    return (
      <article className="dash-slab">
        {this.renderModal()}
        <div className="dash-slab-photo">
          <Link to={Routes.story(story)}>
            <ResponsiveImage publicId={get(story, 'image.publicId')} width="140" height="90" />
          </Link>
        </div>
        <div className="dash-slab-info">
          <h2 className="dash-slab-heading">
            <Link className="dash-slab-heading-story" to={Routes.story(story)}>
              {story.title}
            </Link>
          </h2>
          <div className="dash-slab-detail">{formatShortDate(story.publishedAt)}</div>
          <div className="dash-slab-detail">{story.excerpt}</div>
        </div>

        <div className="dash-slab-actions">
          <Link to={Routes.editStory(story)} className="dash-slab-action">
            <PublishStateAction publishState={story.publishState} />
          </Link>
          {isLoading ? (
            <div className="dash-slab-action">
              <Loading className="isCentered isCollapsed isDark" />
            </div>
          ) : (
            <YourRecordInlineDelete onClick={this.openConfirmModal} />
          )}
        </div>
      </article>
    )
  }
}
