import gql from 'graphql-tag'
import React, { useContext } from 'react'
import Place from '../../api/places/model'
import { placeFragment } from '../../graphql/fragments'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import usePlaceFormData from '../../hooks/usePlaceFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import PlaceFormComponent from '../components/places/PlaceFormComponent'

const PLACE_NEW = gql`
  mutation PlaceCreate($input: PlaceCreateInput!) {
    placeCreate(input: $input) {
      place {
        ...placeFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${placeFragment}
`

const PlaceNewContainer: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { data, loading } = usePlaceFormData()

  const place = new Place({
    contactName: currentUser?.fullName,
    contactEmail: currentUser?.email,
    contact: {
      name: currentUser?.fullName,
      email: currentUser?.email,
    },
    submitterOwnsPlace: true,
    primaryCommunityId: currentUser?.primaryCommunity?.id,
  })

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <PlaceFormComponent action="insert" loading={loading} mutationQuery={PLACE_NEW} place={place} />
    </CommunitiesContextProvider>
  )
}

export default PlaceNewContainer
