import get from 'lodash/get'
import React from 'react'
import { Link } from 'react-router-dom'

import { Story } from '../../../models'
import Routes from '../../../startup/routes'
import { getTimeFromNow } from '../../../util/dateTime'
import ResponsiveImage from '../misc/MediaPresets'
import UserBadge from '../users/UserBadge'

const getClass = ({ communityIds, story }: Props): string => {
  const storyCommunityId = get(story, 'community.id')
  const classNumber = communityIds.indexOf(storyCommunityId) + 1
  return `entry-item place${classNumber}`
}

interface Props {
  communityIds: string[]
  story: Story
}

const StoryListItem: React.FC<Props> = ({ communityIds, story }) => {
  const author = story.submitter || {
    firstName: 'Unknown',
    lastName: 'author',
  }

  return (
    <li key={story.id} className={getClass({ communityIds, story })}>
      <div className="entry-image">
        <div className="entry-image-content">
          <ResponsiveImage publicId={get(story, 'image.publicId')} width="300" height="180" />
        </div>
      </div>
      <article className="entry-content">
        {/* entry story */}
        <h2 className="entry-heading">
          <Link to={Routes.story(story, story.community)}>{story.title}</Link>
        </h2>
        <div className="entry-meta">
          <UserBadge user={author} />
          <span className="meta-author">
            by {author.firstName} {author.lastName}
          </span>
          <span className="meta-category">in {story.category}</span>
          <span>
            &middot; {getTimeFromNow(story.publishedAt)}
            &nbsp; &middot; {story.readingTime}
          </span>
        </div>
        <div className="entry-excerpt">
          <p>
            {story.excerpt}
            <Link to={Routes.story(story, story.community)} className="more">
              <i className="icon-arrow-right-blue" />
            </Link>
          </p>
        </div>
      </article>
    </li>
  )
}

export default StoryListItem
