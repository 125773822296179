import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../../../styles/variables'
import { IconNameType } from './IconName'

export const IconColor = {
  white: colors.white,
  offwhite: colors.offwhite,
  silver: colors.silver,
  grey: colors.grey,
  darkgrey: colors.darkgrey,
  red: colors.clRed,
  green: colors.clGreen,
  blue: colors.clBlue,
  yellow: colors.clYellow,
  lightpurple: colors.clLightpurple,
  purple: colors.clPurple,
  darkblue: colors.clDarkblue,
} as const

const cachebust = 'ermf21'

export type IconColorType = (typeof IconColor)[keyof typeof IconColor]

export const Icon = styled(props => <i {...props} />, { shouldForwardProp: isPropValid })<{
  name: IconNameType
  color?: IconColorType
}>`
  @font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.woff2?${cachebust}') format('woff2'), url('/fonts/icomoon.ttf?${cachebust}') format('truetype'),
      url('/fonts/icomoon.woff?${cachebust}') format('woff'), url('/fonts/icomoon.svg?${cachebust}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  font-family: 'icomoon';
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  // setting width so overflow icons don't break alignment
  width: 1em;
  overflow: visible;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${props => `
    &:before {
      content: "\\${props.name}"
    }
  `};
  ${props => props.color && `color: ${props.color}`};
`
