import SimpleSchema from '../schema'

const OperatingHoursSchema = new SimpleSchema({
  monday: {
    type: Object,
    label: 'Monday',
    optional: true,
  },
  'monday.openTime': {
    type: Number,
    label: 'Monday Open Time',
    optional: true,
  },
  'monday.closeTime': {
    type: Number,
    label: 'Monday Close Time',
    optional: true,
  },
  tuesday: {
    type: Object,
    label: 'Tuesday',
    optional: true,
  },
  'tuesday.openTime': {
    type: Number,
    label: 'Tuesday Open Time',
    optional: true,
  },
  'tuesday.closeTime': {
    type: Number,
    label: 'Tuesday Close Time',
    optional: true,
  },
  wednesday: {
    type: Object,
    label: 'Wednesday',
    optional: true,
  },
  'wednesday.openTime': {
    type: Number,
    label: 'Wednesday Open Time',
    optional: true,
  },
  'wednesday.closeTime': {
    type: Number,
    label: 'Wednesday Close Time',
    optional: true,
  },
  thursday: {
    type: Object,
    label: 'Thursday',
    optional: true,
  },
  'thursday.openTime': {
    type: Number,
    label: 'Thursday Open Time',
    optional: true,
  },
  'thursday.closeTime': {
    type: Number,
    label: 'Thursday Close Time',
    optional: true,
  },
  friday: {
    type: Object,
    label: 'Friday',
    optional: true,
  },
  'friday.openTime': {
    type: Number,
    label: 'Friday Open Time',
    optional: true,
  },
  'friday.closeTime': {
    type: Number,
    label: 'Friday Close Time',
    optional: true,
  },
  saturday: {
    type: Object,
    label: 'Saturday',
    optional: true,
  },
  'saturday.openTime': {
    type: Number,
    label: 'Saturday Open Time',
    optional: true,
  },
  'saturday.closeTime': {
    type: Number,
    label: 'Saturday Close Time',
    optional: true,
  },
  sunday: {
    type: Object,
    label: 'Sunday',
    optional: true,
  },
  'sunday.openTime': {
    type: Number,
    label: 'Sunday Open Time',
    optional: true,
  },
  'sunday.closeTime': {
    type: Number,
    label: 'Sunday Close Time',
    optional: true,
  },
})

export default OperatingHoursSchema
