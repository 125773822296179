import dayjs from 'dayjs'
import gql from 'graphql-tag'
import get from 'lodash/get'
import set from 'lodash/set'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import toastr from 'toastr'
import { AutoForm } from 'uniforms-unstyled'
import EventSeries from '../../../api/events/EventSeries'
import EventStartEndDateTime from '../../../api/events/EventStartEndDateTime'
import { eventDates, initRepeatDays, prepareToSubmit } from '../../../api/events/helpers'
import { eventFragment } from '../../../graphql/fragments'
import useOnImageUploadStateChange from '../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../hooks/useRecordSubmissionMutation'
import { Event } from '../../../models'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import { EventFormSchema } from '../../../schemas/events/eventSchema'
import Routes from '../../../startup/routes'
import EventTypes, { RepeatingPatterns } from '../../../util/constants'
import { dayOfTheWeek } from '../../../util/dateTime'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import Loading from '../misc/Loading'
import EventFields from './EventFields'

const EVENT_NEW = gql`
  mutation EventCreate($input: EventCreateInput!) {
    eventCreate(input: $input) {
      event {
        ...eventFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${eventFragment}
`

type Props = { event: Event; loading: boolean }
const EventNew: React.FC<Props> = ({ event: originalEvent, loading }) => {
  const history = useHistory()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const formRef = useRef(null)

  const { currentUser } = useContext(CurrentUserContext)
  const [organizerName, setOrganizerName] = useState()
  const [organizerEmail, setOrganizerEmail] = useState()

  const onSuccess = useCallback(() => {
    history.push(Routes.yourEvents())
    toastr.success(`
        Thank you!<br/><br/>
        Your event has been submitted.<br/>
      `)
  }, [history])

  const { handleSubmit, mutationLoading, onValidate } = useRecordSubmissionMutation({
    query: EVENT_NEW,
    queryName: 'eventCreate',
    recordName: 'event',
    beforeSubmit: prepareToSubmit,
    onSuccess,
  })

  const onImageUploadStateChange = useOnImageUploadStateChange(setIsSubmitDisabled)

  const updateSubmitterOwnsEvent = useCallback(
    (key, value) => {
      if (key !== 'submitterOwnsEvent') {
        return
      }
      const formReference = formRef.current
      if (!formReference) {
        return
      }

      if (value) {
        const model = formReference.getModel()
        setOrganizerName(get(model, 'organizer.name'))
        setOrganizerEmail(get(model, 'organizer.email'))
        formReference.change('organizer.name', get(currentUser, 'fullName'))
        formReference.change('organizer.email', get(currentUser, 'email'))
      } else {
        formReference.change('organizer.name', organizerName)
        formReference.change('organizer.email', organizerEmail)
      }
    },
    [currentUser, organizerEmail, organizerName, formRef, setOrganizerName, setOrganizerEmail],
  )

  const setRepeatStopsOnBasedOnRepeatingPattern = useCallback((formReference, key: string, value): boolean | undefined => {
    if (key !== 'repeats') {
      return
    }

    const model = formReference.getModel() as Event
    const repeatDays = initRepeatDays(value, model.startAt)
    if (repeatDays) {
      formReference.change('repeatDays', repeatDays)
    }

    let repeatStopsOn
    switch (value) {
      case RepeatingPatterns.DAILY:
      case RepeatingPatterns.REQUEST_SCHEDULE:
        repeatStopsOn = dayjs(model.startAt).add(5, 'day').toDate()
        formReference.change('repeatStopsOn', repeatStopsOn)
        return true
      case RepeatingPatterns.WEEKLY:
        repeatStopsOn = dayjs(model.startAt).add(4, 'week').toDate()
        formReference.change('repeatStopsOn', repeatStopsOn)
        return true
      case RepeatingPatterns.MONTHLY_NTH_DOW:
        const eventSeries = new EventSeries({ startAt: model.startAt, repeats: value })
        let nextOccurrence = eventSeries.next(model.startAt)
        nextOccurrence = eventSeries.next(nextOccurrence)
        repeatStopsOn = nextOccurrence
        formReference.change('repeatStopsOn', repeatStopsOn)
        return true
      case RepeatingPatterns.MONTHLY_SAME_DATE:
        repeatStopsOn = dayjs(model.startAt).add(2, 'month').toDate()
        formReference.change('repeatStopsOn', repeatStopsOn)
        return true
      default:
        break
    }
  }, [])

  const createEventSeries = useCallback(
    (key, value) => {
      const formReference = formRef.current
      if (!formReference) {
        return
      }
      if (setRepeatStopsOnBasedOnRepeatingPattern(formReference, key, value)) {
        return
      }
      const keyStart = key.split('.')[0]
      const scheduleFields = ['eventType', 'repeatDays', 'repeatStopsOn', 'startAtDate', 'startAtTime', 'endAtTime']
      if (!scheduleFields.includes(keyStart)) {
        return
      }

      const model = formReference.getModel() as Event
      const event = new Event(model)
      // this field is set here only to be used in the code below
      // to set or change these values in the form model, use `formReference.change` instead
      set(event, key, value)

      const oldEndAt = event.endAt
      event.endAtDate = event.startAtDate
      if (dayjs(event.endAt).isBefore(dayjs(event.startAt))) {
        event.endAt = dayjs(event.endAt).add(1, 'day').toDate()
      }
      if (oldEndAt?.getTime() !== event.endAt?.getTime()) {
        formReference.change('endAt', event.endAt)
        return
      }

      let occurrences: EventStartEndDateTime[] = []
      if (event.eventType === EventTypes.SINGLE_EVENT) {
        formReference.change('occurrences', occurrences)
        return
      }

      if (event.repeats !== RepeatingPatterns.WEEKLY) {
        occurrences = eventDates(event)
        formReference.change('occurrences', occurrences)
        return
      }

      // calculate weekly occurrences
      let currentDate_DayJs = dayjs(event.startAt)
      for (let i = 0; i < 7; i++) {
        if (currentDate_DayJs.isAfter(event.repeatStopsOn, 'day')) {
          break
        }
        const day = dayOfTheWeek(currentDate_DayJs)
        if (event.repeatDays && event.repeatDays[day]) {
          event.startAt = currentDate_DayJs.toDate()
          event.endAtDate = event.startAtDate
          if (dayjs(event.endAt).isBefore(dayjs(event.startAt))) {
            event.endAt = dayjs(event.endAt).add(1, 'day').toDate()
          }
          occurrences = [...occurrences, ...eventDates(event)]
        }
        currentDate_DayJs = currentDate_DayJs.add(1, 'day')
      }
      occurrences.sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
      formReference.change('occurrences', occurrences)
    },
    [setRepeatStopsOnBasedOnRepeatingPattern],
  )

  const onChange = useCallback(
    (key, value) => {
      updateSubmitterOwnsEvent(key, value)

      createEventSeries(key, value)
    },
    [createEventSeries, updateSubmitterOwnsEvent],
  )

  if (loading) return <Loading className="isDark" />

  return (
    <div>
      <h1 className="content-form-heading">Add Event</h1>

      <AutoForm
        disabled={loading}
        ref={formRef}
        schema={EventFormSchema}
        model={originalEvent}
        className="form content-entry-form event-form"
        onChange={onChange}
        onSubmit={handleSubmit}
        onValidate={onValidate}
      >
        <EventFields onImageUploadStateChange={onImageUploadStateChange} originalEvent={originalEvent} />

        <fieldset className="submit-row">
          <UniformsButton disabled={isSubmitDisabled} shouldShowLoadingSpinner={mutationLoading} text="Submit Event" />
        </fieldset>
      </AutoForm>
    </div>
  )
}

export default EventNew
