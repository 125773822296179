import React from 'react'
import { iconUrlForCategory } from '../../../util/placeCategoryIconMapping'

const ListItem: React.FC = ({ extras, item, onClick, onMouseEnter, onMouseLeave }) => (
  <div className="filter listItem" onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <div className="filter-text iconWithText" style={{ flexGrow: 1 }}>
      <img src={iconUrlForCategory(item.category)} alt={`${item.category || ''} icon`} />
      <strong>{item.title || item.name}</strong>
      {extras}
    </div>
  </div>
)

export default ListItem
