import dayjs from 'dayjs'
import React, { useCallback, useContext } from 'react'
import Event from '../../api/events/model'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import useEventFormData from '../../hooks/useEventFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import EventNew from '../components/events/EventNew'

const EventNewContainer: React.FC = () => {
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const { data, loading } = useEventFormData()

  const initEvent = useCallback(() => {
    const event = new Event({
      eventType: null,
      repeatStopsOn: dayjs().add(5, 'day').toDate(),
      organizerName: currentUser?.fullName,
      organizerEmail: currentUser?.email,
      organizer: {
        name: currentUser?.fullName,
        email: currentUser?.email,
      },
      submitterOwnsEvent: true,
      primaryCommunityId: currentUser?.primaryCommunity?.id,
    })
    // initial start date and time are rounded UP to the nearest hour,
    // 1 day from now
    const tomorrowNextHour = dayjs().startOf('hour').add(1, 'hour').add(1, 'day')
    event.startAt = tomorrowNextHour.toDate()
    event.endAt = tomorrowNextHour.add(3, 'hour').toDate()
    return event
  }, [currentUser])

  const event = initEvent()

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <EventNew loading={loading || loadingCurrentUser} event={event} />
    </CommunitiesContextProvider>
  )
}

export default EventNewContainer
