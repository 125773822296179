import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Event } from '../../../../models'
import Loading from '../../misc/Loading'
import YourEventsItem from './YourEventsItem'
import YourEventsTabContentEmpty from './YourEventsTabContentEmpty'

type Props = {
  events: Event[]
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
  removeRecord: (eventId?: string) => void
  isLoading: boolean
  tabScope: string
}

const YourEventsTabContent: React.FC<Props> = ({ events, fetchNextPage, hasNextPage, removeRecord, isLoading, tabScope }) => {
  if (isLoading) {
    return <Loading className="isCentered isDark" />
  }
  if (events.length === 0) {
    return <YourEventsTabContentEmpty tabScope={tabScope} />
  }
  return (
    <InfiniteScroll
      dataLength={events.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<Loading className="isCentered isDark" />}
      style={{ overflow: 'hidden' }}
    >
      {events.map(record => (
        <YourEventsItem removeRecord={removeRecord} key={record.id} record={record} />
      ))}
    </InfiniteScroll>
  )
}
export default YourEventsTabContent
