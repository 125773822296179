export type CloudinaryUploadFolders = 'logo' | 'users' | 'events' | 'uploads' | 'stories' | 'media'

const fetchUploadToken = async (timestamp: string, folder: string) => {
  const { data } = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!,
    },
    body: JSON.stringify({
      query: `
        query UploadTokenQuery($timestamp: BigInt!) {
          currentUser {
            id
            ${folder}UploadToken(timestamp: $timestamp)
          }
        }
      `,
      variables: { timestamp },
    }),
  }).then(res => res.json())

  return data.currentUser[`${folder}UploadToken`]
}

const cloudinaryTools = {
  async upload(file: File, options: Record<string, string>, cb: (error: string | null, response: unknown) => void): Promise<void> {
    const timestamp = new Date().getTime().toString()
    const token = await fetchUploadToken(timestamp, options.folder)

    const cloudName = process.env.CLOUDINARY_CLOUD_NAME
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
    const xhr = new XMLHttpRequest()
    const fd = new FormData()
    xhr.open('POST', url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.onreadystatechange = async () => {
      if (xhr.readyState !== 4) return

      const response = xhr.status === 200 && JSON.parse(xhr.responseText)
      const error = !response ? 'Error uploading file.' : null
      cb(error, response)
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    fd.append('api_key', process.env.CLOUDINARY_API_KEY!)
    fd.append('signature', token)
    fd.append('file', file)
    fd.append('timestamp', timestamp)
    Object.entries(options).forEach(([key, value]) => fd.append(key, value))
    xhr.send(fd)
  },
}

export default cloudinaryTools
