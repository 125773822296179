import get from 'lodash/get'
import React, { Suspense, useContext } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation, useParams } from 'react-router-dom'
import useIsLiveCommunity from '../hooks/useIsLiveCommunity'
import { Community } from '../models'
import { CurrentUserContext } from '../providers/CurrentUserContext'
import DashboardLayout from '../ui/components/dashboard/DashboardLayout'
import ProfileEdit from '../ui/components/dashboard/profile/ProfileEdit'
import DashWelcome from '../ui/components/dashboard/welcome/DashWelcome'
import Loading from '../ui/components/misc/Loading'
import ForgotPassword from '../ui/components/users/ForgotPassword'
import ResetPassword from '../ui/components/users/ResetPassword'
import SignIn from '../ui/components/users/SignIn'
import ContentFeedEventViewContainer from '../ui/containers/ContentFeedEventViewContainer'
import ContentFeedViewContainer from '../ui/containers/ContentFeedViewContainer'
import EventEditContainer from '../ui/containers/EventEditContainer'
import EventNewContainer from '../ui/containers/EventNewContainer'
import EventViewContainer from '../ui/containers/EventViewContainer'
import EventsListContainer from '../ui/containers/EventsListContainer'
import PlaceEditContainer from '../ui/containers/PlaceEditContainer'
import PlaceNewContainer from '../ui/containers/PlaceNewContainer'
import PlaceViewContainer from '../ui/containers/PlaceViewContainer'
import PlacesListContainer from '../ui/containers/PlacesListContainer'
import SignUpContainer from '../ui/containers/SignUpContainer'
import StoriesListContainer from '../ui/containers/StoriesListContainer'
import StoryEditContainer from '../ui/containers/StoryEditContainer'
import StoryNewContainer from '../ui/containers/StoryNewContainer'
import StoryViewContainer from '../ui/containers/StoryViewContainer'
import YourPastEventsContainer from '../ui/containers/YourPastEventsContainer'
import YourPlacesContainer from '../ui/containers/YourPlacesContainer'
import YourStoriesContainer from '../ui/containers/YourStoriesContainer'
import YourUpcomingEventsContainer from '../ui/containers/YourUpcomingEventsContainer'
import App from '../ui/layouts/App'
import PublicLayout from '../ui/layouts/PublicLayout'
import { RecordTypeInstance } from '../util/constants'
import { LocalStorage } from '../util/window'

export type CommunitySlugRouteParams = { idOrName?: string }

export const closestEventsPath = (path: string): string => path.slice(0, path.indexOf('/events')) + '/events'

const RequireLiveCommunity: React.FC = ({ children }) => {
  const location = useLocation()
  const { idOrName } = useParams<CommunitySlugRouteParams>()
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const queryParams = new URLSearchParams(location.search)

  let communitySlug: void | null | string = queryParams.get('origin')
  if (!communitySlug) communitySlug = location.state?.customDomainOrigin
  communitySlug = communitySlug || idOrName
  communitySlug = communitySlug || currentUser?.primaryCommunity?.slug || LocalStorage.getItem('customDomainOrigin')

  const { isLive, loading } = useIsLiveCommunity(communitySlug)

  const redirect = <Redirect to={Routes.dash()} />

  if (loading || loadingCurrentUser) return null
  if (!currentUser && !communitySlug) return <Redirect to={Routes.unauthenticated()} />
  if (currentUser && !communitySlug) return redirect
  return isLive ? <>{children}</> : redirect
}

const RequireSignedIn: React.FC = ({ children }) => {
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)

  if (loadingCurrentUser) return null
  return currentUser ? <>{children}</> : <Redirect to={Routes.unauthenticated()} />
}

// converts `/events?event=Event-Slug` => `/events/Event-Slug`
// SquareSpace doesn't allow dynamic URLs so we're using this hack to have all the single events
// load on `/events` path with `event` query param
const RedirectToSingleRecord: React.FC = ({ children }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const recordSlug = queryParams.get('event') || queryParams.get('story') || queryParams.get('headline') || queryParams.get('place')

  return recordSlug ? <Redirect to={`${location.pathname}/${recordSlug}`} /> : <>{children}</>
}

class Routes extends React.Component {
  public static idFromGlobalId = (globalId: string): string => {
    // global IDs look like this:
    // gid://civiclift/Event/31
    // backend knows how to search by slug or ID and
    // frontend redirects from ID to slug
    const paths = globalId.split('/')
    return paths.at(-1) || ''
  }
  // returns path with optional parameter if it's defined, otherwise returns an empty string
  public static optional = (parameter?: string): string => (parameter ? `/${parameter}` : '')
  // converts passed argument to url parameter
  public static parameterize = (recordOrId?: string | { slug?: string; id?: string }): string => {
    if (recordOrId == null) return ''
    if (typeof recordOrId === 'string') return Routes.idFromGlobalId(recordOrId)
    return recordOrId.slug || (recordOrId.id && Routes.idFromGlobalId(recordOrId.id)) || ''
  }
  public static customUrl = (community: Community | null, communityScopedPath: string): string => {
    const customUrl = get(community, 'customUrl') || 'https://www.civiclift.com'
    const path = community?.customUrl ? communityScopedPath.replace(`/${community.slug}`, '') : communityScopedPath
    return `${customUrl}${path}`
  }
  public static editPath = (recordName: string, recordOrId: RecordTypeInstance | string): string => {
    switch (recordName) {
      case 'story':
      case 'headline':
        return Routes.editStory(recordOrId)
      case 'event':
        return Routes.editEvent(recordOrId)
      case 'place':
        return Routes.editPlace(recordOrId)
      default:
        return Routes.root()
    }
  }
  public static root = (communityOrId?: Community | string): string => `/${Routes.parameterize(communityOrId)}`
  public static signIn = (): string => '/sign-in'
  public static signUp = (communityOrId?: Community | string): string => `${Routes.optional(Routes.parameterize(communityOrId))}/sign-up`
  public static unauthenticated = Routes.signUp
  public static forgotPassword = (): string => '/forgot-password'
  public static resetPassword = (token: string): string => `/reset-password/${token}`

  public static places = (communityOrId?: Community | string): string => `${Routes.optional(Routes.parameterize(communityOrId))}/places`
  public static place = (recordOrId?: RecordTypeInstance | string, communityOrId?: Community | string): string =>
    `${Routes.optional(Routes.parameterize(communityOrId))}/places/${Routes.parameterize(recordOrId)}`
  public static premiumPlace = (recordOrId?: RecordTypeInstance | string, communityOrId?: Community | string): string =>
    `${Routes.optional(Routes.parameterize(communityOrId))}/places/premium/${Routes.parameterize(recordOrId)}`

  public static events = (communityOrId?: Community | string): string => `${Routes.optional(Routes.parameterize(communityOrId))}/events`
  public static event = (recordOrId?: RecordTypeInstance | string, communityOrId?: Community | string, location?: Location): string => {
    if (location?.pathname?.startsWith('/feeds')) return `${location.pathname}/${Routes.parameterize(recordOrId)}`
    return `${Routes.optional(Routes.parameterize(communityOrId))}/events/${Routes.parameterize(recordOrId)}`
  }
  public static stories = (communityOrId?: Community | string): string => `${Routes.optional(Routes.parameterize(communityOrId))}/stories`
  public static story = (recordOrId?: RecordTypeInstance | string, communityOrId?: Community | string): string =>
    `${Routes.optional(Routes.parameterize(communityOrId))}/stories/${Routes.parameterize(recordOrId)}`

  public static community = (communityOrId?: Community | string): string => `/${Routes.parameterize(communityOrId)}`

  public static dash = (): string => '/dash'
  public static dashWelcome = (): string => '/dash/welcome'

  public static yourEvents = (): string => '/dash/your-events'
  public static yourPastEvents = (): string => '/dash/your-events/past'

  public static yourPlaces = (): string => '/dash/your-places'
  public static yourStories = (): string => '/dash/your-stories'
  public static profile = (): string => '/dash/profile'

  public static newPlace = (): string => '/dash/place/add'
  public static editPlace = (recordOrId: RecordTypeInstance | string): string => `/dash/place/edit/${Routes.parameterize(recordOrId)}`

  public static newEvent = (): string => '/dash/event/add'
  public static editEvent = (recordOrId: RecordTypeInstance | string): string => `/dash/event/edit/${Routes.parameterize(recordOrId)}`

  public static newStory = (): string => '/dash/story/add'
  public static editStory = (recordOrId: RecordTypeInstance | string): string => `/dash/story/edit/${Routes.parameterize(recordOrId)}`

  public render(): React.ReactNode {
    return (
      <Router>
        <App>
          <Suspense fallback={<Loading className="isCentered isDark" />}>
            <Switch>
              <Redirect exact path={Routes.dash()} to={Routes.dashWelcome()} />
              <Route path={Routes.dash()}>
                <RequireSignedIn>
                  <DashboardLayout>
                    <Switch>
                      <Route exact path={Routes.dashWelcome()}>
                        <DashWelcome />
                      </Route>

                      <Route exact path={Routes.profile()}>
                        <ProfileEdit />
                      </Route>

                      <Route exact path={Routes.newEvent()}>
                        <EventNewContainer />
                      </Route>
                      <Route path={Routes.editEvent(':id')}>
                        <EventEditContainer />
                      </Route>

                      <Route exact path={Routes.newPlace()}>
                        <PlaceNewContainer />
                      </Route>
                      <Route path={Routes.editPlace(':id')}>
                        <PlaceEditContainer />
                      </Route>

                      <Redirect from="/dash/headline/add" to={Routes.newStory()} />
                      <Route exact path={Routes.newStory()}>
                        <StoryNewContainer />
                      </Route>
                      <Redirect from={`/dash/headline/edit/:id`} to={Routes.editStory(':id')} />
                      <Route path={Routes.editStory(':id')}>
                        <StoryEditContainer />
                      </Route>

                      <Route exact path={Routes.yourEvents()}>
                        <YourUpcomingEventsContainer />
                      </Route>
                      <Route exact path={Routes.yourPastEvents()}>
                        <YourPastEventsContainer />
                      </Route>
                      <Route exact path={Routes.yourPlaces()}>
                        <YourPlacesContainer />
                      </Route>
                      <Redirect from="/dash/your-headlines" to={Routes.yourStories()} />
                      <Route exact path={Routes.yourStories()}>
                        <YourStoriesContainer />
                      </Route>
                    </Switch>
                  </DashboardLayout>
                </RequireSignedIn>
              </Route>

              {/* public routes */}
              <Route exact path={Routes.signIn()}>
                <SignIn />
              </Route>
              <Route path={Routes.signUp(':idOrName?')}>
                <SignUpContainer />
              </Route>
              <Route path={Routes.forgotPassword()}>
                <ForgotPassword />
              </Route>
              <Route path={Routes.resetPassword(':token')}>
                <ResetPassword />
              </Route>

              <PublicLayout>
                <Switch>
                  <Route path="/feeds/:feedId/events/:id">
                    <ContentFeedEventViewContainer />
                  </Route>
                  <Route path="/feeds/:id/events">
                    <RedirectToSingleRecord />
                    <ContentFeedViewContainer />
                  </Route>

                  <Redirect from="/place/:id" to={Routes.place(':id')} />
                  <Route exact path="/:idOrName?/places/:id?">
                    <RedirectToSingleRecord />
                    <RequireLiveCommunity />
                    <PlacesListContainer />
                  </Route>
                  <Redirect from="/place/premium/:id" to={Routes.premiumPlace(':id')} />
                  <Route path="/:idOrName?/places/premium/:id">
                    <RequireLiveCommunity />
                    <PlaceViewContainer />
                  </Route>

                  <Route path="/:idOrName?/events/:id">
                    <RequireLiveCommunity />
                    <EventViewContainer />
                  </Route>
                  <Route path="/:idOrName?/events">
                    <RedirectToSingleRecord />
                    <RequireLiveCommunity />
                    <EventsListContainer />
                  </Route>

                  <Redirect from="/headline/view/:id" to={Routes.story(':id')} />
                  <Redirect from="/:idOrName?/headlines/:id" to={Routes.story(':id')} />
                  <Route path="/:idOrName?/stories/:id">
                    <RequireLiveCommunity />
                    <StoryViewContainer />
                  </Route>
                  <Redirect from="/:idOrName?/headlines" to={Routes.stories(':idOrName?')} />
                  <Route path="/:idOrName?/stories">
                    <RedirectToSingleRecord />
                    <RequireLiveCommunity />
                    <StoriesListContainer />
                  </Route>

                  <Route path={Routes.community(':idOrName?')}>
                    <RequireLiveCommunity />
                    <EventsListContainer />
                  </Route>
                </Switch>
              </PublicLayout>
            </Switch>
          </Suspense>
        </App>
      </Router>
    )
  }
}

export default Routes
