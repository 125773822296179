import { css } from '@emotion/react'
import { useField } from 'formik'
import normalizeUrl from 'normalize-url'
import MagicUrl from 'quill-magic-url'
import 'quill-paste-smart'
import React, { useCallback, useEffect, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import toastr from 'toastr'
import cloudinaryTools from '../../../util/cloudinaryTools'
import { UploadState } from '../../../util/constants'
import { mediaSrc } from '../misc/MediaPresets'

Quill.register('modules/magicUrl', MagicUrl)

const Link = Quill.import('formats/link')

class AbsoluteLink extends Link {
  static create(value) {
    const node = super.create(value)
    value = this.sanitize(value)
    try {
      value = normalizeUrl(value, { stripWWW: false })
    } catch (error) {
      console.error(error)
    }

    if (/(https?:\/\/)?(www\.)?civiclift.com/i.test(value)) {
      node.removeAttribute('target')
    }
    node.setAttribute('href', value)
    return node
  }
}

Quill.register(AbsoluteLink)

type Props = {
  onUploadingStateChange
  toolbar
  isRequired
  label
  id
  formats
  placeholder
  name
}

interface RichTextEditorProps {
  id?: string
  label?: string
  value?: string
  placeholder?: string
  onChange?(...args: unknown[]): unknown
  toolbar?: unknown[]
}

const RichTextEditor: React.FC<Props> = ({
  onUploadingStateChange,
  toolbar,
  isRequired,
  label,
  id,
  formats,
  placeholder,
  name,
}: RichTextEditorProps) => {
  // we don't need meta but can't skip it since it's in an array
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [field, _meta, helpers] = useField(name)
  const editor = useRef(null)

  const insertToEditor = useCallback(
    publicId => {
      // push image publicId to rich editor.
      const range = editor.current.getEditor().getSelection()
      editor.current.getEditor().insertEmbed(range.index, 'image', mediaSrc({ publicId }))
    },
    [editor],
  )

  const uploadFileToCloudinary = useCallback(
    async (file: File) => {
      onUploadingStateChange && onUploadingStateChange(UploadState.UPLOADING)

      await cloudinaryTools.upload(file, { folder: 'uploads' }, (error, response) => {
        const uploadState = error ? UploadState.ERROR : UploadState.SUCCESS
        onUploadingStateChange && onUploadingStateChange(uploadState)

        if (error) {
          toastr.error('Upload error', error)
        } else {
          insertToEditor(response.public_id)
        }
      })
    },
    [onUploadingStateChange, insertToEditor],
  )

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.click()
    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]
      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadFileToCloudinary(file)
      } else {
        toastr.warning('You can only upload images.')
      }
    }
  }, [uploadFileToCloudinary])

  useEffect(() => {
    editor.current
      .getEditor()
      .getModule('toolbar')
      .addHandler('image', () => {
        selectLocalImage()
      })
  }, [editor, selectLocalImage])

  const modules = { toolbar, magicUrl: true }
  return (
    <div
      required={isRequired}
      className="input-row"
      css={css`
        display: flex;
      `}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <ReactQuill
          theme="snow"
          formats={formats}
          modules={modules}
          placeholder={placeholder}
          onChange={textContent => {
            helpers.setValue(textContent)
          }}
          value={field.value || ''}
          ref={editor}
        />
      </div>
    </div>
  )
}

RichTextEditor.defaultProps = {
  formats: ['header', 'bold', 'italic', 'blockquote', 'link', 'list', 'image'],
  toolbar: [[{ header: [2, 3, false] }], ['bold', 'italic', 'blockquote', 'link'], [{ list: 'ordered' }, { list: 'bullet' }]],
}

export default RichTextEditor
