import dayjs from 'dayjs'
import React from 'react'
import Flatpickr from 'react-flatpickr'

/* usage:
<Calendar
  // array of Date objects or timestamps or properly formatted strings
  dates={[dayjs(event.startAt).startOf('day').valueOf()]} />
  // handler for calendar day clicked
  onChangeDate={filterEventsByDate}
  // selected date
  value={dayjs(event.startAt).startOf('day').valueOf()}
*/
type Props = { dates?: Date[]; value?: Date; onChangeDate: (date: Date) => void }

const Calendar: React.FC<Props> = ({ dates, value = new Date(), onChangeDate }) => (
  <div className="mini-cal-widget">
    <div className="mini-cal">
      <Flatpickr
        id="cal-widget"
        onChange={datesSelected => onChangeDate(datesSelected[0])}
        value={value}
        options={{
          // when dates array is not available, enable all dates
          ...(dates ? { enable: dates } : { disable: [] }),
          inline: true,
          locale: {
            weekdays: {
              shorthand: ['s', 'm', 't', 'w', 't', 'f', 's'],
              longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            },
            months: {
              shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              longhand: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
            },
          },
        }}
      />
    </div>

    <div className="mini-cal-key">
      <button className="btn today-btn-red" onClick={() => onChangeDate(dayjs().startOf('day').toDate())}>
        Today
      </button>
      <i className="cal-key-circle" /> <em>Day with an event scheduled.</em>
    </div>
  </div>
)

export default Calendar
