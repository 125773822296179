import React from 'react'
import { Community } from '../../../models'
import Routes from '../../../startup/routes'
import { isEmbedded } from '../../../util/window'
import CalendarContainer from '../../containers/misc/CalendarContainer'
import EmbeddedModalLinkBlocker from '../misc/EmbeddedModalLinkBlocker'
import FilterByCommunity from '../misc/FilterByCommunity'
import EventSignUpUpsell from './EventSignUpUpsell'

type Props = {
  communities?: Community[]
  blockedEventCommunities?: string[]
  selectedDay: Date
  toggleEventCommunity?: React.ChangeEventHandler<HTMLInputElement>
}
const EventsListSidebar: React.FC<Props> = ({ communities, blockedEventCommunities, selectedDay, toggleEventCommunity }) => (
  <aside>
    <EmbeddedModalLinkBlocker
      to={Routes.newEvent()}
      className="btn btn-red btn-new submit-event"
      modalComponent={EventSignUpUpsell}
      modalCTALink={Routes.signUp(communities?.[0])}
    >
      Submit an Event
    </EmbeddedModalLinkBlocker>

    <CalendarContainer selectedDay={selectedDay} />

    {communities && communities.length > 1 && !isEmbedded() && blockedEventCommunities && toggleEventCommunity && (
      <FilterByCommunity communities={communities} blockedCommunities={blockedEventCommunities} handleToggle={toggleEventCommunity} />
    )}
  </aside>
)

export default EventsListSidebar
