import React from 'react'
import { createMarkup } from '../../helpers/helpers'

const getMessageClass = (type: Props['type']) => {
  let result = 'alert'
  if (type) result += ` alert-${type}`
  return result
}

type Props = { type?: 'success' | 'info' | 'warning' | 'danger'; content?: string }
const MessageForm: React.FC<Props> = ({ type, content }) => {
  return content ? <div className={getMessageClass(type)} dangerouslySetInnerHTML={createMarkup(content)} /> : null
}

export default MessageForm
