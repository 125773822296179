import dayjs from 'dayjs'
import { DateLike } from '../../util/dateTime'

class EventStartEndDateTime {
  public startAt: Date
  public endAt: Date

  public constructor({ startAt, endAt }: { startAt: Date; endAt: Date }) {
    this.startAt = startAt
    this.endAt = endAt
  }

  private mergeDateTime(date?: DateLike, time?: string): Date {
    const dateTime = dayjs(date)
    if (!time) {
      return dateTime.toDate()
    }

    // dayjs won't work with time only, so we'll add an arbitrary date to the time string so it can be parsed
    const timeMoment = dayjs('12-17-1986 ' + time, 'MM-DD-YYYY h:mma')

    if (!timeMoment.isValid()) {
      return dateTime.toDate()
    }

    return dateTime.set('hour', timeMoment.get('hour')).set('minute', timeMoment.get('minute')).startOf('minute').toDate()
  }

  private splitDateTime(dateTime?: DateLike): { date?: Date; time: string } {
    const timeMoment = dayjs(dateTime)
    const time = timeMoment.format('h:mma')
    return {
      date: timeMoment.toDate(),
      time,
    }
  }

  public get startAtDate(): Date | undefined {
    return this.startAt
  }

  public set startAtDate(value: Date | undefined) {
    this.startAt = this.mergeDateTime(value, this.startAtTime)
    this.endAt = this.mergeDateTime(value, this.endAtTime)
  }

  public get startAtTime(): string {
    return this.splitDateTime(this.startAt).time
  }

  public set startAtTime(value: string) {
    this.startAt = this.mergeDateTime(this.startAtDate, value)
  }

  public get endAtDate(): Date | undefined {
    return this.endAt
  }

  public set endAtDate(value: Date | undefined) {
    this.endAt = this.mergeDateTime(value, this.endAtTime)
  }

  public get endAtTime(): string {
    return this.splitDateTime(this.endAt).time
  }

  public set endAtTime(value: string) {
    this.endAt = this.mergeDateTime(this.endAtDate, value)
  }
}

export default EventStartEndDateTime
