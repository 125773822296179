import { css } from '@emotion/react'
import React from 'react'
import { AutoField } from 'uniforms-unstyled'
import { marginBottom } from '../../../styles/vars'

type Props = { name: string; type?: string; label?: string }
const AccountField: React.FC<Props> = props => (
  <AutoField
    {...props}
    css={css`
      width: 100%;
      ${marginBottom}
    `}
  />
)

export default AccountField
