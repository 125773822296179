import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../../startup/routes'

const UnauthenticatedNav: React.FC = () => (
  <nav className="public-nav-login">
    <Link className="btn btn-join" to={Routes.signUp()}>
      Join <span className="show-for-medium">now</span>
    </Link>
    <span>
      <span className="show-for-medium">or</span>
      <Link className="signIn" to={Routes.signIn()}>
        Sign in
      </Link>
    </span>
    {/*
    <div className="search-toggle">
      <button className="header-search-btn" title="search">
        <i className="icon-search-darkblue" aria-hidden="true" />
      </button>
    </div>
    */}
  </nav>
)

export default UnauthenticatedNav
