import gql from 'graphql-tag'
import { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import toastr from 'toastr'
import { AutoForm } from 'uniforms-unstyled'
import { prepareToSubmit } from '../../../api/events/helpers'
import { eventFragment } from '../../../graphql/fragments'
import useOnImageUploadStateChange from '../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../hooks/useRecordSubmissionMutation'
import { Event } from '../../../models'
import { EventFormSchema } from '../../../schemas/events/eventSchema'
import Routes from '../../../startup/routes'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import Loading from '../misc/Loading'
import EventFields from './EventFields'

const EVENT_EDIT = gql`
  mutation EventUpdate($input: EventUpdateInput!) {
    eventUpdate(input: $input) {
      event {
        ...eventFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${eventFragment}
`

type Props = { event: Event; loading: boolean }
const EventEdit: React.FC<Props> = ({ event: originalEvent, loading }) => {
  const history = useHistory()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const formRef = useRef(null)

  const navigate = useCallback(() => history.push(Routes.yourEvents()), [history])

  const beforeSubmit = useCallback(doc => prepareToSubmit(doc), [])

  const onSuccess = useCallback(() => {
    toastr.success('Event updated!')
    navigate()
  }, [navigate])

  const {
    handleSubmit,
    mutationLoading: recordUpdateMutationLoading,
    onValidate,
  } = useRecordSubmissionMutation({ query: EVENT_EDIT, queryName: 'eventUpdate', recordName: 'event', beforeSubmit, onSuccess })

  const onImageUploadStateChange = useOnImageUploadStateChange(setIsSubmitDisabled)

  if (loading) return <Loading className="isDark" />

  return (
    <div>
      <h1 className="content-form-heading">Edit Event</h1>

      <AutoForm
        className="form content-entry-form event-form"
        model={originalEvent}
        onSubmit={handleSubmit}
        onValidate={onValidate}
        ref={formRef}
        schema={EventFormSchema}
      >
        <EventFields onImageUploadStateChange={onImageUploadStateChange} originalEvent={originalEvent} />

        <fieldset className="submit-row">
          <UniformsButton
            disabled={recordUpdateMutationLoading || isSubmitDisabled}
            shouldShowLoadingSpinner={recordUpdateMutationLoading}
            text="Submit Changes"
          />
        </fieldset>
      </AutoForm>
    </div>
  )
}

export default EventEdit
