import { Place } from '../../models'
import { EventTypesType, RepeatingPatternsType, WeekdaysType } from '../../util/constants'
import { applyMixins } from '../../util/mixins'
import BaseModel from '../model'
import { Address, Contact, Publishable } from '../types'
import EventStartEndDateTime from './EventStartEndDateTime'

class Event extends BaseModel {
  public readonly objectType = 'event'
  public readonly __typename = 'Event'

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor(doc) {
    super()
    const id = doc.id || doc._id
    Object.assign(this, { ...doc, _id: id, id })

    const { startAt, endAt, repeatStopsOn } = doc

    if (typeof startAt === 'string') {
      this.startAt = new Date(startAt)
    }
    if (typeof endAt === 'string') {
      this.endAt = new Date(endAt)
    }
    if (typeof repeatStopsOn === 'string') {
      this.repeatStopsOn = new Date(repeatStopsOn)
    }
  }

  public getClassType(type: number): string {
    return `place${type}`
  }

  public place?: Place
  public tempPlace?: {
    name?: string
    url?: string
    address?: Address
  }
  public title?: string
  public image?: { publicId?: string }
  public costMin?: number
  public costMax?: number
  public dressCode?: string
  public category?: string
  public description?: string
  public excerpt?: string
  public organizer?: Contact
  public externalSourceUrl?: string
  public useTempPlace?: boolean

  public eventType?: EventTypesType
  public occurrences?: EventStartEndDateTime[]
  public repeatDays?: { [key in WeekdaysType]: boolean }
  public repeats?: RepeatingPatternsType
  public repeatStopsOn?: Date
  public repeatingEventId?: string
  public day?: string
  public isVirtual?: boolean
  public url?: string
}

export default Event

interface Event extends EventStartEndDateTime {} // eslint-disable-line @typescript-eslint/no-empty-interface
applyMixins(Event, [EventStartEndDateTime])
interface Event extends Publishable {} // eslint-disable-line @typescript-eslint/no-empty-interface
applyMixins(Event, [Publishable])
