import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import { isEmbedded } from '../../../util/window'
import Modal from '../misc/Modal'

type ModalComponentProps = {
  closeModal: () => void
  ctaLink: string
}

type Props = {
  modalComponent: null | React.FC<ModalComponentProps> | React.ComponentType<ModalComponentProps>
  className?: string
  to: string
  modalCTALink: string
}
const EmbeddedModalLinkBlocker: React.FC<Props> = ({ to, className, modalComponent: ModalComponent, modalCTALink, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { currentUser } = useContext(CurrentUserContext)
  const extraProps = {}
  if (currentUser && isEmbedded()) extraProps['target'] = '_blank'

  const openModal = useCallback(
    e => {
      if (!currentUser && isEmbedded()) {
        e.preventDefault()
        setIsModalOpen(true)
      }
    },
    [currentUser],
  )

  const closeModal = useCallback(() => setIsModalOpen(false), [])

  return (
    <>
      {ModalComponent ? (
        <Modal className="ReactModal-modal-base--light ReactModal-modal-base--narrow" isOpen={isModalOpen} onRequestClose={closeModal}>
          <ModalComponent closeModal={closeModal} ctaLink={modalCTALink} />
        </Modal>
      ) : null}
      <Link to={to} {...extraProps} className={className} onClick={openModal}>
        {children}
      </Link>
    </>
  )
}

export default EmbeddedModalLinkBlocker
