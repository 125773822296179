import React from 'react'
import ApolloProvider from '../providers/ApolloProvider'
import CurrentUserProvider from '../providers/CurrentUserContext'
import GlobalStyles from '../styles/GlobalStyles'
import Routes from './routes'

const App: React.FC = () => (
  <ApolloProvider>
    <GlobalStyles />
    <CurrentUserProvider>
      <Routes />
    </CurrentUserProvider>
  </ApolloProvider>
)

export default App
