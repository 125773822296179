import gql from 'graphql-tag'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { useMemo } from 'react'
import { calculateShouldFetchSingleCommunity } from '../../api/records'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import { storyFragment } from '../../graphql/fragments'
import { useRedirect } from '../../hooks'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import Routes from '../../startup/routes'
import { LocalStorage } from '../../util/window'
import StoriesList from '../components/stories/StoriesList'

const StoriesListQuery = gql`
  query StoriesList($communitySlug: String!, $shouldFetchSingleCommunity: Boolean = true, $cursor: String) {
    community(slug: $communitySlug) @include(if: $shouldFetchSingleCommunity) {
      id
      slug
      featuredStory {
        ...storyFragment
      }
      stories(first: 30, after: $cursor) {
        nodes {
          ...storyFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    currentUser @skip(if: $shouldFetchSingleCommunity) {
      id
      subscribedStories(first: 30, after: $cursor) {
        nodes {
          ...storyFragment

          # TODO: many-to-many
          communities {
            id
            featuredStory {
              ...storyFragment
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      subscribedStoriesCommunities {
        id
        displayName
        storyCategories
      }
      primaryCommunity {
        id
        featuredStory {
          ...storyFragment
        }
      }
    }
  }
  ${storyFragment}
`

const StoriesListContainer: React.FC = () => {
  const { idOrName } = useParams()
  const { currentUser, loading: loadingCurrentUser } = React.useContext(CurrentUserContext)
  const communitySlug = idOrName || currentUser?.primaryCommunity?.slug || LocalStorage.getItem('customDomainOrigin') || ''
  const shouldFetchSingleCommunity = calculateShouldFetchSingleCommunity(idOrName, currentUser)
  const isUnknownCommunity = shouldFetchSingleCommunity && !communitySlug

  const property = shouldFetchSingleCommunity ? 'community.stories' : 'currentUser.subscribedStories'
  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(StoriesListQuery, property, {
    variables: { communitySlug, shouldFetchSingleCommunity },
    skip: isUnknownCommunity || loadingCurrentUser,
  })

  useRedirect(!loadingCurrentUser && isUnknownCommunity && Routes.unauthenticated())
  let didRedirect = !loadingCurrentUser && isUnknownCommunity
  const community = data?.community
  useRedirect(!didRedirect && shouldFetchSingleCommunity && community && community.slug !== idOrName && Routes.stories(community))
  didRedirect ||= shouldFetchSingleCommunity && community && community.slug !== idOrName

  const stories = useMemo(
    () => (shouldFetchSingleCommunity ? data?.community?.stories?.nodes : data?.currentUser?.subscribedStories?.nodes) || [],
    [data, shouldFetchSingleCommunity],
  )

  const communities = data?.currentUser?.subscribedStoriesCommunities || (community ? [community] : [])

  const featuredStory = useMemo(() => {
    if (shouldFetchSingleCommunity) return stories[0] || null
    return data?.currentUser?.primaryCommunity?.featuredStory || stories.find(story => story.community?.featuredStory) || null
  }, [data, stories, shouldFetchSingleCommunity])

  return didRedirect ? null : (
    <StoriesList
      loading={loading}
      communities={communities}
      featuredStory={featuredStory}
      stories={stories}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  )
}

export default StoriesListContainer
