import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2'
import SimpleSchema from '../schema'

const SignUpSchema = new SimpleSchema({
  firstName: { type: String, label: 'First Name' },
  lastName: { type: String, label: 'Last Name', optional: true },
  email: { type: String, regEx: SimpleSchema.RegEx.Email, label: 'Email' },
  password: { type: String, min: 6, label: 'Password' },
  primaryCommunityId: { type: String, label: 'Community' },
  _acceptTerms: {
    type: Boolean,
    custom() {
      if (this.value !== true) return 'acceptTerms'
      return undefined
    },
    optional: true,
  },
})

export default new SimpleSchema2Bridge(SignUpSchema)
