import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import ReactMapboxGL from 'react-mapbox-gl'
import { FactoryParameters, Props as ReactMapboxGLProps } from 'react-mapbox-gl/lib/map'
import { MAPBOX_ACCESS_TOKEN } from '../../../util/constants'

import Loading from '../misc/Loading'
import { addMissingMarker } from './markers'
import SizeUpdater from './SizeUpdater'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(mapboxgl as any).accessToken = MAPBOX_ACCESS_TOKEN

interface ExtendedProps extends ReactMapboxGLProps {
  loading?: boolean
  onSizeUpdate?: (rect: DOMRect) => void
  onStyleLoad?: (map, event) => void
  factoryParameters?: FactoryParameters
}

type Props = Omit<Omit<ExtendedProps, 'style'>, 'onRender'>

const Mapbox: React.FC<Props> = ({ children, factoryParameters, onStyleLoad, onSizeUpdate, loading: parentIsLoading, ...props }) => {
  const [mapIsLoading, setMapIsLoading] = useState(true)
  const [imagesHavePopulated, setImagesHavePopulated] = useState(false)

  const loading = Boolean(mapIsLoading || parentIsLoading)

  useEffect(() => {
    let canceled = false
    setTimeout(() => !canceled && setMapIsLoading(false), 3000)

    return (): void => {
      canceled = true
    }
  }, [imagesHavePopulated])

  const MapboxGL = useMemo(
    () => ReactMapboxGL({ accessToken: MAPBOX_ACCESS_TOKEN, trackResize: false, ...(factoryParameters || {}) }),
    [factoryParameters],
  )

  return (
    <>
      <MapboxGL
        style="mapbox://styles/mapbox/light-v10?optimize=true"
        onRender={(map): void => {
          if (!imagesHavePopulated && !isEmpty(get(map, 'style.imageManager.images'))) {
            setImagesHavePopulated(true)
          }
        }}
        onStyleLoad={(map, event): void => {
          setMapIsLoading(false)
          if (onStyleLoad) {
            onStyleLoad(map, event)
          }

          map.on('styleimagemissing', e => addMissingMarker({ map, name: e.id }))
        }}
        {...props}
      >
        <>
          <SizeUpdater onUpdate={onSizeUpdate} />
          {children}
        </>
      </MapboxGL>
      {loading && (
        <div className="splash-screen">
          <Loading className="isDark" />
        </div>
      )}
    </>
  )
}

export default Mapbox
