import React, { useContext } from 'react'
import BodyClassName from 'react-body-classname'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'

import { Story, User } from '../../../models'
import Routes from '../../../startup/routes'
import { getTimeFromNow } from '../../../util/dateTime'
import { createMarkup } from '../../helpers/helpers'
import ResponsiveImage, { mediaSrc } from '../misc/MediaPresets'
import { ActionControls } from '../misc/RecordView'
import UserBadge from '../users/UserBadge'

const MetaData: React.FC<{ story: Story }> = ({ story }) => {
  const { community } = story

  return (
    <Helmet>
      <title>{story.title}</title>
      {story.image && <meta property="og:image" content={mediaSrc(story.image)} />}
      <link rel="canonical" href={Routes.customUrl(community, Routes.story(story, community))} />
    </Helmet>
  )
}

const StoryAuthorInfo: React.FC<{ author?: User }> = ({ author }) => {
  const { currentUser } = useContext(CurrentUserContext)

  if (!author) {
    return null
  }

  const isAuthorCurrentUser = currentUser && author.id === currentUser.id

  return (
    <>
      <div className="story-footer-avatar">
        <UserBadge user={author} />
      </div>
      <div className="story-footer-info">
        <h2>
          {author.firstName} {author.lastName}
        </h2>
        <p className="rte" dangerouslySetInnerHTML={createMarkup(author.signature)} />
        {isAuthorCurrentUser && (
          <div>
            <Link to={Routes.profile()} className="link">
              <i className="icon-gear-red" />
              <span className="link-text"> Edit Bio</span>
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

const StoryView: React.FC<{ story?: Story }> = ({ story }) => {
  if (!story) {
    return null
  }

  return (
    <BodyClassName className="path-stories">
      <section className="story-view">
        {story && <MetaData story={story} />}

        <article className="story-single">
          <header className="story-header">
            <div className="header-wrap">
              <Link to={Routes.stories(story.community)} className="btn btn-grey">
                <i className="icon-arrow-left" /> Back
              </Link>
              <h1 className="story-heading">{story.title}</h1>
              <h2 className="story-subhead">{story.excerpt}</h2>
            </div>

            <dl className="author-lockup">
              <dt className="author-lockup-avatar">
                <UserBadge user={story.submitter} />
              </dt>
              <dd className="author-lockup-details">
                by&nbsp;
                {story.submitter ? story.submitter.firstName : ''}&nbsp;
                {story.submitter ? story.submitter.lastName : ''}
                &nbsp;in&nbsp;
                {story.category}
                <br />
                <span className="author-meta">
                  {getTimeFromNow(story.publishedAt)}
                  &nbsp; &middot; {story.readingTime}
                </span>
              </dd>
            </dl>
          </header>

          {story.image ? (
            <div className="story-img-lg">
              <ResponsiveImage publicId={story.image.publicId} height="600" width={null} />
            </div>
          ) : (
            ''
          )}

          <div className="story-body">
            <div className="rte" dangerouslySetInnerHTML={createMarkup(story.content)} />
            <ActionControls record={story} />
          </div>

          <footer className="story-footer">
            <StoryAuthorInfo author={story.submitter} />
          </footer>
        </article>
      </section>
    </BodyClassName>
  )
}

export default StoryView
