import gql from 'graphql-tag'
import { useContext } from 'react'
import { useQuery } from '../api/graphql'
import { CurrentUserContext } from '../providers/CurrentUserContext'

export const StoryFormQuery = gql`
  query StoryFormQuery {
    currentUser {
      id
    }
    communities {
      id
      displayName
      storyCategories
    }
  }
`

type Data = {
  currentUser: { id: string }
  communities: { id: string; displayName: string; storyCategories: string[] }[]
}
const useStoryFormData = (): { data: Data; loading: boolean } => {
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)

  const { data, loading } = useQuery(StoryFormQuery, { skip: loadingCurrentUser })
  return { data, loading: loading || loadingCurrentUser }
}

export default useStoryFormData
