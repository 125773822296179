import dayjs from 'dayjs'
import values from 'lodash/values'
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2'
import { EventTypes, RepeatingPatterns } from '../../util/constants'
import EventCategories from '../../util/eventCategories'
import { usPhoneRegEx } from '../../util/string'
import AddressSchema from '../addressSchema'
import MediaSchema from '../mediaSchema'
import SimpleSchema from '../schema'
import SlugSchema from '../slugSchema'
import SubmissionCommonSchema from '../submissionCommonSchema'

const TIME_INCREMENT = 15
// this magic creates an array of time values as strings (e.g. "12:00am", "12:15am", etc.)
// it uses this strange syntax Array.from(Array(<number>).keys())
// which creates an array of numbers from 0 to <number> - 1
// the <number> that we pass in is: for every hour in the day (24),
// for every minute in the hour (60) divided by the increment (15)
// (how many minutes we want to have between each time value)
// if it were 1 minute, we'd have 24 * (60 / 1) so we'd have string values like:
// "12:00am", "12:01am", "12:02am", "12:03am", etc.
// by using (60 / 15 = 4) we get "12:00am", "12:15am", "12:30am", "12:45am", etc. (4 per hour)
const TIME_VALUES = Array.from(Array(24 * (60 / TIME_INCREMENT)).keys()).map(i => {
  let hour = Math.floor((i * TIME_INCREMENT) / 60)
  let minute = String((i * TIME_INCREMENT) % 60)
  // pad zero to minute
  if (minute.length === 1) minute = '0' + minute
  const ampm = hour < 12 ? 'am' : 'pm'
  // 12-hour format
  if (hour > 12) hour -= 12
  if (hour === 0) hour = 12
  return `${hour}:${minute}${ampm}`
})

// the scaffold schema is main event schema used to omit repeatingEventId
// from places like EventForm
const EventScaffoldSchema = new SimpleSchema({
  category: { type: String, label: 'Category', allowedValues: EventCategories },
  subcategory: { type: String, label: 'Subcategory', optional: true },
  title: { type: String, max: 120, label: 'Event Title' },
  description: { type: String, max: 10000, label: 'Description' },
  costMin: { type: Number, defaultValue: 0, label: '$', optional: true },
  costMax: { type: Number, defaultValue: 0, label: '$', optional: true },
  dressCode: { type: String, label: 'Dress Code', allowedValues: ['Casual', 'Dressy', 'Business', 'Formal'], optional: true },
  image: { type: MediaSchema, label: 'Image', optional: true },
  startAt: { type: Date, label: 'Start' },
  endAt: { type: Date, label: 'End', optional: true },
  eventType: { type: String, allowedValues: values(EventTypes), defaultValue: EventTypes.SINGLE_EVENT },
  repeats: {
    type: String,
    label: 'Repeats',
    allowedValues: values(RepeatingPatterns),
    optional: true,
    custom() {
      const eventType = this.field('eventType').value
      if (eventType === EventTypes.SINGLE_EVENT) return
      if (!this.value) return 'selectRecurrencePattern'
      return
    },
  },
  repeatStopsOn: {
    type: Date,
    label: 'Repeats Until',
    optional: true,
    custom() {
      const eventType = this.field('eventType').value
      if (eventType === EventTypes.SINGLE_EVENT) return
      if (eventType === EventTypes.ONGOING_EVENT && !this.value) return 'ongoingLastsUntilRequired'
      const startAt = this.field('startAt').value
      if (!startAt || !this.value) return
      const startAtDay = dayjs(startAt).startOf('day')
      if (startAtDay > this.value) return 'startAtMustBeBeforeRepeatStopsOn'
      return
    },
  },
  repeatDays: { type: Object, optional: true, defaultValue: {} },
  'repeatDays.Sunday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Monday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Tuesday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Wednesday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Thursday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Friday': { type: Boolean, optional: true, defaultValue: false },
  'repeatDays.Saturday': { type: Boolean, optional: true, defaultValue: false },
  useTempPlace: {
    type: Boolean,
    defaultValue: false,
    label: "My event's venue is not available in the CivicLift Places directory",
    optional: true,
  },
  // a placeId is required if useTempPlace is false
  placeId: {
    type: String,
    autoValue() {
      if (this.field('useTempPlace').value) {
        this.unset()
        return null
      }
      return
    },
    label: 'Venue',
    optional: true,
    custom() {
      if (this.field('isVirtual').value) return
      const makeRequired = this.field('useTempPlace').value === false

      if (makeRequired) {
        // inserts
        if (!this.operator) {
          if (!this.isSet || this.value === null || this.value === '') return 'placeRequired'
        } else if (this.isSet) {
          // updates
          if (this.operator === '$set' && (this.value === null || this.value === '')) return 'placeRequired'
          if (this.operator === '$unset') return 'placeRequired'
          if (this.operator === '$rename') return 'placeRequired'
        }
      }
      return
    },
  },
  // a tempPlace object is required if useTempPlace is true
  tempPlace: {
    type: Object,
    custom() {
      if (this.field('isVirtual').value) return
      const makeRequired = this.field('useTempPlace').value

      if (makeRequired) {
        // inserts
        if (!this.operator) {
          if (!this.isSet || this.value === null || this.value === '') return 'tempPlaceRequired'
          // updates
        } else if (this.isSet) {
          if (this.operator === '$set' && (this.value === null || this.value === '')) return 'tempPlaceRequired'
          if (this.operator === '$unset') return 'tempPlaceRequired'
          if (this.operator === '$rename') return 'tempPlaceRequired'
        }
      }
      return
    },
    autoValue() {
      if (this.field('isVirtual').value || !this.field('useTempPlace').value) this.unset()
    },
    optional: true,
  },
  'tempPlace.name': { type: String, label: 'Place Name' },
  'tempPlace.address': { type: AddressSchema, optional: true },
  'tempPlace.url': { type: String, regEx: SimpleSchema.RegEx.Url, label: 'Place URL', optional: true },
  /* this property lets the user add alternate contact info to
  the event, if false the user would be prompted to input a
  contactName, contactEmail, and contactPhone instead of
  automatically using the name and email from their account info */
  submitterOwnsEvent: {
    type: Boolean,
    defaultValue: true,
    label: 'I am the main point-of-contact for this event',
    optional: true,
  },
  organizer: { type: Object },
  // default to user's registered name
  'organizer.name': { type: String, label: 'Organizer', optional: true },
  // default to user's registered email
  'organizer.email': { type: String, regEx: SimpleSchema.RegEx.Email, label: 'Email', optional: true },
  'organizer.phone': {
    type: String,
    label: 'Phone',
    custom() {
      const phoneNumber = this.value || this.field('organizer.phone').value
      if (phoneNumber && !phoneNumber.match(usPhoneRegEx)) return 'phoneNumberFormat'
      return
    },
    optional: true,
  },
  isVirtual: { type: Boolean, defaultValue: false, label: 'This is an Online Event', optional: true },
  url: {
    type: String,
    regEx: SimpleSchema.RegEx.Url,
    label: 'Event URL',
    custom() {
      const isVirtual = this.field('isVirtual').value
      if (isVirtual && !this.value) return 'eventUrlRequiredForVirtualEvent'
      return
    },
    optional: true,
  },
})
EventScaffoldSchema.extend(SubmissionCommonSchema)

const EventScaffoldSchemaBridged = new SimpleSchema2Bridge(EventScaffoldSchema)
export { EventFormSchemaBridged as EventFormSchema, EventScaffoldSchemaBridged as EventScaffoldSchema }

const EventSchema = new SimpleSchema({
  // ongoing events day of the event: Day 3 of 5
  day: { type: String, optional: true },
  // the id of the "original" event instance that is used as a template for creating
  // future events
  repeatingEventId: { type: String, optional: true },
})
EventSchema.extend(EventScaffoldSchema)
EventSchema.extend(SlugSchema)

export default new SimpleSchema2Bridge(EventSchema)

const StartEndDateTimeEntry = new SimpleSchema({
  startAt: { type: Date, optional: true },
  endAt: { type: Date, optional: true },
  startAtDate: { type: Date, optional: true },
  startAtTime: { type: String, optional: true, allowedValues: TIME_VALUES, label: null },
  endAtTime: { type: String, optional: true, allowedValues: TIME_VALUES, label: null },
})

export const FREE_EVENT = 'Free Event'
export const ONE_FLAT_PRICE = 'One Flat Price'
export const MULTIPLE_PRICING_TIERS = 'Multiple Pricing Tiers'

const OPEN_TO_THE_PUBLIC_WALK_INS_WELCOME = 'Open to the public, walk-ins welcome'
export const BUY_TICKETS_ONLINE = 'Buy Tickets Online'
export const REGISTER_OR_RSVP_ONLINE = 'Register or RSVP Online'

const EventFormSchema = new SimpleSchema({
  costTier: {
    type: String,
    label: 'Cost',
    allowedValues: [FREE_EVENT, ONE_FLAT_PRICE, MULTIPLE_PRICING_TIERS],
    optional: true,
  },
  needTicketOrRsvp: {
    type: String,
    label: 'Attending',
    allowedValues: [OPEN_TO_THE_PUBLIC_WALK_INS_WELCOME, BUY_TICKETS_ONLINE, REGISTER_OR_RSVP_ONLINE],
    optional: true,
  },
  ticketOrRsvpUrl: {
    type: String,
    regEx: SimpleSchema.RegEx.Url,
    custom() {
      const needTicketOrRsvp = this.field('needTicketOrRsvp').value
      if (![BUY_TICKETS_ONLINE, REGISTER_OR_RSVP_ONLINE].includes(needTicketOrRsvp)) return
      if (!this.value) return 'eventLinkRequiredForTicketedEvent'
      return
    },
    optional: true,
    label: '',
  },
  updateAllFollowingEventsInSeries: {
    type: Boolean,
    label: 'Update all following events in series',
    optional: true,
  },
  startAtDate: { type: Date, optional: true },
  startAtTime: { type: String, optional: true, allowedValues: TIME_VALUES, label: null },
  endAtDate: { type: Date, optional: true },
  endAtTime: { type: String, optional: true, allowedValues: TIME_VALUES, label: null },

  occurrences: { type: Array, optional: true },
  'occurrences.$': { type: StartEndDateTimeEntry, optional: true },
})
EventFormSchema.extend(EventScaffoldSchema)

const EventFormSchemaBridged = new SimpleSchema2Bridge(EventFormSchema)

// since the events that we are importing from other sources
// don't necessarily have all the fields that are required by our schema
// we create a parallel schema with most of the fields optional
const AddressImportSchema = new SimpleSchema({
  state: {
    type: String,
    label: 'State / Province',
    optional: true,
  },
})
AddressImportSchema.extend(AddressSchema)

export const SubmittableEventCreateSchema = new SimpleSchema({
  needTicketOrRsvp: {
    type: String,
    allowedValues: [OPEN_TO_THE_PUBLIC_WALK_INS_WELCOME, BUY_TICKETS_ONLINE, REGISTER_OR_RSVP_ONLINE],
    optional: true,
  },
  ticketOrRsvpUrl: { type: String, optional: true },
  eventType: { type: String, optional: true },
  submitterOwnsEvent: { type: Boolean, optional: true },
  startAt: { type: Date, optional: true },
  endAt: { type: Date, optional: true },
  primaryCommunityId: { type: String, optional: true },
  title: { type: String, optional: true },
  description: { type: String, optional: true },
  costTier: { type: String, allowedValues: [FREE_EVENT, ONE_FLAT_PRICE, MULTIPLE_PRICING_TIERS], optional: true },
  costMin: { type: Number, optional: true },
  costMax: { type: Number, optional: true },
  category: { type: String, optional: true },
  subcategory: { type: String, optional: true },
  dressCode: { type: String, optional: true },
  tags: { type: Array, optional: true },
  'tags.$': { type: String, optional: true },
  occurrences: { type: Array, optional: true },
  'occurrences.$': { type: Object, optional: true },
  'occurrences.$.startAt': { type: Date, optional: true },
  'occurrences.$.endAt': { type: Date, optional: true },
  imagePublicId: { type: String, optional: true },
  placeId: { type: String, optional: true },
  tempPlaceName: { type: String, optional: true },
  tempPlaceUrl: { type: String, optional: true },
  tempPlaceAddressStreetAddress: { type: String, optional: true },
  tempPlaceAddressCity: { type: String, optional: true },
  tempPlaceAddressState: { type: String, optional: true },
  tempPlaceAddressZipCode: { type: String, optional: true },
  tempPlaceAddressStreetAddress2: { type: String, optional: true },
  repeats: { type: String, optional: true },
  repeatStopsOn: { type: Date, optional: true },
  organizerName: { type: String, optional: true },
  organizerEmail: { type: String, optional: true },
  organizerPhone: { type: String, optional: true },
  isVirtual: { type: Boolean, optional: true },
  url: { type: String, optional: true },
})

export const SubmittableEventUpdateSchema = new SimpleSchema({
  id: { type: String },
  needTicketOrRsvp: {
    type: String,
    allowedValues: [OPEN_TO_THE_PUBLIC_WALK_INS_WELCOME, BUY_TICKETS_ONLINE, REGISTER_OR_RSVP_ONLINE],
    optional: true,
  },
  ticketOrRsvpUrl: { type: String, optional: true },
  eventType: { type: String, optional: true },
  submitterOwnsEvent: { type: Boolean, optional: true },
  startAt: { type: Date, optional: true },
  endAt: { type: Date, optional: true },
  // primaryCommunityId: { type: String, optional: true },
  title: { type: String, optional: true },
  description: { type: String, optional: true },
  costTier: { type: String, allowedValues: [FREE_EVENT, ONE_FLAT_PRICE, MULTIPLE_PRICING_TIERS], optional: true },
  costMin: { type: Number, optional: true },
  costMax: { type: Number, optional: true },
  category: { type: String, optional: true },
  subcategory: { type: String, optional: true },
  dressCode: { type: String, optional: true },
  tags: { type: Array, optional: true },
  'tags.$': { type: String, optional: true },
  imagePublicId: { type: String, optional: true },
  placeId: { type: String, optional: true },
  tempPlaceName: { type: String, optional: true },
  tempPlaceUrl: { type: String, optional: true },
  tempPlaceAddressStreetAddress: { type: String, optional: true },
  tempPlaceAddressCity: { type: String, optional: true },
  tempPlaceAddressState: { type: String, optional: true },
  tempPlaceAddressZipCode: { type: String, optional: true },
  tempPlaceAddressStreetAddress2: { type: String, optional: true },
  // repeats: { type: String, optional: true },
  repeatStopsOn: { type: Date, optional: true },
  organizerName: { type: String, optional: true },
  organizerEmail: { type: String, optional: true },
  organizerPhone: { type: String, optional: true },
  isVirtual: { type: Boolean, optional: true },
  url: { type: String, optional: true },
  updateAllFollowingEventsInSeries: { type: Boolean, optional: true },
})
