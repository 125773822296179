import dayjs from 'dayjs'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { groupEventsByDay, splitEventsToFullSizeAndTableEvents } from '../../../api/events/helpers'
import { Event } from '../../../models'
import Loading from '../misc/Loading'
import EventsListDay from './EventsListDay'

type Props = {
  events: Event[]
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
}
const ContentFeedEventsListEvents: React.FC<Props> = ({ events, fetchNextPage, hasNextPage }) => {
  if (!events || !events.length) return null

  const eventsGroupedByDay = Object.entries(groupEventsByDay(events))
  return (
    <InfiniteScroll
      dataLength={events.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<Loading className="isCentered isDark" />}
      style={{ overflow: 'hidden' }}
    >
      {eventsGroupedByDay.map(([dayDate, dailyEvents]) => {
        const { fullSizeEvents, tableEvents } = splitEventsToFullSizeAndTableEvents(dailyEvents)
        const activeDay = dayjs(dayDate).startOf('day')

        return <EventsListDay key={activeDay.toString()} activeDay={activeDay} fullSizeEvents={fullSizeEvents} tableEvents={tableEvents} />
      })}
    </InfiniteScroll>
  )
}

export default ContentFeedEventsListEvents
