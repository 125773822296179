type Props = {
  onClick: () => void
}
const YourRecordInlineDelete: React.FC<Props> = ({ onClick }) => (
  <a
    href="#"
    className="dash-slab-action"
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
  >
    <i className="icon-close2-blue" />
    <span className="dash-slab-action-text">Remove</span>
  </a>
)

export default YourRecordInlineDelete
