import * as React from 'react'
import { Community } from '../../../models'

interface Props {
  communities: Community[]
  blockedCommunities: string[]
  handleToggle: React.ChangeEventHandler<HTMLInputElement>
}

const FilterByCommunity: React.FC<Props> = ({ communities, blockedCommunities, handleToggle }) => {
  if (communities.length < 2) return null

  return (
    <div className="filter-widget">
      <h1 className="widget-heading">Filter By Community</h1>
      {communities.map((community, index) => {
        const idName = `switch${index + 1}`
        const communityId = community.id

        return (
          <dl key={communityId} className="slide-toggle">
            <dt className="onoffswitch">
              <input
                type="checkbox"
                name={communityId}
                className="onoffswitch-checkbox"
                id={idName}
                onChange={handleToggle}
                checked={!blockedCommunities.includes(communityId)}
              />
              <label className="onoffswitch-label" htmlFor={idName} />
            </dt>
            <dd>{community.displayName}</dd>
          </dl>
        )
      })}
    </div>
  )
}

export default FilterByCommunity
