import React, { useCallback } from 'react'
import Flatpickr from 'react-flatpickr'
import { connectField, filterDOMProps } from 'uniforms'

const DatePicker = ({ disabled, id, name, onChange, placeholder, value, enableTime, dateFormat = 'M j, Y', ...props }) => {
  const handleChange = useCallback(values => onChange(values[0]), [onChange])

  return (
    <Flatpickr
      {...filterDOMProps(props)}
      id={id}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      options={{
        dateFormat,
        enableTime,
      }}
      onChange={handleChange}
      value={value}
    />
  )
}

export default connectField(DatePicker)
