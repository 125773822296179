export const secondaryBgColor = '#102638'
export const offWhite = '#eee'

export const borderRadius = '4px'

const baseColors = {
  clRed: '#d95859', // events
  clGreen: '#6CC14C', // places
  clYellow: '#f3b240',
  clPurple: '#c74e78',
  clLightpurple: '#c87fe4',
  clBlue: '#3fb9b5', // stories
  clDarkblue: '#296ba0',
  // clViolet: '#787c9f',
  // clOrange: '#ee6012', // job listings
}

export const colors = {
  ...baseColors,
  primary: baseColors.clGreen,
  secondaryBgColor: '#102638',
  secondaryBgColorTransparent: `rgba(16, 38, 56, 0.8)`, // secondaryBgColor, 0.8 opacity
  highlight: baseColors.clBlue,

  white: '#fff',
  offwhite: '#F4F4F4',
  silver: '#D8D8D8',
  grey: '#808080',
  darkgrey: '#434343',
}
export const baseFontColor = colors.darkgrey
