import gql from 'graphql-tag'
import { useQuery } from '../api/graphql'
import { CommunityLiveState } from '../util/constants'

const IsLiveQuery = gql`
  query IsLive($communitySlug: String!) {
    community(slug: $communitySlug) {
      id
      liveState
      childCommunities {
        nodes {
          id
          liveState
        }
      }
    }
  }
`

const isCommunityLive = (community): boolean =>
  community?.liveState === CommunityLiveState.LIVE ||
  (community?.childCommunities?.nodes || []).some(c => c.liveState === CommunityLiveState.LIVE)

const useIsLiveCommunity = (communitySlug: void | null | string): { isLive: boolean; loading: boolean } => {
  const { data = {}, loading: loadingQuery } = useQuery(IsLiveQuery, { variables: { communitySlug }, skip: !communitySlug })

  if (loadingQuery) return { isLive: false, loading: true }

  const { community } = data
  return { isLive: isCommunityLive(community), loading: false }
}
export default useIsLiveCommunity
