import React, { useContext } from 'react'
import { BaseField } from 'uniforms'
import { CommunitiesContext } from '../../../hooks/CommunitiesContextProvider'
import SelectFromOptions from './SelectFromOptions'

const CommunityField: React.FC = (_props, { uniforms: { model } }) => {
  const communities = useContext(CommunitiesContext)

  return (
    <div className="double-input-row">
      <div className="input-col">
        {!model.isNew ? (
          <>
            <label>Community</label>
            <label className="textValue">{model.community?.displayName}</label>
          </>
        ) : (
          <SelectFromOptions
            name="primaryCommunityId"
            options={communities.map(c => ({ value: c.id, label: c.displayName }))}
            placeholder="Select Community"
            value={model.primaryCommunityId}
          />
        )}
      </div>
    </div>
  )
}

CommunityField.contextTypes = BaseField.contextTypes

export default CommunityField
