import dayjs from 'dayjs'
import React, { useCallback, useContext } from 'react'
import Story from '../../api/stories/model'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import useStoryFormData from '../../hooks/useStoryFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import StoryNew from '../components/stories/StoryNew'

const StoryNewContainer: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { data, loading } = useStoryFormData()

  const initStory = useCallback(
    () =>
      new Story({
        publishedAt: dayjs().toDate(),
        primaryCommunityId: currentUser?.primaryCommunity?.id,
      }),
    [currentUser],
  )

  const story = initStory()

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <StoryNew loading={loading} story={story} />
    </CommunitiesContextProvider>
  )
}

export default StoryNewContainer
