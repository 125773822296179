import Select from 'react-select'
import { connectField, filterDOMProps } from 'uniforms'

const SelectTags = ({
  label,
  name,
  tags = [],
  // uniforms fields
  onChange,
  id,
  value,
  inputRef,
  ...props
}) => (
  <section className="input-row" {...filterDOMProps(props)}>
    {label && <label htmlFor={id}>{label}</label>}

    <Select.Creatable
      name={name}
      placeholder="Type or select from the list..."
      options={tags.filter(Boolean).map(tag => ({ value: tag, label: tag }))}
      // default values
      complete
      multi
      matchProps="any"
      matchPos="any"
      ignoreCase
      joinValues
      simpleValue={false}
      clearableValue
      // uniform fields
      id={id}
      ref={inputRef}
      value={value}
      onChange={v => onChange(v.map(opt => opt.value))}
    />
  </section>
)

export default connectField(SelectTags)
