import get from 'lodash/get'
import set from 'lodash/set'
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import toastr from 'toastr'
import { AutoForm } from 'uniforms-unstyled'
import { prepareToSubmit } from '../../../api/places/helpers'
import useOnImageUploadStateChange from '../../../hooks/useOnImageUploadStateChange'
import useRecordSubmissionMutation from '../../../hooks/useRecordSubmissionMutation'
import { PlaceFormSchema } from '../../../schemas/places/placeSchema'
import Routes from '../../../startup/routes'
import { PublishState } from '../../../util/constants'
import UniformsButton from '../form-fields/uniform-fields/UniformsButton'
import Loading from '../misc/Loading'
import PlaceFields from './PlaceFields'

type Props = {
  action
  mutationQuery
  loading
  place
}
const PlaceFormComponent: React.FC<Props> = ({ action, mutationQuery, loading, place: originalPlace }) => {
  const history = useHistory()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  // const [contactName, setContactName] = useState();
  // const [contactEmail, setContactEmail] = useState();

  const formRef = useRef(null)

  const placeMedia = get(originalPlace, 'media', [])
  if (placeMedia.length !== 6) {
    const emptyMediaObjects = [{}, {}, {}, {}, {}, {}]
    const newPlaceMedia = [...placeMedia, ...emptyMediaObjects]
    // remove all elements from 7th forwards
    newPlaceMedia.splice(6)
    set(originalPlace, 'media', newPlaceMedia)
  }

  const navigate = useCallback(
    place => {
      place.isPremium ? history.push(Routes.premiumPlace(place, place.community)) : history.push(Routes.place(place, place.community))
    },
    [history],
  )

  const beforeSubmit = useCallback(doc => prepareToSubmit(doc), [])

  const onSuccess = useCallback(
    place => {
      if (action === 'insert' || place.publishState !== PublishState.APPROVED) {
        history.push(Routes.yourPlaces())
      } else {
        navigate(place)
      }

      if (action === 'insert') {
        toastr.success(`
          Thank you!<br/><br/>
          Your place has been submitted.<br/>
        `)
      } else {
        toastr.success('Place updated!')
      }
    },
    [action, history, navigate],
  )

  const {
    handleSubmit,
    mutationLoading: recordUpdateMutationLoading,
    onValidate,
  } = useRecordSubmissionMutation({
    query: mutationQuery,
    queryName: action === 'insert' ? 'placeCreate' : 'placeUpdate',
    recordName: 'place',
    beforeSubmit,
    onSuccess,
  })

  const onImageUploadStateChange = useOnImageUploadStateChange(setIsSubmitDisabled)

  // const updateSubmitterOwnsPlace = useCallback(
  //   (key, value) => {
  //     if (key !== 'submitterOwnsPlace') {
  //       return;
  //     }
  //
  //     if (value) {
  //       const model = formRef.current.getModel();
  //       setContactName(get(model, 'contact.name'));
  //       setContactEmail(get(model, 'contact.email'));
  //       formRef.current.change('contact.name', get(currentUser, 'fullName'));
  //       formRef.current.change('contact.email', get(currentUser, 'email'));
  //     } else {
  //       formRef.current.change('contact.name', contactName);
  //       formRef.current.change('contact.email', contactEmail);
  //     }
  //   },
  //   [currentUser, contactEmail, contactName, formRef, setContactName, setContactEmail],
  // );

  if (loading) {
    return <Loading className="isDark" />
  }

  return (
    <div>
      <h1 className="content-form-heading">{`${originalPlace && !originalPlace.isNew ? 'Edit' : 'Add'} Place`}</h1>

      <AutoForm
        className="form content-entry-form place-form"
        model={originalPlace}
        onSubmit={handleSubmit}
        onValidate={onValidate}
        ref={formRef}
        schema={PlaceFormSchema}
      >
        <PlaceFields onUploadingStateChange={onImageUploadStateChange} />

        <fieldset className="submit-row">
          <UniformsButton
            disabled={recordUpdateMutationLoading || isSubmitDisabled}
            shouldShowLoadingSpinner={recordUpdateMutationLoading}
            text="Submit Changes"
          />
        </fieldset>
      </AutoForm>
    </div>
  )
}

export default PlaceFormComponent
