import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ErrorMessage, useField } from 'formik'
import React, { useCallback, useState } from 'react'
import toastr from 'toastr'
import cloudinaryTools, { CloudinaryUploadFolders } from '../../../util/cloudinaryTools'
import { UploadState, UploadStateType } from '../../../util/constants'
import FileDropBox from '../misc/FileDropBox'
import ResponsiveImage, { VideoTag } from '../misc/MediaPresets'

interface MediaUploadFieldProps {
  className?: string
  label?: string
  name: string
  folder: CloudinaryUploadFolders
  onUploadingStateChange?(...args: unknown[]): unknown
  allowVideo: boolean
}

const MediaUploadField: React.FC<MediaUploadFieldProps> = props => {
  const { allowVideo = true, folder = 'uploads', label, name, onUploadingStateChange } = props
  const [uploadState, setUploadState] = useState<UploadStateType>(UploadState.IDLE)

  // skip meta field, we don't need it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _meta, helpers] = useField(name)
  const value = field.value
  const onChange = helpers.setValue

  const handleRemoveMedia = useCallback(() => {
    // Cloudinary.delete(value.publicId, (err, res) => {});
    // don't wait until Cloudinary API removes the media

    if (allowVideo) {
      onChange({ publicId: '', type: '' })
    } else {
      onChange({ publicId: '' })
    }

    setUploadState(UploadState.IDLE)
  }, [allowVideo, onChange, setUploadState])

  const uploadMediaToCloudinary = useCallback(
    async (file: File) => {
      if (file == null) return

      const fileIsVideo = file.type.indexOf('image') !== 0
      if (fileIsVideo && !allowVideo) {
        toastr.error('Please select valid image file.')
        return
      }

      let newUploadState: UploadStateType = UploadState.UPLOADING
      setUploadState(newUploadState)

      onUploadingStateChange && onUploadingStateChange(newUploadState)

      const options = { folder, resource_type: '' }
      if (fileIsVideo) options.resource_type = 'video'

      await cloudinaryTools.upload(file, options, (error, response) => {
        newUploadState = error ? UploadState.ERROR : UploadState.SUCCESS
        setUploadState(newUploadState)

        onUploadingStateChange && onUploadingStateChange(newUploadState)

        if (error) {
          toastr.error(error)

          allowVideo ? onChange({ publicId: '', type: '' }) : onChange({ publicId: '' })
        } else {
          if (allowVideo) {
            onChange({ publicId: response.public_id, type: fileIsVideo ? 'video' : 'image' })
          } else {
            onChange({ publicId: response.public_id })
          }
        }
      })
    },
    [allowVideo, folder, onChange, onUploadingStateChange],
  )

  const { publicId, type } = value

  const className = props.className ? `${props.className} file-upload` : 'file-upload'

  return (
    <StyledUploadBox
      className={className}
      onDraggingStyle={css`
        background-color: white;
      `}
      onDropped={uploadMediaToCloudinary}
    >
      {publicId ? (
        <UploadedMediaPreview onRemove={handleRemoveMedia} type={type} publicId={publicId} />
      ) : (
        <StyledUploadBoxLabel htmlFor={`imgInp-${name}`}>
          <div>{label}</div>
          <FileSelect id={`imgInp-${name}`} uploadState={uploadState} onFileSelected={uploadMediaToCloudinary} />
        </StyledUploadBoxLabel>
      )}
      <ErrorMessage name={name} />
    </StyledUploadBox>
  )
}

const StyledUploadBox = styled(FileDropBox)`
  height: 100%;
  width: 100%;
  & > * {
    padding: 15px;
  }
  border-radius: 5px;
  border: 1px dashed #959595;
`

const UploadedMediaPreview: React.FC<{ onRemove?: () => void; type: string; publicId: string }> = props => {
  const { type, publicId, onRemove } = props
  return (
    <MediaPreviewBox className="media-preview">
      {type === 'video' ? <VideoTag publicId={publicId} /> : <ResponsiveImage publicId={publicId} />}
      <RemoveMediaButton onClick={onRemove} />
    </MediaPreviewBox>
  )
}

const MediaPreviewBox = styled.div`
  position: relative;
  height: 100%;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`

const RemoveMediaButton: React.FC<{ onClick?: (e) => void }> = props => (
  <TopRightButton type="button" onClick={props.onClick}>
    <RedXIcon className="icon-remove2" />
  </TopRightButton>
)

const StyledUploadBoxLabel = styled.label`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FileSelect: React.FC<{ onFileSelected: (file: File) => void; uploadState: UploadStateType; id: string }> = props => {
  return (
    <StyledFileSelect>
      <i
        className="icon-upload"
        css={css`
          margin: 10px;
        `}
      />
      <input
        type="file"
        id={props.id}
        onChange={e => {
          const file = e.currentTarget.files ? e.currentTarget.files[0] : null
          props.onFileSelected(file)
        }}
      />
      <UploadStateMessage uploadState={props.uploadState} />
    </StyledFileSelect>
  )
}

const StyledFileSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  .upload-status {
    font-weight: normal;
  }
  input[type='file'] {
    display: none;
  }
`

const UploadStateMessage: React.FC<{ uploadState: string }> = ({ uploadState }) => {
  switch (uploadState) {
    case UploadState.UPLOADING:
      return <div className="upload-status">Uploading...</div>

    case UploadState.SUCCESS:
      return <div className="upload-status success">Uploaded</div>

    case UploadState.ERROR:
      return <div className="upload-status error">Upload error</div>

    default:
      return <div className="upload-status">Drag and drop or upload from your computer.</div>
  }
}

// make it easier to hit with the finger with 30x30px
const TopRightButton = styled.button`
  margin-right: 0;
  margin-top: 0;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
`

const RedXIcon = styled.i`
  margin: 0;
  background-color: white;
  color: #d95859;
  font-size: 16px;
`

export default MediaUploadField
