import { applyMixins } from '../../util/mixins'
import BaseModel from '../model'
import { Media, Publishable } from '../types'

class Story extends BaseModel {
  public readonly objectType = 'story'
  public readonly __typename = 'Story'

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor(doc) {
    super()
    const id = doc.id || doc._id
    Object.assign(this, { ...doc, _id: id, id })

    const { publishedAt } = doc

    if (typeof publishedAt === 'string') {
      this.publishedAt = new Date(publishedAt)
    }
  }

  public slug?: string
  public title?: string
  public publishedAt?: Date
  public category?: string
  public excerpt?: string

  public image?: Media
  public readingTime?: string
  public content?: string
  public isFeatured: boolean
}

export default Story

interface Story extends Publishable {} // eslint-disable-line @typescript-eslint/no-empty-interface
applyMixins(Story, [Publishable])
