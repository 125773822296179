import gql from 'graphql-tag'
import { useContext } from 'react'
import { useQuery } from '../api/graphql'
import { CurrentUserContext } from '../providers/CurrentUserContext'

export const PlaceFormQuery = gql`
  query PlaceFormQuery {
    currentUser {
      id
    }
    communities {
      id
      displayName
    }
  }
`

type Data = {
  currentUser: { id: string }
  communities: { id: string; displayName: string }[]
}
const usePlaceFormData = (): { data: Data; loading: boolean } => {
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)

  const { data, loading } = useQuery(PlaceFormQuery, { skip: loadingCurrentUser })
  return { data, loading: loading || loadingCurrentUser }
}

export default usePlaceFormData
