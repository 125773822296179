import isPropValid from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../../../startup/routes'
import { baseFontColor, colors } from '../../../../styles/variables'
import { YourEventsTabScope } from '../../../../util/constants'

const StyledTabLink = styled(Link, { shouldForwardProp: isPropValid })<{ $isActive: boolean }>`
  display: inline-block;
  padding: 10px 14px;
  border: 1px solid ${colors.silver};
  border-radius: 10px 10px 0 0;
  color: ${baseFontColor};
  ${props =>
    props.$isActive &&
    css`
      border-color: ${colors.grey};
      border-bottom-color: ${colors.offwhite};
      font-weight: 700;
      color: ${colors.secondaryBgColor};
    `}
`
type Props = {
  activeTabScope: string
}
const YourEventsTabHeader: React.FC<Props> = ({ activeTabScope }) => {
  const isActive = (tabScope: string) => tabScope === activeTabScope

  return (
    <div
      css={css`
        margin-left: 30px;
        margin-bottom: -1px;
      `}
    >
      <StyledTabLink to={Routes.yourEvents()} $isActive={isActive(YourEventsTabScope.UPCOMING)}>
        Upcoming
      </StyledTabLink>
      <StyledTabLink to={Routes.yourPastEvents()} $isActive={isActive(YourEventsTabScope.PAST)}>
        Past Events
      </StyledTabLink>
    </div>
  )
}
export default YourEventsTabHeader
