import gql from 'graphql-tag'
import * as React from 'react'

import { useQuery } from '../../api/graphql'
import useCommunityIdOrSlug from '../../hooks/useCommunityIdOrSlug'
import { LocalStorage } from '../../util/window'
import SignUp from '../components/users/SignUp'

const SignUpQuery = gql`
  query SignUp {
    communities {
      id
      slug
      displayName
      name
      termsAndConditionsUrl
    }
  }
`

const SignUpContainer: React.FC = () => {
  const idOrName = useCommunityIdOrSlug()
  const communitySlug = idOrName || LocalStorage.getItem('customDomainOrigin')

  const { data: { communities } = { communities: [] }, loading } = useQuery(SignUpQuery)

  let selectedCommunityId
  if (communitySlug) {
    const selectedCommunity = communities.find(c => c.slug.toLowerCase() === communitySlug.toLowerCase())
    if (selectedCommunity) {
      selectedCommunityId = selectedCommunity.id
    }
  }

  return <SignUp loading={loading} communities={communities} selectedCommunityId={selectedCommunityId} />
}

export default SignUpContainer
