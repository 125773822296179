import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useCallback, useRef, useState } from 'react'
import { BaseField, joinName } from 'uniforms'
import { SelectField } from 'uniforms-unstyled'
import { gap } from '../../../styles/vars'
import { formatDayAndDate, formatTime } from '../../../util/dateTime'
import DatePicker from '../form-fields/DatePicker'
import { Icon } from '../misc/Icon'
import { IconName } from '../misc/IconName'
import { StyledLabel } from './EventFields'

const StyledOccurrences = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  max-width: 324px;

  font-size: 12px;
  @media (min-width: 500px) {
    font-size: 13px;
  }
`

const OccurrencesDates = styled.div`
  flex: 115px;
`
const OccurrenceTime = styled.div`
  flex: 110px;
`

const OccurrenceActions = styled.div`
  flex: 55px;
  white-space: nowrap;
`

const StyledInlineIcon = styled(Icon)`
  display: inline-block;
  cursor: pointer;
  padding: 4px;
  width: 22px;
  height: 22px;
  color: #aaa;
  transition: color 0.3s;

  &:hover {
    color: #666;
  }
`

const StyledSummaryFields = styled.div`
  display: flex;
  align-items: center;

  && input {
    height: 36px;
    min-width: 57px;
    max-width: 110px;
  }

  max-width: 360px;
`

const StyledDatePicker = styled(DatePicker)`
  flex: 120px;
  padding: 5px 5px !important;
  white-space: nowrap;

  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    appearance: none;
  }
  &::-webkit-date-and-time-value {
    margin: 0;
  }
  &.flatpickr-mobile {
    line-height: 24px;
  }

  @media (min-width: 500px) {
    margin-right: 15px;
  }
`
const StyledTimeInput = styled(SelectField)`
  flex: 80px;

  && select {
    padding-right: 20px;
    padding-left: 8px;
    height: 36px;
    line-height: 24px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23808080' width='18px' height='9px' viewBox='0 0 20 9' xmlns='http://www.w3.org/2000/svg'><g><polygon points='1.4 0.8 6 5.4 10.6 0.8 12 2.2 6 8.2 0 2.2'/></g></svg>");
  }
`

const StyledSummaryButton = styled.button`
  font-size: 12px !important;
  align-self: center;
  margin-left: 8px;
  height: 24px;
  padding: 0 4px !important;
`

const NOT_EDITING = -1
const OccurrencesBox = styled.div`
  font-size: 13px;
  margin: ${gap} -${gap} 0;
  width: calc(100% + 2 * ${gap});
  padding: ${gap};
  border-top: 1px solid #ccc;
`

const InputText = styled.div`
  padding-bottom: ${gap};
`

const Occurrences = styled.div<{ $isExpanded: boolean }>`
  @media (min-width: 500px) {
    margin-left: ${gap};
  }

  transition: max-height 0.3s;
  overflow: hidden;
  ${props => (props.$isExpanded ? `max-height: 2400px;` : `max-height: 230px;`)}
`

const ShowMore = styled.div<{ $isExpanded: boolean }>`
  ${props => !props.$isExpanded && `box-shadow: 0px -8px 10px -10px #aaa;`}
  padding: ${gap} ${gap} 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

const SummaryOccurrences: React.FC = (_props, { uniforms }) => {
  const [editingIndex, setEditingIndex] = useState<number>(NOT_EDITING)
  const { model } = uniforms
  const { occurrences } = model

  if (!occurrences) {
    return null
  }

  return (
    <>
      {occurrences.map(({ startAtDate, endAtTime }, index) =>
        editingIndex === index ? (
          <StyledSummaryFields key={editingIndex}>
            <StyledDatePicker id="startAt" name={joinName('occurrences', editingIndex, 'startAtDate')} />
            <StyledTimeInput name={joinName('occurrences', editingIndex, 'startAtTime')} />
            <StyledTimeInput name={joinName('occurrences', editingIndex, 'endAtTime')} />
            <StyledSummaryButton className="btn" onClick={() => setEditingIndex(NOT_EDITING)}>
              Done
            </StyledSummaryButton>
          </StyledSummaryFields>
        ) : (
          <StyledOccurrences key={index}>
            <OccurrencesDates>{formatDayAndDate(startAtDate)}</OccurrencesDates>
            <OccurrenceTime>
              {formatTime(startAtDate)} {endAtTime && ` - ${endAtTime}`}
            </OccurrenceTime>
            <OccurrenceActions>
              <StyledInlineIcon name={IconName.edit} onClick={() => setEditingIndex(index)} />
              <StyledInlineIcon
                name={IconName.remove2}
                onClick={() => {
                  setEditingIndex(NOT_EDITING)
                  const newOccurrences = [...occurrences]
                  newOccurrences.splice(index, 1)
                  uniforms.onChange('occurrences', newOccurrences)
                }}
                css={css`
                  margin-left: 10px;
                `}
              />
            </OccurrenceActions>
          </StyledOccurrences>
        ),
      )}
    </>
  )
}
SummaryOccurrences.contextTypes = BaseField.contextTypes

const EventOccurrences: React.FC = (
  _props,
  {
    uniforms: {
      model: { occurrences },
    },
  },
) => {
  const occurrencesBoxRef = useRef<HTMLInputElement>()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const toggleIsExpanded = useCallback(() => {
    if (isExpanded && occurrencesBoxRef.current) {
      occurrencesBoxRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  if (!occurrences || occurrences.length === 0) {
    return null
  }

  return (
    <OccurrencesBox ref={occurrencesBoxRef}>
      <StyledLabel>
        Your selections have produced <u>{occurrences.length} total occurrences</u>
      </StyledLabel>
      <InputText>
        Review the occurrences your selections have produced. You can edit or remove individual occurrences in the list below or on the next
        page after submitting the event.
      </InputText>
      <Occurrences $isExpanded={isExpanded || occurrences.length <= 10}>
        <SummaryOccurrences />
      </Occurrences>
      {occurrences.length > 10 && (
        <ShowMore $isExpanded={isExpanded} onClick={toggleIsExpanded}>
          show {isExpanded ? 'less' : 'more'}
        </ShowMore>
      )}
    </OccurrencesBox>
  )
}
EventOccurrences.contextTypes = BaseField.contextTypes

export default EventOccurrences
