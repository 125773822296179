import SimpleSchema from 'simpl-schema'

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      regEx({ label, regExp }) {
        switch (regExp) {
          case SimpleSchema.RegEx.Url.toString():
            return 'URL must start with http:// or https:// like: https://example.com'
          case SimpleSchema.RegEx.Email.toString():
            return `${label} must be a valid e-mail address`
          default:
            return `${label} is invalid`
        }
      },
      passwordMismatch: 'Passwords do not match',
      acceptTerms: 'You must read and accept Terms and Conditions before continuing',
      placeRequired: 'Please select a Place',
      tempPlaceRequired: "Please enter details for your event's venue",
      ongoingLastsUntilRequired: 'Last Date is required',
      phoneNumberFormat: 'Please enter full phone number',
      eventUrlRequiredForVirtualEvent: 'Event URL is required',
      eventLinkRequiredForTicketedEvent: 'Link for tickets / registration is required',
      selectRecurrencePattern: 'Select Recurrence Pattern',
      startAtMustBeBeforeEndAt: 'Event end time cannot be before event start time',
      startAtMustBeBeforeRepeatStopsOn: 'Event end date cannot be before event start date',
      startMustBeInTheFuture: 'Event start cannot be in the past',
    },
  },
})

SimpleSchema.RegEx.Url = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export default SimpleSchema
