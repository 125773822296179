import gql from 'graphql-tag'
import * as React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '../../api/graphql'
import { eventFragment } from '../../graphql/fragments'
import ContentFeedEventView from '../components/events/ContentFeedEventView'

const EventViewQuery = gql`
  query EventView($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...eventFragment
    }
  }
  ${eventFragment}
`

const ContentFeedEventViewContainer: React.FC = () => {
  const { id: eventSlug } = useParams<{ id: string }>()

  const { loading, data: { event = null } = {} } = useQuery(EventViewQuery, { variables: { eventSlug }, skip: !eventSlug })

  return <ContentFeedEventView loading={loading} event={event} />
}

export default ContentFeedEventViewContainer
