import dayjs from 'dayjs'
import React from 'react'
import EventsListDayHeader from './EventsListDayHeader'
import EventsListItemPlaceholder from './EventsListItemPlaceholder'

const EventsListPlaceholder: React.FC = () => {
  return (
    <div className="events-day">
      <EventsListDayHeader eventDate={dayjs().startOf('day')} />
      <EventsListItemPlaceholder />
      <EventsListItemPlaceholder />
      <EventsListItemPlaceholder />
      <EventsListItemPlaceholder />
      <EventsListItemPlaceholder />
    </div>
  )
}

export default EventsListPlaceholder
