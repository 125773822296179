import gql from 'graphql-tag'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '../../api/graphql'
import Place from '../../api/places/model'
import { placeFragment } from '../../graphql/fragments'
import CommunitiesContextProvider from '../../hooks/CommunitiesContextProvider'
import usePlaceFormData from '../../hooks/usePlaceFormData'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import PlaceFormComponent from '../components/places/PlaceFormComponent'

const PlaceEditQuery = gql`
  query PlaceEditQuery($slug: String!) {
    place(slug: $slug) {
      ...placeFragment
    }
  }
  ${placeFragment}
`

const PLACE_EDIT = gql`
  mutation PlaceUpdate($input: PlaceUpdateInput!) {
    placeUpdate(input: $input) {
      place {
        ...placeFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${placeFragment}
`

const PlaceEditContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const { data, loading } = usePlaceFormData()

  const { data: placeData, loading: loadingPlace } = useQuery(PlaceEditQuery, { variables: { slug: id }, skip: loadingCurrentUser })

  // {...place} is needed to convert Place to JS object w/o functions
  // for example `omitBy(isNil)` assigned place.fullAddress as a value
  const place = new Place(omitBy({ ...placeData?.place }, isNil))
  place.contact = omitBy(get(place, 'contact'), isNil)
  place.address = omitBy(get(place, 'address'), isNil)
  place.socialUrls = omitBy(get(place, 'socialUrls'), isNil)
  place.primaryCommunityId = place.community?.id

  const isAnythingLoading = loading || loadingCurrentUser || loadingPlace

  return (
    <CommunitiesContextProvider value={data?.communities}>
      <PlaceFormComponent
        action="update"
        loading={isAnythingLoading}
        mutationQuery={PLACE_EDIT}
        place={isAnythingLoading ? undefined : place}
      />
    </CommunitiesContextProvider>
  )
}

export default PlaceEditContainer
