import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Geosuggest from 'react-geosuggest'
import GoogleMapsLoader from 'react-google-maps-loader'
import { connectField } from 'uniforms'
import { extractAddressComponents, joinAddressComponents } from '../../../api/geocoding/geocoding'

class GeocodeAddressFields extends React.Component {
  constructor(props) {
    super(props)
    this.handleSuggestSelect = this.handleSuggestSelect.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      isGeosuggestExpanded: false,
    }
  }

  handleSuggestSelect(value) {
    this.setState({ isGeosuggestExpanded: false })
    const components = value.gmaps.address_components
    const extracted = extractAddressComponents(components)
    this.props.onChange(
      {
        streetAddress: extracted.street,
        city: extracted.city,
        state: extracted.state,
        zipCode: extracted.zip,
      },
      `${this.props.name}`,
    )
  }

  onBlur() {
    this.setState({ isGeosuggestExpanded: false })
  }

  onChange() {
    this.setState({ isGeosuggestExpanded: true })
  }

  render() {
    let address
    if (!isEmpty(this.props.address)) {
      address = joinAddressComponents(this.props.address)
    }

    return (
      <section className={`address ${this.props.className}`}>
        <label htmlFor={this.props.id}>Address</label>
        <Geosuggest
          id={this.props.id}
          className={this.state.isGeosuggestExpanded ? '' : 'is-suggestsHidden'}
          initialValue={address}
          googleMaps={this.props.googleMaps}
          placeholder="Search places"
          onBlur={this.onBlur}
          onChange={this.onChange}
          onSuggestSelect={this.handleSuggestSelect}
          autocomplete="address-search"
        />
      </section>
    )
  }
}

GeocodeAddressFields.defaultProps = {
  className: 'input-row',
}

// If you really want a HOC for some reason, you can easily
// create one using a regular component with a render prop!
function withGoogleMapsLoader(Component) {
  return class GeocodeAddressFieldsWithGoogleMapsLoader extends React.Component {
    render() {
      return (
        <GoogleMapsLoader
          params={{
            libraries: 'places',
            key: process.env.GOOGLE_MAP_API_KEY,
          }}
          render={googleMaps => googleMaps && <Component {...this.props} googleMaps={googleMaps} />}
        />
      )
    }
  }
}

export default connectField(withGoogleMapsLoader(GeocodeAddressFields))
