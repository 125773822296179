import { useEffect, useRef, useState } from 'react'
import { BaseField, connectField, filterDOMProps } from 'uniforms'

const preventScrollingFromChangingValue = e => e.preventDefault()
const formatValue = (val?: number) => {
  if (val == null) return ''

  const amountInCents = Math.round(val * 100)
  if (amountInCents % 100 === 0) return (amountInCents / 100).toFixed(0)
  return (amountInCents / 100).toFixed(2)
}

const MoneyField = ({ name, onChange, value, className, label, ...props }) => {
  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef(null)
  useEffect(() => {
    const inputRefCurrent = inputRef.current
    inputRefCurrent?.addEventListener('focus', () => setIsFocused(true))
    inputRefCurrent?.addEventListener('blur', () => setIsFocused(false))
    inputRefCurrent?.addEventListener('wheel', preventScrollingFromChangingValue, { passive: false })
    return () => {
      inputRefCurrent?.removeEventListener('focus', () => setIsFocused(true))
      inputRefCurrent?.removeEventListener('blur', () => setIsFocused(false))
      inputRefCurrent?.removeEventListener('wheel', preventScrollingFromChangingValue, { passive: false })
    }
  }, [inputRef])

  const handleChange = event => {
    const numericValue = parseFloat(event.target.value)
    isNaN(numericValue) ? onChange(undefined) : onChange(numericValue)
  }

  return (
    <div className={className}>
      <label htmlFor={props.id}>{label}</label>
      <input
        {...filterDOMProps(props)}
        type="number"
        min="0"
        name={name}
        value={isFocused ? value : formatValue(value)}
        onChange={handleChange}
        ref={inputRef}
      />
    </div>
  )
}

MoneyField.contextTypes = BaseField.contextTypes

export default connectField(MoneyField)
