import React from 'react'
import CivicLiftLogo from './CivicLiftLogo'
import PublicNav from './PublicNav'

const PublicHeader: React.FC = () => (
  <header className="public-header">
    <div className="public-header-wrap">
      <div className="public-header-content">
        <CivicLiftLogo />
        <PublicNav />
      </div>
    </div>
  </header>
)

export default PublicHeader
