import React from 'react'
import { BaseField } from 'uniforms'
import Loading from '../../misc/Loading'

type Props = {
  className?: string
  disabled?: boolean
  shouldShowLoadingSpinner?: boolean
  onClick?: () => void
  text: string
  type?: 'submit' | 'button'
}
const UniformsButton: React.FC<Props> = (
  { className = '', disabled, shouldShowLoadingSpinner = false, onClick, text, type = 'submit' },
  { uniforms: { error } = {} },
) => (
  <button
    className={`btn ${className} ${shouldShowLoadingSpinner ? 'isLoading' : ''}`}
    /* The button is always disabled when showing loading spinner */
    disabled={shouldShowLoadingSpinner || (disabled !== undefined ? disabled : error)}
    onClick={onClick}
    type={type}
  >
    {text}
    {shouldShowLoadingSpinner ? <Loading /> : ''}
  </button>
)

UniformsButton.contextTypes = BaseField.contextTypes

export default UniformsButton
