// this function allows us to extend/spread one class in another
// https://www.typescriptlang.org/docs/handbook/mixins.html#alternative-pattern
// class A {
//   public x;
//
//   public printX() {
//     console.log(x)
//   }
// }
//
// class B {}
//
// applyMixins(B, [A])
//
// const b = new B();
// b.x = 15
// b.printX() // 15
//
// Usage
// interface Event extends EventStartEndDateTime {} // eslint-disable-line @typescript-eslint/no-empty-interface
// applyMixins(Event, [EventStartEndDateTime]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const applyMixins = (derivedCtor: any, constructors: any[]): void => {
  constructors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      if (name === 'constructor') {
        return
      }
      Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null))
    })
  })
}
