import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import { Community } from '../../models'
import { SubmittableEventCreateSchema, SubmittableEventUpdateSchema } from '../../schemas/events/eventSchema'
import {
  EventTypes,
  RepeatingPatterns,
  RepeatingPatternsType,
  Weekdays,
  WeekdaysType,
  repeatingPatternIsCustom,
} from '../../util/constants'
import { dayOfTheWeek, formatDate, formatTime } from '../../util/dateTime'
import EventSeries from './EventSeries'
import EventStartEndDateTime from './EventStartEndDateTime'
import { Event } from './model'

const ORDINAL_NUMBERS = ['first', 'second', 'third', 'fourth', 'fifth']
export const ordinalWeekOfMonth = (date: Date): string => {
  const dayOfMonth = parseInt(dayjs(date).format('D'))
  return ORDINAL_NUMBERS[Math.floor((dayOfMonth - 1) / 7)]
}

const dayOfMonth = (date: Date): string => dayjs(date).format('Do')
type RepeatDays = { [key in WeekdaysType]: boolean }

export const initRepeatDays = (repeatPattern: RepeatingPatternsType, startAt: Date): RepeatDays | null => {
  if (repeatPattern !== RepeatingPatterns.WEEKLY) {
    return null
  }
  const repeatDays: Partial<RepeatDays> = {}
  Object.values(Weekdays).forEach(day => (repeatDays[day] = false))
  repeatDays[dayOfTheWeek(startAt) as WeekdaysType] = true
  return repeatDays as RepeatDays
}

export const eventsSummary = (event: Event): string | null => {
  if (!event.startAt || !event.eventType) {
    return null
  }

  if (event.eventType === EventTypes.SINGLE_EVENT) {
    if (!event.endAt) {
      // The event is at 12:00pm on September 30, 2021.
      return `The event is at ${formatTime(event.startAt)} on ${formatDate(event.startAt)}.`
    } else {
      // The event is from 12:00pm to 4:00pm on September 30, 2021.
      const isSameDay = dayjs(event.startAt).isSame(event.endAt, 'day')

      if (isSameDay) {
        return `The event is from ${formatTime(event.startAt)} to ${formatTime(event.endAt)} on ${formatDate(event.startAt)}.`
      } else {
        return `The event is from ${formatTime(event.startAt)} ${formatDate(event.startAt)} to ${formatTime(event.endAt)} ${formatDate(
          event.endAt,
        )}.`
      }
    }
  }

  if (repeatingPatternIsCustom(event.repeats)) {
    return null
  }

  let summary
  switch (event.repeats) {
    case RepeatingPatterns.DAILY:
    case RepeatingPatterns.REQUEST_SCHEDULE:
      // An event every day that begins on September 30, 2021 at 12:00pm, repeating until October 2, 2021
      summary = `An event every day that begins on ${formatDate(event.startAt)} at ${formatTime(event.startAt)}`
      break
    case RepeatingPatterns.WEEKLY:
      // An event every Tuesday and Wednesday that begins at 9:00am on August 10th, 2021, repeating until September 7, 2021
      const daysOfTheWeek = Object.entries(event.repeatDays || {})
        .filter(([_key, value]) => value)
        .map(([key, _value]) => key)
      let daysOfTheWeekJoined
      if (daysOfTheWeek.length === 0) {
        daysOfTheWeekJoined = dayOfTheWeek(event.startAt)
      } else {
        daysOfTheWeekJoined = daysOfTheWeek.reduce(
          // [1, 2, 3, 4, 5]
          // => "1, 2, 3, 4 and 5"
          (sentence, current, currentIdx, days) => sentence + (currentIdx < days.length - 1 ? ', ' : ' and ') + current,
        )
      }
      summary = `An event every ${daysOfTheWeekJoined} that begins at ${formatTime(event.startAt)} on ${formatDate(event.startAt)}`
      break
    case RepeatingPatterns.MONTHLY_SAME_DATE:
      // An event every 12th of the month, repeating until January 14, 2022
      summary = `An event every ${dayOfMonth(event.startAt)} of the month`
      break
    case RepeatingPatterns.MONTHLY_NTH_DOW:
      // An event every third Wednesday of the month, repeating until January 14, 2022
      summary = `An event every ${ordinalWeekOfMonth(event.startAt)} ${dayOfTheWeek(event.startAt)} of the month`
      break
    default:
      return 'Custom schedule'
  }

  if (event.repeatStopsOn) {
    summary += `, repeating until ${formatDate(event.repeatStopsOn)}`
  }
  return summary
}

export const eventDates = (event: Event): EventStartEndDateTime[] => {
  const result: EventStartEndDateTime[] = []

  const nextStartAtGenerator = new EventSeries({ repeats: event.repeats, startAt: event.startAt })
  const nextEndAtGenerator = new EventSeries({ repeats: event.repeats, startAt: event.endAt })

  let currentStartAt = event.startAt
  let currentEndAt = event.endAt
  for (let i = 0; i < EventSeries.MAX_EVENTS_IN_SERIES; i++) {
    result.push(new EventStartEndDateTime({ startAt: currentStartAt, endAt: currentEndAt }))
    currentStartAt = nextStartAtGenerator.next(currentStartAt)
    currentEndAt = nextEndAtGenerator.next(currentEndAt)
    if (event.repeatStopsOn && dayjs(currentStartAt).startOf('day').isAfter(event.repeatStopsOn)) {
      break
    }
  }

  return result
}

// This method is used to make sure that endAt is after startAt.
// This happens when user sets endAt time to be before startAt. (e.g. startAt = 10pm, endAt = 2am)
// endAt at 2am is actually supposed to be 2am the next day.
// This can also happen when user changes startAt to a previous date.
const fixEndAtDate = (startEndDateTime: EventStartEndDateTime) => {
  startEndDateTime.endAtDate = startEndDateTime.startAtDate
  if (dayjs(startEndDateTime.endAt).isBefore(dayjs(startEndDateTime.startAt))) {
    startEndDateTime.endAt = dayjs(startEndDateTime.endAt).add(1, 'day').toDate()
  }
}

export const prepareToSubmit = (originalEvent: Event): Event => {
  const event = cloneDeep(originalEvent)

  if (event.eventType === EventTypes.SINGLE_EVENT) {
    ;(['repeats', 'repeatStopsOn'] as const).forEach(field => delete event[field])
  }

  event.flatObjectFields('image')
  event.flatObjectFields('organizer')
  event.flatObjectFields('tempPlace')
  event.flatObjectFields('tempPlaceAddress')

  fixEndAtDate(event)
  event.occurrences && event.occurrences.forEach(fixEndAtDate)

  const serialized = JSON.parse(JSON.stringify(event))
  if (serialized.subcategory === '') serialized.subcategory = null
  if (serialized.repeats === RepeatingPatterns.REQUEST_SCHEDULE) serialized.repeats = RepeatingPatterns.DAILY
  return event.isNew
    ? SubmittableEventCreateSchema.clean(serialized, { removeEmptyStrings: false })
    : SubmittableEventUpdateSchema.clean(serialized, { removeEmptyStrings: false })
}
// [
//   mon1, mon2, mon3, tue1, tue2
// ] => {
//   'September 25, 2021': [mon1, mon2, mon3];
//   'September 26, 2021': [tue1, tue2]
// }
type GroupEventsByDayReturnType = {
  [key: string]: Event[]
}
export const groupEventsByDay = (events: Event[]): GroupEventsByDayReturnType =>
  groupBy(events, (event: Event) => formatDate(event.startAt))

type EventsToFullSizeAndTableEventsReturnType = {
  fullSizeEvents: Event[]
  tableEvents: Event[]
}
export const splitEventsToFullSizeAndTableEvents = (sameDayEvents: Event[]): EventsToFullSizeAndTableEventsReturnType => {
  let fullSizeEvents: Event[] = []
  const tableEvents: Event[] = []
  sameDayEvents.forEach(event => {
    if (!isDailyEvent(event)) return fullSizeEvents.push(event)
    if (isFirstOccurrenceOfDailyEvent(event)) return fullSizeEvents.push(event)
    tableEvents.push(event)
  })
  // move up to first 3 table events to full size events
  if (fullSizeEvents.length === 0) fullSizeEvents = tableEvents.splice(0, 3)
  return { fullSizeEvents, tableEvents }
}

const isDailyEvent = (event: Event): boolean => event.repeats === RepeatingPatterns.DAILY

const isFirstOccurrenceOfDailyEvent = (dailyEvent: Event): boolean => !dailyEvent.day || dailyEvent.day.startsWith('Day 1 of')

export type eventIdToCommunityIndexMapType = {
  [key: string]: number
}
export const mapEventIdToCommunityIndex = (communities: Community[], events: Event[]): eventIdToCommunityIndexMapType => {
  const eventIdToCommunityIndexMap: eventIdToCommunityIndexMapType = {}

  events.forEach(event => {
    const communityIndex = communities.findIndex(c => c.id === event.community.id) + 1
    eventIdToCommunityIndexMap[event.id] = communityIndex
  })

  return eventIdToCommunityIndexMap
}
