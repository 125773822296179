import bbox from '@turf/bbox'
import Fuse from 'fuse.js'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import * as React from 'react'
import BodyClassName from 'react-body-classname'
import { Helmet } from 'react-helmet'
import { Popup, ZoomControl } from 'react-mapbox-gl'
import WebMercatorViewport from 'viewport-mercator-project'
import Routes from '../../../startup/routes'
import { DEFAULT_MAP_CENTER_COORDINATES } from '../../../util/constants'
import { iconUrlForCategory } from '../../../util/placeCategoryIconMapping'
import Mapbox from '../mapbox/Mapbox'
import EmbeddedModalLinkBlocker from '../misc/EmbeddedModalLinkBlocker'
import ListItem from '../misc/ListItem'
import SearchBox from '../misc/SearchBox'
import PlacePopup from './PlacePopup'
import PlaceSignUpUpsell from './PlaceSignUpUpsell'
import PlacesLayer, { placeLayerIds } from './PlacesLayer'

const defaultZoom = 9
const settleInOptions = { padding: { start: 200, end: 0 }, duration: 800 }
const defaultPopupOffset = {
  medium: { bottom: [0, -32], 'bottom-left': [0, -32], 'bottom-right': [0, -32], right: [-15, -24], left: [15, -24] },
  large: { bottom: [0, -40], 'bottom-left': [0, -40], 'bottom-right': [0, -40], right: [-18, -26], left: [18, -26] },
}

const getBounds = feature => {
  const [west, south, east, north] = bbox(feature)
  return [
    [west, south],
    [east, north],
  ]
}

const CenterPlace = ({ centerPlace, place, shouldCenter }) => {
  React.useEffect(() => {
    if (place && shouldCenter) {
      centerPlace(place)
    }
  }, [centerPlace, place, shouldCenter])

  return null
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class PlacesList extends React.Component {
  state = {
    place: this.props.place || null,
    places: this.props.places || [],
    center: DEFAULT_MAP_CENTER_COORDINATES,
    zoom: [defaultZoom],
    fitBounds: null,
    fitBoundsOptions: { padding: settleInOptions.padding.start },
    width: 0,
    height: 0,
    searchPerformed: false,
    searchResultPlaces: [],
    shouldCenterPlace: false,
  }

  componentDidMount() {
    !this.props.loading && this.centerMap(this.props)
  }

  static getDerivedStateFromProps({ places }, state) {
    if (places !== state.places) return { places }
    return null
  }

  componentDidUpdate(prevProps) {
    prevProps.loading && !this.props.loading && this.centerMap(this.props)
  }

  settleIn = () => {
    this.setState(() => ({
      fitBoundsOptions: { ...this.state.fitBoundsOptions, padding: settleInOptions.padding.end, duration: settleInOptions.duration },
    }))
  }

  centerMap(props) {
    // center map to primary community only if there's no place marker active
    // center map to place marker or primary community
    if (props.place && props.place.geo) {
      const { place } = props

      this.setState(() => ({ place }))
      this.setFitBounds({
        center: place.geo.geometry.coordinates,
        zoom: [14],
        offset: this.popupOffset(place),
        addSidebarOffset: false,
      })
    } else if (props.primaryCommunity) {
      this.setFitBounds({
        bounds: getBounds(props.primaryCommunity.geo),
        center: props.primaryCommunity.centerCoordinates || undefined,
      })
    }
  }

  togglePopup = (place, shouldCenterPlace = false) => {
    this.setState(state => {
      const shouldOpenPopup = place && get(state.place, 'id') !== get(place, 'id')
      return { place: shouldOpenPopup ? place : null, shouldCenterPlace }
    })
  }

  closePopup = () => this.togglePopup(null)

  onMarkerClick = (map, event) => {
    const clickingMarker = map.queryRenderedFeatures(event.point, { layers: placeLayerIds }).length > 0
    // togglePopup will handle removing place when clicking on a marker
    if (!clickingMarker) this.closePopup()
  }

  centerPlace = place => {
    this.setFitBounds({ center: place.geo.geometry.coordinates, zoom: [14], offset: this.popupOffset(place), addSidebarOffset: false })
  }

  zoomToCluster = (center, zoom) => this.setFitBounds({ addSidebarOffset: false, center, zoom })

  setFitBounds({
    addSidebarOffset = true,
    center = DEFAULT_MAP_CENTER_COORDINATES,
    zoom = defaultZoom,
    bounds,
    offset = [0, 0],
    options = this.state.fitBoundsOptions,
  }) {
    let xOffset = offset[0]
    const yOffset = offset[1]

    // category filters are only shown at min-width: 1024px
    if (addSidebarOffset && this.state.width >= 1024) {
      const sidebarWidth = 300
      const sidebarOffset = 30
      xOffset -= sidebarWidth + sidebarOffset
    }

    // create a virtual viewport adjusted by the offset
    let viewport = new WebMercatorViewport({
      width: this.state.width - Math.abs(xOffset),
      height: this.state.height - Math.abs(yOffset),
      zoom,
    })

    let sw
    let ne

    if (bounds) {
      // fit the virtual viewport to the given bounds
      viewport = viewport.fitBounds(bounds)

      // get the corners of the virtual viewport
      sw = viewport.unproject([0, viewport.height])
      ne = viewport.unproject([viewport.width, 0])
    } else {
      // when positioning by center, coordinates seem to be backwards
      sw = viewport.getMapCenterByLngLatPosition({ lngLat: center, pos: [viewport.width, 0] })
      ne = viewport.getMapCenterByLngLatPosition({ lngLat: center, pos: [0, viewport.height] })
    }

    this.setState(() => ({
      // fit the map as if it were the adjusted size
      // but offset the center to account for the adjustment
      fitBounds: [sw, ne],
      fitBoundsOptions: { ...options, offset: [xOffset / 2, yOffset / 2] },
    }))
  }

  get popup() {
    const { place } = this.state
    if (!place || !place.geo) return null

    const offset = defaultPopupOffset[place.markerSize] || defaultPopupOffset.medium

    return (
      <Popup anchor="bottom" coordinates={place.geo.geometry.coordinates} offset={offset}>
        <PlacePopup communityIdParam={this.props.communityIdParam} placeSlug={place.slug} closePopup={this.closePopup} />
      </Popup>
    )
  }

  popupOffset(place) {
    const mapHeight = this.state.height
    const mapHalfHeight = mapHeight / 2
    const popupHeightPlusPadding = place.isPremium ? 450 + 30 : 260 + 30

    let y

    if (mapHalfHeight > popupHeightPlusPadding) {
      y = 0
    } else if (mapHeight * 0.75 < popupHeightPlusPadding) {
      // if popup is bigger than the map
      // position the top of popup visible on the screen
      y = Math.max(popupHeightPlusPadding - mapHalfHeight, mapHalfHeight * 0.75)
    } else if (mapHalfHeight < popupHeightPlusPadding) {
      // if popup is bigger than half of the map
      // make the popup fit the map
      y = Math.min(popupHeightPlusPadding, mapHalfHeight * 0.5)
    } else {
      y = popupHeightPlusPadding - 170
    }
    return [0, y]
  }

  updateDimensions = ({ width, height }) => this.setState(() => ({ width, height }))

  searchTextChanged = text => {
    if (text === '') this.setState(() => ({ places: this.props.places, searchPerformed: false }))
  }

  setSearchResults = results => {
    // bump up premium listings' score by factor of 1.2x
    const sorter = ({ score, item: place }) => (place.isPremium ? score / 1.2 : score)
    const searchResultPlaces = sortBy(results, sorter).map(({ item: place }) => place)

    const places = searchResultPlaces.length > 0 ? searchResultPlaces : this.props.places
    this.setState(() => ({ places, searchPerformed: true, searchResultPlaces }))
  }

  renderMetaData() {
    const community = this.props.primaryCommunity

    if (!community) {
      return <Helmet defaultTitle="Places | CivicLift" titleTemplate="Places | %s | CivicLift" />
    }

    return (
      <Helmet defaultTitle="Places | CivicLift" titleTemplate="Places | %s | CivicLift">
        <title key="community title">{community.displayName}</title>
        {community.placesListMetaTitle && <meta property="og:title" content={community.placesListMetaTitle} />}
        {community.placesListMetaDescription && (
          <meta
            property="og:description"
            key="community meta description"
            name="description"
            content={community.placesListMetaDescription}
          />
        )}
        {community.placesListMetaKeywords && (
          <meta key="community meta keywords" name="keywords" content={community.placesListMetaKeywords} />
        )}
        <link rel="canonical" href={Routes.customUrl(community, Routes.places(community))} />
      </Helmet>
    )
  }

  renderCategoriesFilter() {
    if (this.state.searchPerformed || this.props.placeCategories.length === 0) {
      return null
    }
    return (
      <div className="sidebarPanel places-overview-filters">
        {this.props.placeCategories.map(category => (
          <label key={category.name} className="filter">
            <div className="filter-text iconWithText">
              <img src={iconUrlForCategory(category.name)} alt={`${category.name} icon`} />
              <strong>{category.name}</strong>&nbsp;({category.count})
            </div>
            <div className="filter-switch">
              <span className="onoffswitch">
                <input
                  type="checkbox"
                  className="onoffswitch-checkbox"
                  onChange={() => this.props.togglePlaceCategory(category)}
                  checked={!category.hidden}
                />
                <div className="onoffswitch-label" />
              </span>
            </div>
          </label>
        ))}
      </div>
    )
  }

  renderSidebar() {
    const { searchResultPlaces } = this.state

    return (
      <div>
        <aside className="places-sidebar">
          <EmbeddedModalLinkBlocker
            to={Routes.newPlace()}
            className="btn btn-green btn-new"
            modalComponent={PlaceSignUpUpsell}
            modalCTALink={Routes.signUp(this.props.primaryCommunity)}
          >
            Submit a Place
          </EmbeddedModalLinkBlocker>

          <div style={{ marginTop: '20px' }}>
            <SearchBox
              handleSearch={term => {
                if (!term) {
                  return []
                }

                const fuse = new Fuse(this.props.allPlaces, {
                  includeScore: true,
                  shouldSort: true,
                  threshold: 0.4,
                  location: 0,
                  distance: 100,
                  maxPatternLength: 32,
                  minMatchCharLength: 1,
                  keys: [
                    'title',
                    'shortDescription',
                    'longDescription',
                    'address.city',
                    'address.state',
                    'address.streetAddress',
                    'address.streetAddress2',
                    'address.zipCode',
                    'category',
                    'contact.email',
                    'contact.name',
                    'contact.phone',
                  ],
                })

                return fuse.search(term)
              }}
              onChange={this.searchTextChanged}
              placeholder="Search for Places"
              setSearchResults={this.setSearchResults}
            />
          </div>

          {this.renderCategoriesFilter()}
        </aside>

        {this.state.searchPerformed &&
          (searchResultPlaces.length === 0 ? (
            <div className="list placesSearchList">
              <ListItem item={{ title: 'No results found :(' }} />
            </div>
          ) : (
            <div className="list placesSearchList">
              {searchResultPlaces.map(place => (
                <ListItem
                  key={place.id}
                  item={place}
                  extras={place.isPremium ? <i style={{ marginLeft: 'auto' }} className="icon-star-yellow" /> : null}
                  onClick={() => this.togglePopup(place, true)}
                />
              ))}
            </div>
          ))}
      </div>
    )
  }

  render() {
    return (
      <BodyClassName className="path-places">
        <section className="places-overview">
          {this.renderMetaData()}
          {this.renderSidebar()}
          <Mapbox
            center={this.state.center}
            fitBounds={this.state.fitBounds}
            fitBoundsOptions={this.state.fitBoundsOptions}
            zoom={this.state.zoom}
            onClick={this.onMarkerClick}
            onStyleLoad={this.settleIn}
            onSizeUpdate={this.updateDimensions}
          >
            <ZoomControl position="top-left" />
            <PlacesLayer
              currentPlace={this.state.place}
              places={this.state.places}
              onPlaceClick={this.togglePopup}
              onClusterClick={this.zoomToCluster}
            />
            {this.popup}
            <CenterPlace place={this.state.place} centerPlace={this.centerPlace} shouldCenter={this.state.shouldCenterPlace} />
          </Mapbox>
        </section>
      </BodyClassName>
    )
  }
}
