import dayjs from 'dayjs'
import gql from 'graphql-tag'
import sortBy from 'lodash/sortBy'
import queryString from 'query-string'
import React, { useContext, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { calculateShouldFetchSingleCommunity } from '../../api/records'
import usePaginatedQuery from '../../api/usePaginatedQuery'
import useRedirect from '../../hooks/useRedirect'
import { Community, Event } from '../../models'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import Routes from '../../startup/routes'
import { LocalStorage } from '../../util/window'
import EventsList from '../components/events/EventsList'
import { toggleBlocked } from '../helpers/helpers'

const EventsListQuery = gql`
  fragment eventsListEventFragment on Event {
    id
    title
    slug
    startAt
    endAt
    eventType
    excerpt
    tempPlace_name: tempPlaceName
    image_publicId: imagePublicId
    costMin
    costMax
    repeats
    repeatingEventId
    day

    # TODO: many-to-many
    communities {
      id
      slug
      displayName
    }
    place {
      id
      name
    }
    isVirtual
  }
  query EventsList(
    $communitySlug: String!
    $shouldFetchSingleCommunity: Boolean = true
    $fromDate: ISO8601DateTime!
    $categories: [EventCategoryEnum!]
    $cursor: String
  ) {
    community(slug: $communitySlug) @include(if: $shouldFetchSingleCommunity) {
      id
      slug
      events(first: 30, fromDate: $fromDate, categories: $categories, after: $cursor) {
        nodes {
          ...eventsListEventFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    currentUser @skip(if: $shouldFetchSingleCommunity) {
      id
      subscribedEvents(first: 30, fromDate: $fromDate, categories: $categories, after: $cursor) {
        nodes {
          ...eventsListEventFragment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      subscribedEventsCommunities {
        id
        slug
        displayName
      }
    }
  }
`

const EventsListContainer: React.FC = () => {
  const { idOrName } = useParams<{ idOrName?: string }>()

  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const communitySlug = idOrName || currentUser?.primaryCommunity?.slug || LocalStorage.getItem('customDomainOrigin') || ''

  const shouldFetchSingleCommunity = calculateShouldFetchSingleCommunity(idOrName, currentUser)
  const isUnknownCommunity = shouldFetchSingleCommunity && !communitySlug

  const { search } = useLocation()
  const { categories, from: fromDate } = queryString.parse(search)
  const today = dayjs().startOf('day').toDate()
  const selectedDay = fromDate ? dayjs(fromDate as string).toDate() : today

  const property = shouldFetchSingleCommunity ? 'community.events' : 'currentUser.subscribedEvents'
  type Variables = { communitySlug: string; shouldFetchSingleCommunity: boolean; fromDate: Date; categories?: string[] }
  const variables: Variables = {
    communitySlug,
    shouldFetchSingleCommunity,
    fromDate: selectedDay,
    ...(categories && { categories: (categories as string).split(',') }),
  }
  const { data, loading, fetchNextPage, hasNextPage } = usePaginatedQuery(EventsListQuery, property, {
    variables,
    skip: isUnknownCommunity || loadingCurrentUser,
  })

  useRedirect(!loadingCurrentUser && isUnknownCommunity && Routes.unauthenticated())
  let didRedirect = !loadingCurrentUser && isUnknownCommunity
  const community = data?.community
  useRedirect(!didRedirect && shouldFetchSingleCommunity && community && community.slug !== idOrName && Routes.events(community))
  didRedirect ||= shouldFetchSingleCommunity && community && community.slug !== idOrName

  const events: Event[] = useMemo(
    () =>
      sortBy((shouldFetchSingleCommunity ? data?.community?.events?.nodes : data?.currentUser?.subscribedEvents?.nodes) || [], 'startAt'),
    [data, shouldFetchSingleCommunity],
  )

  const communities: Community[] = data?.currentUser?.subscribedEventsCommunities || (community ? [community] : [])

  const [blockedEventCommunities, setBlockedEventCommunities] = useState<string[]>([])
  const filteredEvents = useMemo(
    () => events.filter(event => !blockedEventCommunities.includes(event.community?.id)),
    [events, blockedEventCommunities],
  )

  return didRedirect ? null : (
    <EventsList
      loading={loading}
      communities={communities}
      communityIdParam={idOrName}
      currentUser={currentUser}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      blockedEventCommunities={blockedEventCommunities}
      toggleEventCommunity={toggleBlocked(setBlockedEventCommunities)}
      events={filteredEvents}
      selectedDay={selectedDay}
    />
  )
}

export default EventsListContainer
