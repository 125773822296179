import { SerializedStyles } from '@emotion/react'
import React, { HTMLAttributes, useState } from 'react'

interface FileDropBoxProps extends HTMLAttributes<HTMLDivElement> {
  onDragging?: (e) => void
  onNotDragging?: (e) => void
  onDropped: (e) => void
  onDraggingStyle?: SerializedStyles
}

const FileDropBox: React.FC<FileDropBoxProps> = props => {
  const { onDragging, onNotDragging, onDropped, onDraggingStyle, children, ...otherProps } = props
  const [isDraggingInProgress, setIsDraggingInProgress] = useState(false)
  const preventer = e => {
    e.preventDefault()
  }

  const fileDraggedOver = e => {
    preventer(e)
    setIsDraggingInProgress(true)
    if (onDragging) {
      onDragging(e)
    }
  }

  const fileNotDraggedOver = e => {
    preventer(e)
    setIsDraggingInProgress(false)
    if (onNotDragging) {
      onNotDragging(e)
    }
  }

  return (
    <div
      css={isDraggingInProgress ? onDraggingStyle : ''}
      onDragOver={fileDraggedOver}
      onDragLeave={fileNotDraggedOver}
      onDragEnd={fileNotDraggedOver}
      onDrop={e => {
        fileNotDraggedOver(e)
        const droppedFiles = e.dataTransfer.files
        onDropped(droppedFiles[0])
      }}
      {...otherProps}
    >
      {children}
    </div>
  )
}

export default FileDropBox
