import React from 'react'
import { isEmbedded } from '../../util/window'
import PublicFooter from '../components/public-header/PublicFooter'
import PublicHeader from '../components/public-header/PublicHeader'

const PublicLayout: React.FC = ({ children }) => {
  if (isEmbedded()) return <div className="outer-wrap">{children}</div>

  return (
    <div>
      <PublicHeader />
      <div className="outer-wrap">{children}</div>
      <PublicFooter />
    </div>
  )
}

export default PublicLayout
