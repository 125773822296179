import React from 'react'
import { Marker } from 'react-mapbox-gl'
import { Place } from '../../../models'
import { iconUrlForCategory } from '../../../util/placeCategoryIconMapping'

/* NOTE:
 * A Cluster requires a Marker as a direct child,
 * so in order to use a PlaceMarker inside a Cluster,
 * you must render it like PlaceMarker(props)
 * instead of <PlaceMarker {...props} />
 */

const className = (place: Place) => ['place-marker', place.markerSize].join(' ')

const offset = (place: Place) => (place.markerSize === 'large' ? -10 : -8)

type Props = { place: Place }

interface PlaceMarkerProps {
  place: Place
}

const PlaceMarker: React.FC<Props> = ({ place, ...props }: PlaceMarkerProps) => (
  <Marker
    key={place.id}
    className={className(place)}
    coordinates={place.geo.geometry.coordinates}
    anchor="bottom"
    offset={offset(place)}
    {...props}
  >
    <img className="place-marker-img" alt="" src={iconUrlForCategory(place.category)} />
  </Marker>
)

export default PlaceMarker
