import InputMask from 'react-input-mask'
import connectField from 'uniforms/connectField'
import filterDOMProps from 'uniforms/filterDOMProps'

const UniformInputMask = ({ onChange, disabled, id, name, placeholder, value, ...props }) => {
  // we intentionally remove "custom" property here
  // this is necessary, because otherwise react throws error:
  // Invalid value for prop `custom` on <input> tag.
  const { custom, autoValue, ...rest } = { ...filterDOMProps(props) } // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <InputMask
      id={id}
      name={name}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      {...rest}
    />
  )
}

export default connectField(UniformInputMask)
