import SimpleSchema from './schema'

const SlugSchema = new SimpleSchema({
  slug: { type: String, label: 'Slug', optional: true },
  // slug_lower is used for case-insensitive search
  slug_lower: {
    type: String,
    autoValue() {
      const slug = this.field('slug').value
      return slug && slug.toLowerCase()
    },
    optional: true,
  },
})

export default SlugSchema
