import * as React from 'react'
import { MapContext } from 'react-mapbox-gl'
import ResizeObserver, { DOMRect } from 'react-resize-observer'

interface Props {
  onUpdate?: (rect: DOMRect) => void
}

const SizeUpdater: React.FC<Props> = ({ onUpdate }) => {
  const map = React.useContext(MapContext)

  return (
    <ResizeObserver
      onResize={(rect: DOMRect): void => {
        if (map) {
          map.resize()
        }

        if (onUpdate) {
          onUpdate(rect)
        }
      }}
    />
  )
}

export default React.memo(SizeUpdater)
