import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Community } from '../../../models'
import Routes from '../../../startup/routes'

type Props = { communityIdParam?: string; communities: Community[] }

const EventsListMetadata: React.FC<Props> = ({ communityIdParam, communities }) => {
  let community
  if (communityIdParam) community = communities.find(c => c.slug.toLowerCase() === communityIdParam.toLowerCase())
  if (!community) return <Helmet defaultTitle="Events | CivicLift" titleTemplate="Events | %s | CivicLift" />

  return (
    <Helmet defaultTitle="Events | CivicLift" titleTemplate="Events | %s | CivicLift">
      <title key="community title">{community.displayName}</title>
      {community.eventsListMetaTitle && <meta property="og:title" content={community.eventsListMetaTitle} />}
      {community.eventsListMetaDescription && (
        <meta property="og:description" key="community meta description" name="description" content={community.eventsListMetaDescription} />
      )}
      {community.eventsListMetaKeywords && (
        <meta key="community meta keywords" name="keywords" content={community.eventsListMetaKeywords} />
      )}
      <link rel="canonical" href={Routes.customUrl(community, Routes.events(community))} />
    </Helmet>
  )
}

export default EventsListMetadata
