import { useRouteMatch } from 'react-router'

const ROOT_LEVEL_PATHS = [
  'dash',
  'events',
  'feeds',
  'forgot-password',
  'headlines',
  'places',
  'reset-password',
  'sign-in',
  'sign-up',
  'stories',
]

const useCommunityIdOrSlug = (): string | undefined => {
  // This is a hack needed to get all params (from sub-routes as well)
  // Taken from:
  // https://github.com/ReactTraining/react-router/issues/5870#issuecomment-394194338
  const match = useRouteMatch<{ idOrSlug: string }>({ path: '/:idOrSlug?', exact: false, strict: false })
  const { params: { idOrSlug } = {} } = match || {}
  // returns `undefined` (not `null`) when can't find community id in the url
  // this is needed to work correctly with `Routes` routes
  if (ROOT_LEVEL_PATHS.includes(idOrSlug)) return
  return idOrSlug
}

export default useCommunityIdOrSlug
