import dayjs from 'dayjs'
import gql from 'graphql-tag'
import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '../../../api/graphql'
import Calendar from '../../components/misc/Calendar'

const CALENDAR_QUERY = gql`
  query CalendarQuery($id: String!, $timezone: String) {
    feed(id: $id) {
      id
      eventsCalendar(timezone: $timezone)
    }
  }
`

type Props = { id: string; selectedDay: Date }
const ContentFeedCalendarContainer: React.FC<Props> = ({ id, selectedDay }) => {
  const history = useHistory()
  const location = useLocation()

  const timezone = useMemo(() => dayjs.tz.guess() || 'America/New_York', [])

  const { data } = useQuery(CALENDAR_QUERY, { variables: { id, timezone } })

  const eventDays: string[] = data?.feed?.eventsCalendar || []

  const handleDateChange = useCallback(
    newDate => {
      const fromDate = dayjs(newDate).format('YYYY-MM-DD')
      const paths = location.pathname.split('/')
      const eventsIndex = paths.indexOf('events')
      const newPath = paths.slice(0, eventsIndex + 1).join('/')
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('from', fromDate)
      const search = queryParams.toString()
      history.replace({ pathname: newPath, search })
    },
    [history, location],
  )

  return <Calendar dates={eventDays.map(day => new Date(day))} onChangeDate={handleDateChange} value={selectedDay} />
}

export default ContentFeedCalendarContainer
