import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../../startup/routes'

const CivicLiftLogo: React.FC = () => (
  <Link to={Routes.root()} className="logo">
    <img className="logo-generic" src="/img/logo-civiclift.svg" alt="CivicLift" />
  </Link>
)

export default CivicLiftLogo
