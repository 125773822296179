import gql from 'graphql-tag'
import { useLazyQuery } from '../api/graphql'

const RECORD_SEARCH_QUERY = gql`
  query RecordSearch($searchTerm: String!, $communityId: ID, $recordsType: RecordEnum) {
    search(searchTerm: $searchTerm, communityId: $communityId, recordsType: $recordsType) {
      ... on Event {
        id
        slug
        image_publicId: imagePublicId
        title
        excerpt
        costMin
        costMax
        startAt
      }
      ... on Place {
        id
        slug
        name
        shortDescription
        category
        tags

        address_streetAddress: addressStreetAddress
        address_city: addressCity
        address_state: addressState
        address_zipCode: addressZipCode
      }
      ... on Story {
        id
        slug
        image_publicId: imagePublicId
        title
        category
        submitter {
          id
          firstName
          lastName
          profilePic_publicId: profilePicPublicId
        }
        excerpt
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRecordSearch = ({ query, ...searchVariables }) => {
  const [searchRecords, { loading, data }] = useLazyQuery(query || RECORD_SEARCH_QUERY)

  return { componentProps: { ...searchVariables, searchRecords, data }, loading }
}

export default useRecordSearch
