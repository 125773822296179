import * as React from 'react'
import { eventIdToCommunityIndexMapType } from '../../../api/events/helpers'
import { Event } from '../../../models'
import EventsListFullSizeItem from './EventsListFullSizeItem'

type Props = {
  events: Event[]
  eventIdToCommunityIndexMap?: eventIdToCommunityIndexMapType
}
const EventsListDayFullSizeEvents: React.FC<Props> = ({ events, eventIdToCommunityIndexMap }) => {
  if (events.length === 0) return null

  return (
    <ul className="events-list">
      {events.map(event => {
        const { place } = event
        const communityIndex = eventIdToCommunityIndexMap && eventIdToCommunityIndexMap[event.id]

        return <EventsListFullSizeItem key={event.id} event={event} type={communityIndex} place={place} />
      })}
    </ul>
  )
}

export default EventsListDayFullSizeEvents
