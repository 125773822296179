import { Community } from '../models'
import { PublishStateType } from '../util/constants'

export interface Address {
  city?: string
  state?: string
  streetAddress?: string
  streetAddress2?: string
  zipCode?: string
}

export interface Contact {
  email?: string
  name?: string
  phone?: string
}

export interface Media {
  publicId?: string
  type?: string
}

export class Publishable {
  get community(): Community | undefined {
    return this.communities?.[0]
  }
  public communities?: Community[]
  public publishState?: PublishStateType
}
