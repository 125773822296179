export const halfHourTimeValues = [
  // value is number of minutes since midnight
  { value: 24 * 60, label: '12:00am' },
  { value: 0 * 60 + 30, label: '12:30am' },
  { value: 1 * 60, label: '1:00am' },
  { value: 1 * 60 + 30, label: '1:30am' },
  { value: 2 * 60, label: '2:00am' },
  { value: 2 * 60 + 30, label: '2:30am' },
  { value: 3 * 60, label: '3:00am' },
  { value: 3 * 60 + 30, label: '3:30am' },
  { value: 4 * 60, label: '4:00am' },
  { value: 4 * 60 + 30, label: '4:30am' },
  { value: 5 * 60, label: '5:00am' },
  { value: 5 * 60 + 30, label: '5:30am' },
  { value: 6 * 60, label: '6:00am' },
  { value: 6 * 60 + 30, label: '6:30am' },
  { value: 7 * 60, label: '7:00am' },
  { value: 7 * 60 + 30, label: '7:30am' },
  { value: 8 * 60, label: '8:00am' },
  { value: 8 * 60 + 30, label: '8:30am' },
  { value: 9 * 60, label: '9:00am' },
  { value: 9 * 60 + 30, label: '9:30am' },
  { value: 10 * 60, label: '10:00am' },
  { value: 10 * 60 + 30, label: '10:30am' },
  { value: 11 * 60, label: '11:00am' },
  { value: 11 * 60 + 30, label: '11:30am' },
  { value: 12 * 60, label: '12:00pm' },
  { value: 12 * 60 + 30, label: '12:30pm' },
  { value: 13 * 60, label: '1:00pm' },
  { value: 13 * 60 + 30, label: '1:30pm' },
  { value: 14 * 60, label: '2:00pm' },
  { value: 14 * 60 + 30, label: '2:30pm' },
  { value: 15 * 60, label: '3:00pm' },
  { value: 15 * 60 + 30, label: '3:30pm' },
  { value: 16 * 60, label: '4:00pm' },
  { value: 16 * 60 + 30, label: '4:30pm' },
  { value: 17 * 60, label: '5:00pm' },
  { value: 17 * 60 + 30, label: '5:30pm' },
  { value: 18 * 60, label: '6:00pm' },
  { value: 18 * 60 + 30, label: '6:30pm' },
  { value: 19 * 60, label: '7:00pm' },
  { value: 19 * 60 + 30, label: '7:30pm' },
  { value: 20 * 60, label: '8:00pm' },
  { value: 20 * 60 + 30, label: '8:30pm' },
  { value: 21 * 60, label: '9:00pm' },
  { value: 21 * 60 + 30, label: '9:30pm' },
  { value: 22 * 60, label: '10:00pm' },
  { value: 22 * 60 + 30, label: '10:30pm' },
  { value: 23 * 60, label: '11:00pm' },
  { value: 23 * 60 + 30, label: '11:30pm' },
  { value: 24 * 60, label: '12:00am' },
]
