import SimpleSchema from './schema'

const GeoSchema = new SimpleSchema({
  type: { type: String, allowedValues: ['Feature'], defaultValue: 'Feature', optional: true },
  geometry: { type: Object, blackbox: true, optional: true },
  'geometry.type': { type: String, allowedValues: ['Point', 'LineString', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon'] },
  'geometry.coordinates': { type: Array },
  'geometry.coordinates.$': { type: Number },
  properties: { type: Object, optional: true, blackbox: true },
})

export default GeoSchema
