import gql from 'graphql-tag'
import sortBy from 'lodash/sortBy'
import * as React from 'react'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '../../api/graphql'
import { placeFragmentWithEvents } from '../../graphql/fragments'
import Routes, { CommunitySlugRouteParams } from '../../startup/routes'
import { PublishState } from '../../util/constants'
import PlaceView from '../components/places/PlaceView'

export const PlaceViewQuery = gql`
  query PlaceView($placeSlug: String!) {
    place(slug: $placeSlug) {
      ...placeFragmentWithEvents
    }
  }
  ${placeFragmentWithEvents}
`

const PlaceViewContainer: React.FC = () => {
  const history = useHistory()
  const { idOrName: communitySlug, id: placeSlug } = useParams<CommunitySlugRouteParams & { id: string }>()

  const { loading, data: { place = null } = {} } = useQuery(PlaceViewQuery, { variables: { placeSlug }, skip: !placeSlug })

  React.useEffect(() => {
    if (!loading && !place) {
      history.push(Routes.places(communitySlug))
    } else if (place && !place.isPremium) {
      history.push(Routes.place(placeSlug))
    } else if (place && place.slug !== placeSlug) {
      history.replace(Routes.premiumPlace(place))
    }
  }, [loading, place, placeSlug, communitySlug, history])

  const events = React.useMemo(() => {
    if (!place) return []

    const now = new Date()

    return sortBy(
      place.events.nodes.filter(event => event.publishState === PublishState.APPROVED && now <= new Date(event.startAt)),
      'startAt',
    ).slice(2)
  }, [place])

  return <PlaceView loading={loading} place={place} communityIdParam={communitySlug} events={events} />
}

export default PlaceViewContainer
