import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

const GRID_GAP = 20
const GRID_GAP_MARGIN = `${GRID_GAP / 2}px`

export const SMALL = '425px'
export const MEDIUM = '768px'
export const LARGE = '1024px'
export const XLARGE = '1200px'

type Breakpoint = typeof SMALL | typeof MEDIUM | typeof LARGE | typeof XLARGE

export const breakpoint = (bp: Breakpoint): string => `@media (min-width: ${bp})`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${GRID_GAP_MARGIN};
  margin-right: -${GRID_GAP_MARGIN};
`

type GridItemProps = {
  columns?: number
  small?: number
  medium?: number
  large?: number
  xlarge?: number
  children?: React.ReactNode
}

const GridItem: React.FC<GridItemProps> = props => <StyledGridItem {...props} />

const StyledGridItem = styled.div<GridItemProps>`
  padding-left: ${GRID_GAP_MARGIN};
  padding-right: ${GRID_GAP_MARGIN};

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  max-width: 100%;

  ${props =>
    props.columns &&
    css`
      flex-basis: calc(100% / ${12 / props.columns});
      max-width: calc(100% / ${12 / props.columns});
    `}

  ${props =>
    props.small &&
    css`
      @media (min-width: ${SMALL}) {
        flex-basis: calc(100% / ${12 / props.small});
        max-width: calc(100% / ${12 / props.small});
      }
    `}

  ${props =>
    props.medium &&
    css`
      @media (min-width: ${MEDIUM}) {
        flex-basis: calc(100% / ${12 / props.medium});
        max-width: calc(100% / ${12 / props.medium});
      }
    `}

  ${props =>
    props.large &&
    css`
      @media (min-width: ${LARGE}) {
        flex-basis: calc(100% / ${12 / props.large});
        max-width: calc(100% / ${12 / props.large});
      }
    `}

  ${props =>
    props.xlarge &&
    css`
      @media (min-width: ${XLARGE}) {
        flex-basis: calc(100% / ${12 / props.xlarge});
        max-width: calc(100% / ${12 / props.xlarge});
      }
    `}
`

export { GridItem }
export default Grid
