import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2'
import SimpleSchema from '../schema'

const SignInSchema = new SimpleSchema({
  email: { type: String, regEx: SimpleSchema.RegEx.Email, label: 'Email' },
  password: { type: String, label: 'Password' },
  rememberSignIn: { type: Boolean, defaultValue: true, label: 'Remember me', optional: true },
})

export default new SimpleSchema2Bridge(SignInSchema)
