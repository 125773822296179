import debounce from 'lodash/debounce'
import get from 'lodash/get'
import React from 'react'
import Select from 'react-select'
import useRecordSearch from '../../../hooks/useRecordSearch'
import { formatShortDate } from '../../../util/dateTime'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RecordSearchAutocomplete: React.FC = ({ onChange, name, value, searchProps, ...selectProps }) => {
  const {
    loading,
    componentProps: { searchRecords, data, communityId, recordsType },
  } = useRecordSearch(searchProps)

  const records = get(data, 'search', [])
  const options = records.map(r => {
    let icon
    switch (r.__typename) {
      case 'Event':
        icon = <i className="icon-events-red" aria-hidden="true" />
        break
      case 'Place':
        icon = <i className="icon-location-green" aria-hidden="true" />
        break
      case 'Story':
        icon = <i className="icon-bullhorn-blue" aria-hidden="true" />
        break
      default:
        icon = null
        break
    }
    let label = r.title || r.name
    if (r.startAt) {
      label += ` (${formatShortDate(r.startAt)})`
    }
    return { value: r.id, icon, label }
  })

  return (
    <Select
      name={name}
      isLoading={loading}
      value={value}
      onChange={x => {
        const selectedRecord = x ? records.find(r => r.id === x.value) : null
        onChange(selectedRecord)
      }}
      onInputChange={debounce(newValue => {
        if (newValue.length < 3) {
          return
        }
        searchRecords({ variables: { searchTerm: newValue, communityId, recordsType } })
      }, 350)}
      optionRenderer={option => (
        <>
          {option.icon} {option.label}
        </>
      )}
      placeholder="Start typing to search..."
      {...selectProps}
      options={selectProps.options || options}
    />
  )
}

export default RecordSearchAutocomplete
