import React from 'react'
import Routes from '../../../startup/routes'

// TODO: add types
type Props = { closeModal; ctaLink }

const EventSignUpUpsell: React.FC<Props> = ({ closeModal, ctaLink }) => (
  <div className="upsellModal">
    <i className="ReactModal-modal-closeIcon icon-cancel-circle" onClick={closeModal} />
    <h2 className="upsellModal-title">We're happy to promote your event!</h2>
    <div className="upsellModal-subtitle">Create a free CivicLift account to submit your event.</div>
    <a target="_blank" rel="noopener noreferrer" href={ctaLink} className="btn upsellModal-cta">
      Create <span className="show-for-medium">Your Free</span> Account
    </a>
    <p className="upsellModal-haveAccount prompt-sign-in">
      Already have an account?{' '}
      <a target="_blank" rel="noopener noreferrer" href={Routes.signIn()}>
        Sign in
      </a>
      .
    </p>
    <ul className="upsellModal-upsellPoints">
      <li>Submit, manage, and promote your events</li>
      <li>Subscribe to all of the communities you care about</li>
      <li>
        Discover things to do and places to go - based on <em>your</em> interests
      </li>
    </ul>
    <div className="upsellModal-footer">
      <img src="/img/logo_slogan.svg" />
    </div>
  </div>
)

export default EventSignUpUpsell
