import gql from 'graphql-tag'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { calculateShouldFetchSingleCommunity } from '../../api/records'
import { placesMapFragment } from '../../graphql/fragments'
import { useQuery, useRedirect } from '../../hooks'
import { Place } from '../../models'
import { CurrentUserContext } from '../../providers/CurrentUserContext'
import Routes from '../../startup/routes'
import { LocalStorage } from '../../util/window'
import PlacesList from '../components/places/PlacesList'
import { PlaceViewQuery } from './PlaceViewContainer'

const PlacesListQuery = gql`
  query PlacesList($communitySlug: String!, $shouldFetchSingleCommunity: Boolean = true) {
    community(slug: $communitySlug) {
      id
      slug
      geometry: geo
      centerCoordinates: centerCoordinatesLonlat
      placesMap @include(if: $shouldFetchSingleCommunity) {
        ...placesMapFragment
      }
    }
    currentUser @skip(if: $shouldFetchSingleCommunity) {
      id
      subscribedPlacesMap {
        ...placesMapFragment
      }
    }
  }
  ${placesMapFragment}
`

const PlacesListContainer: React.FC = () => {
  const { idOrName, id: placeSlug } = useParams()
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)
  const communitySlug = idOrName || currentUser?.primaryCommunity?.slug || LocalStorage.getItem('customDomainOrigin') || ''

  const shouldFetchSingleCommunity = calculateShouldFetchSingleCommunity(idOrName, currentUser)
  const isUnknownCommunity = shouldFetchSingleCommunity && !communitySlug

  const { data, loading } = useQuery(PlacesListQuery, {
    variables: { communitySlug, shouldFetchSingleCommunity },
    skip: isUnknownCommunity || loadingCurrentUser,
  })

  useRedirect(!loadingCurrentUser && isUnknownCommunity && Routes.unauthenticated())
  let didRedirect = !loadingCurrentUser && isUnknownCommunity
  const community = data?.community
  useRedirect(
    !didRedirect && shouldFetchSingleCommunity && community && community.slug !== idOrName && !placeSlug && Routes.places(community),
  )
  didRedirect ||= shouldFetchSingleCommunity && community && community.slug !== idOrName && !placeSlug

  const places: Place[] = useMemo(
    () => (shouldFetchSingleCommunity ? data?.community?.placesMap : data?.currentUser?.subscribedPlacesMap) || [],
    [data, shouldFetchSingleCommunity],
  )

  const communities = useMemo(() => uniqBy(places.map(place => place.community).filter(Boolean), 'id'), [places])

  const { data: currentPlaceData, loading: loadingCurrentPlace } = useQuery(PlaceViewQuery, {
    variables: { placeSlug },
    skip: !placeSlug,
  })

  const currentPlaceFromPlaces = places.find(place => place.slug === placeSlug)
  const currentPlace = currentPlaceData?.place || currentPlaceFromPlaces
  if (currentPlace && !currentPlaceFromPlaces) places.push(currentPlace)
  const primaryCommunity = data?.community || communities[0]

  const redirectPath = useMemo(() => {
    if (loading || loadingCurrentUser || loadingCurrentPlace) return false

    if (placeSlug && !currentPlace) {
      return Routes.places(communitySlug)
    } else if (idOrName && primaryCommunity && primaryCommunity.slug !== idOrName) {
      return currentPlace ? Routes.place(currentPlace) : Routes.places(primaryCommunity)
    }
  }, [placeSlug, loading, loadingCurrentUser, loadingCurrentPlace, currentPlace, communitySlug, idOrName, primaryCommunity])
  useRedirect(!didRedirect && redirectPath)
  didRedirect ||= redirectPath

  const [hiddenCategories, setHiddenCategories] = React.useState({})

  const placeCategories = Object.entries(groupBy(places, 'category')).map(([name, groupedPlaces]: [string, [Place]]) => ({
    name,
    count: groupedPlaces.length,
    hidden: Boolean(hiddenCategories[name]),
  }))

  const filteredPlaces = React.useMemo(() => places.filter(place => !hiddenCategories[place.category]), [places, hiddenCategories])

  return didRedirect ? null : (
    <PlacesList
      loading={loadingCurrentUser || loading}
      communityIdParam={communitySlug}
      allPlaces={places}
      place={currentPlace}
      places={filteredPlaces}
      placeCategories={placeCategories}
      togglePlaceCategory={(category): void => setHiddenCategories(current => ({ ...current, [category.name]: !current[category.name] }))}
      primaryCommunity={primaryCommunity}
    />
  )
}

export default PlacesListContainer
