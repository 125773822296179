import React from 'react'
import UniformInputMask from '../form-fields/UniformInputMask'

const PhoneField: React.FC = ({ id, label, name }) => (
  <div className="input-row">
    {label && <label htmlFor={id}>{label}</label>}

    <UniformInputMask id={id} name={name} mask="+1 (999) 999-9999" alwaysShowMask maskChar=" " type="text" />
  </div>
)

export default PhoneField
