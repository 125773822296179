import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'
import UserBadge from '../users/UserBadge'
import UnauthenticatedNav from './UnauthenticatedNav'

const UserNav: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext)
  return (
    <nav className="user-nav">
      {currentUser ? (
        <nav className="authenticated-nav">
          <Link to={Routes.dash()} className="header-avatar-lockup">
            <UserBadge user={currentUser} />
            <span>Account</span>
          </Link>
        </nav>
      ) : (
        <UnauthenticatedNav />
      )}
    </nav>
  )
}

export default UserNav
