import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../../providers/CurrentUserContext'
import Routes from '../../../../startup/routes'

const DashWelcome: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const canSubmitStories = currentUser?.isAuthor

  return (
    <section className="dash-welcome-content">
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <header className="dash-section-greeting">
        <h1 className="dash-greeting-heading">Dashboard</h1>
      </header>

      <div className="dash-welcome-content-container">
        <div className="dash-welcome-content-title"></div>

        <div className="dash-welcome-content-links">
          <div className="dash-welcome-content-link-container">
            <img src="/img/welcome-page-icon-events.svg" alt="Submit an Event" />
            <div className="dash-welcome-content-link-text">Feature your event on the community calendar</div>
            <Link to={Routes.newEvent()} className="btn btn-dash-add-event-red link">
              Submit an Event
            </Link>
          </div>

          <div className="dash-welcome-content-link-container">
            <img src="/img/welcome-page-icon-places.svg" alt="Submit a Place" />
            <div className="dash-welcome-content-link-text">Include your business in the map.</div>
            <Link to={Routes.newPlace()} className="btn btn-dash-add-green link">
              Submit a Place
            </Link>
          </div>

          {canSubmitStories && (
            <div className="dash-welcome-content-link-container">
              <img src="/img/welcome-page-icon-stories.svg" alt="Submit a Story" />
              <div className="dash-welcome-content-link-text">Write an article to share with your neighbors.</div>
              <Link to={Routes.newStory()} className="btn btn-dash-add-blue link">
                Submit a Story
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DashWelcome
