import uniq from 'lodash/uniq'
import { mediaSrc } from '../misc/MediaPresets'

const premiumImageSize = 32

// needs to be synced with
//  - app/models/place.rb
//  - app/javascript/api/places/placeCategories.ts
//  - app/javascript/util/placeCategoryIconMapping.ts
const defaultIcon = `<svg id="marker-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path id="path4133" d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703&#xA;&#x9;C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"/>
</svg>`
const lodgingIcon = `<svg id="lodging-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path id="rect6507" d="M0.5,2.5C0.2,2.5,0,2.7,0,3v7.5v2C0,12.8,0.2,13,0.5,13S1,12.8,1,12.5V11h13v1.5&#xA;&#x9;c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-2c0-0.3-0.2-0.5-0.5-0.5H1V3C1,2.7,0.8,2.5,0.5,2.5z M3.5,3C2.7,3,2,3.7,2,4.5l0,0&#xA;&#x9;C2,5.3,2.7,6,3.5,6l0,0C4.3,6,5,5.3,5,4.5l0,0C5,3.7,4.3,3,3.5,3L3.5,3z M7,4C5.5,4,5.5,5.5,5.5,5.5V7h-3C2.2,7,2,7.2,2,7.5v1&#xA;&#x9;C2,8.8,2.2,9,2.5,9H6h9V6.5C15,4,12.5,4,12.5,4H7z"/>
</svg>`
const restaurantIcon = `<svg id="restaurant-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path id="path11774" d="M3.5,0l-1,5.5c-0.1464,0.805,1.7815,1.181,1.75,2L4,14c-0.0384,0.9993,1,1,1,1s1.0384-0.0007,1-1L5.75,7.5&#xA;&#x9;c-0.0314-0.8176,1.7334-1.1808,1.75-2L6.5,0H6l0.25,4L5.5,4.5L5.25,0h-0.5L4.5,4.5L3.75,4L4,0H3.5z M12,0&#xA;&#x9;c-0.7364,0-1.9642,0.6549-2.4551,1.6367C9.1358,2.3731,9,4.0182,9,5v2.5c0,0.8182,1.0909,1,1.5,1L10,14c-0.0905,0.9959,1,1,1,1&#xA;&#x9;s1,0,1-1V0z"/>
</svg>`
const shopIcon = `<svg id="shop-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path d="M13.33,6H11.5l-0.39-2.33c-0.1601-0.7182-0.7017-1.2905-1.41-1.49C9.3507,2.0676,8.9869,2.007,8.62,2H6.38&#xA;&#x9;C6.0131,2.007,5.6493,2.0676,5.3,2.18C4.5917,2.3795,4.0501,2.9518,3.89,3.67L3.5,6H1.67C1.3939,5.9983,1.1687,6.2208,1.167,6.497&#xA;&#x9;C1.1667,6.5489,1.1744,6.6005,1.19,6.65l1.88,6.3l0,0C3.2664,13.5746,3.8453,13.9996,4.5,14h6c0.651-0.0047,1.2247-0.4289,1.42-1.05&#xA;&#x9;l0,0l1.88-6.3c0.0829-0.2634-0.0635-0.5441-0.3269-0.627C13.4268,6.0084,13.3786,6.0007,13.33,6z M4.52,6l0.36-2.17&#xA;&#x9;c0.0807-0.3625,0.3736-0.6395,0.74-0.7C5.8663,3.0524,6.1219,3.0087,6.38,3h2.24c0.2614,0.0078,0.5205,0.0515,0.77,0.13&#xA;&#x9;c0.3664,0.0605,0.6593,0.3375,0.74,0.7L10.48,6h-6H4.52z"/>
</svg>`
const theatreIcon = `<svg id="theatre-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path id="path6342-3" d="M2,1c0,0-1,0-1,1v5.1582C1,8.8885,1.354,11,4.5,11H5V8L2.5,9c0,0,0-2.5,2.5-2.5V5&#xA;&#x9;c0-0.7078,0.0868-1.3209,0.5-1.7754C5.8815,2.805,6.5046,1.9674,8.1562,2.7539L9,3.3027V2c0,0,0-1-1-1C7.2922,1,6.0224,2,5,2&#xA;&#x9;S2.7865,1,2,1z M3,3c0.5523,0,1,0.4477,1,1S3.5523,5,3,5S2,4.5523,2,4S2.4477,3,3,3z M7,4c0,0-1,0-1,1v5c0,2,1,4,4,4s4-2,4-4V5&#xA;&#x9;c0-1-1-1-1-1c-0.7078,0-1.9776,1-3,1S7.7865,4,7,4z M8,6c0.5523,0,1,0.4477,1,1S8.5523,8,8,8S7,7.5523,7,7S7.4477,6,8,6z M12,6&#xA;&#x9;c0.5523,0,1,0.4477,1,1s-0.4477,1-1,1s-1-0.4477-1-1S11.4477,6,12,6z M7.5,10H10h2.5c0,0,0,2.5-2.5,2.5S7.5,10,7.5,10z"/>
</svg>`
const townHallIcon = `<svg id="town-hall-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path id="path7509" d="M7.5,0L1,3.4453V4h13V3.4453L7.5,0z M2,5v5l-1,1.5547V13h13v-1.4453L13,10V5H2z M4,6h1v5.5H4V6z M7,6h1v5.5H7&#xA;&#x9;V6z M10,6h1v5.5h-1V6z"/>
</svg>`
const farmIcon = `<svg id="farm-15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15" fill="#ffffff">
  <path d="m8 6-3-2-3 2-1 2v4h2v-2h4v2h2v-4zm-2 2h-2v-2h2zm8 4h-3v-9.5c0-.39782.158-.77936.4393-1.06066s.6629-.43934 1.0607-.43934.7794.15804 1.0607.43934.4393.66284.4393 1.06066z"/>
</svg>`

const markerSvgSource = (color: string) => `
  <svg width="29px" height="38px" viewBox="0 0 29 38" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-27.3%" y="-100.0%" width="154.5%" height="300.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
      </filter>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1013.000000, -206.000000)">
        <g transform="translate(1015.000000, 208.000000)">
          <ellipse fill-opacity="0.2" fill="#000000" filter="url(#filter-1)" cx="12.5" cy="32.5" rx="5.5" ry="1.5"></ellipse>
          <path d="M16.0122576,25.2793186 C15.9213332,25.458076 15.8340571,25.6423215 15.7499008,25.8325638 C15.5004115,26.3965546 15.2870257,26.9892854 15.0630566,27.710743 C14.9749281,27.994626 14.6332283,29.1583289 14.5447738,29.4372129 C14.0436421,31.0172078 13.5561217,31.75 12.5,31.75 C11.442969,31.75 10.9560197,31.0169773 10.4555818,29.43537 C10.3679426,29.158391 10.0278326,27.9978107 9.93932756,27.7122686 C9.716276,26.9926416 9.50359193,26.4011478 9.25488529,25.8383994 C9.16997702,25.6462775 9.08187428,25.4602801 8.99003222,25.2798838 C3.29099207,23.7188867 -0.75,18.5150337 -0.75,12.5 C-0.75,5.18222707 5.18222707,-0.75 12.5,-0.75 C19.8177729,-0.75 25.75,5.18222707 25.75,12.5 C25.75,18.5143856 21.7098013,23.7180578 16.0122576,25.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${color}"></path>
            </g>
        </g>
    </g>
</svg>
`

// prettier-ignore
const cluster2Source = (colors: string[]) => `
  <svg width="29px" height="43px" viewBox="0 0 29 43" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <filter x="-27.3%" y="-100.0%" width="154.5%" height="300.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
      </filter>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-827.000000, -216.000000)">
        <g transform="translate(829.000000, 218.000000)">
          <ellipse fill-opacity="0.2" fill="#000000" filter="url(#filter-1)" cx="12.5" cy="38" rx="5.5" ry="1.5"></ellipse>
          <path d="M16.0122576,25.2793186 C15.9213332,25.458076 15.8340571,25.6423215 15.7499008,25.8325638 C15.5004115,26.3965546 15.2870257,26.9892854 15.0630566,27.710743 C14.9749281,27.994626 14.6332283,29.1583289 14.5447738,29.4372129 C14.0436421,31.0172078 13.5561217,31.75 12.5,31.75 C11.442969,31.75 10.9560197,31.0169773 10.4555818,29.43537 C10.3679426,29.158391 10.0278326,27.9978107 9.93932756,27.7122686 C9.716276,26.9926416 9.50359193,26.4011478 9.25488529,25.8383994 C9.16997702,25.6462775 9.08187428,25.4602801 8.99003222,25.2798838 C3.29099207,23.7188867 -0.75,18.5150337 -0.75,12.5 C-0.75,5.18222707 5.18222707,-0.75 12.5,-0.75 C19.8177729,-0.75 25.75,5.18222707 25.75,12.5 C25.75,18.5143856 21.7098013,23.7180578 16.0122576,25.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${colors[1] || colors[0]}"></path>
          <path d="M16.0122576,31.2793186 C15.9213332,31.458076 15.8340571,31.6423215 15.7499008,31.8325638 C15.5004115,32.3965546 15.2870257,32.9892854 15.0630566,33.710743 C14.9749281,33.994626 14.6332283,35.1583289 14.5447738,35.4372129 C14.0436421,37.0172078 13.5561217,37.75 12.5,37.75 C11.442969,37.75 10.9560197,37.0169773 10.4555818,35.43537 C10.3679426,35.158391 10.0278326,33.9978107 9.93932756,33.7122686 C9.716276,32.9926416 9.50359193,32.4011478 9.25488529,31.8383994 C9.16997702,31.6462775 9.08187428,31.4602801 8.99003222,31.2798838 C3.29099207,29.7188867 -0.75,24.5150337 -0.75,18.5 C-0.75,11.1822271 5.18222707,5.25 12.5,5.25 C19.8177729,5.25 25.75,11.1822271 25.75,18.5 C25.75,24.5143856 21.7098013,29.7180578 16.0122576,31.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${colors[0]}"></path>
        </g>
      </g>
    </g>
  </svg>
`;

// prettier-ignore
const cluster3Source = (colors: string[]) => `
  <svg width="29px" height="49px" viewBox="0 0 29 49" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <filter x="-27.3%" y="-100.0%" width="154.5%" height="300.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
      </filter>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-874.000000, -210.000000)">
        <g transform="translate(876.000000, 212.000000)">
          <ellipse fill-opacity="0.2" fill="#000000" filter="url(#filter-1)" cx="12.5" cy="44" rx="5.5" ry="1.5"></ellipse>
          <path d="M16.0122576,25.2793186 C15.9213332,25.458076 15.8340571,25.6423215 15.7499008,25.8325638 C15.5004115,26.3965546 15.2870257,26.9892854 15.0630566,27.710743 C14.9749281,27.994626 14.6332283,29.1583289 14.5447738,29.4372129 C14.0436421,31.0172078 13.5561217,31.75 12.5,31.75 C11.442969,31.75 10.9560197,31.0169773 10.4555818,29.43537 C10.3679426,29.158391 10.0278326,27.9978107 9.93932756,27.7122686 C9.716276,26.9926416 9.50359193,26.4011478 9.25488529,25.8383994 C9.16997702,25.6462775 9.08187428,25.4602801 8.99003222,25.2798838 C3.29099207,23.7188867 -0.75,18.5150337 -0.75,12.5 C-0.75,5.18222707 5.18222707,-0.75 12.5,-0.75 C19.8177729,-0.75 25.75,5.18222707 25.75,12.5 C25.75,18.5143856 21.7098013,23.7180578 16.0122576,25.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${colors[2] || colors[1] || colors[0]}"></path>
          <path d="M16.0122576,31.2793186 C15.9213332,31.458076 15.8340571,31.6423215 15.7499008,31.8325638 C15.5004115,32.3965546 15.2870257,32.9892854 15.0630566,33.710743 C14.9749281,33.994626 14.6332283,35.1583289 14.5447738,35.4372129 C14.0436421,37.0172078 13.5561217,37.75 12.5,37.75 C11.442969,37.75 10.9560197,37.0169773 10.4555818,35.43537 C10.3679426,35.158391 10.0278326,33.9978107 9.93932756,33.7122686 C9.716276,32.9926416 9.50359193,32.4011478 9.25488529,31.8383994 C9.16997702,31.6462775 9.08187428,31.4602801 8.99003222,31.2798838 C3.29099207,29.7188867 -0.75,24.5150337 -0.75,18.5 C-0.75,11.1822271 5.18222707,5.25 12.5,5.25 C19.8177729,5.25 25.75,11.1822271 25.75,18.5 C25.75,24.5143856 21.7098013,29.7180578 16.0122576,31.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${colors[1] || colors[0]}"></path>
          <path d="M16.0122576,37.2793186 C15.9213332,37.458076 15.8340571,37.6423215 15.7499008,37.8325638 C15.5004115,38.3965546 15.2870257,38.9892854 15.0630566,39.710743 C14.9749281,39.994626 14.6332283,41.1583289 14.5447738,41.4372129 C14.0436421,43.0172078 13.5561217,43.75 12.5,43.75 C11.442969,43.75 10.9560197,43.0169773 10.4555818,41.43537 C10.3679426,41.158391 10.0278326,39.9978107 9.93932756,39.7122686 C9.716276,38.9926416 9.50359193,38.4011478 9.25488529,37.8383994 C9.16997702,37.6462775 9.08187428,37.4602801 8.99003222,37.2798838 C3.29099207,35.7188867 -0.75,30.5150337 -0.75,24.5 C-0.75,17.1822271 5.18222707,11.25 12.5,11.25 C19.8177729,11.25 25.75,17.1822271 25.75,24.5 C25.75,30.5143856 21.7098013,35.7180578 16.0122576,37.2793186 Z" stroke="#FFFFFF" stroke-width="1.5" fill="${colors[0]}"></path>
        </g>
      </g>
    </g>
  </svg>
`;

const premiumMarkerSource = (color: string) => `
  <svg width="42px" height="53px" viewBox="0 0 42 53" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-27.3%" y="-100.0%" width="154.5%" height="300.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
      </filter>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-902.000000, -372.000000)">
        <g transform="translate(904.000000, 374.000000)">
          <ellipse fill-opacity="0.2" fill="#000000" filter="url(#filter-1)" cx="19.5" cy="47.5" rx="5.5" ry="1.5"></ellipse>
          <path d="M23.9498105,38.7504836 C23.7879426,38.9868806 23.6346932,39.2173505 23.4889148,39.4439608 C23.2153234,39.8692544 22.9718498,40.3292346 22.7501674,40.8304653 C22.5006282,41.3946809 22.2872088,41.9876513 22.0632271,42.7093655 C21.9750332,42.9935438 21.6333465,44.1576014 21.5449304,44.436465 C21.0438003,46.017025 20.5563014,46.75 19.5,46.75 C18.442969,46.75 17.9560197,46.0169773 17.4555818,44.43537 C17.3679426,44.158391 17.0278326,42.9978107 16.9393276,42.7122686 C16.716276,41.9926416 16.5035919,41.4011478 16.2548853,40.8383994 C16.0335246,40.3375266 15.7903139,39.8779241 15.5169135,39.4530198 C15.3691813,39.2234223 15.2137751,38.9898533 15.0494942,38.7501525 L5,38.75 C1.82436269,38.75 -0.75,36.1756373 -0.75,33 L-0.75,5 C-0.75,1.82436269 1.82436269,-0.75 5,-0.75 L33,-0.75 C36.1756373,-0.75 38.75,1.82436269 38.75,5 L38.75,33 C38.75,36.1756373 36.1756373,38.75 33.0000401,38.75 L23.9498105,38.7504836 Z" id="Combined-Shape-Copy" stroke="#FFFFFF" stroke-width="1.5" fill="${color}"></path>
          <rect fill="#FFFFFF" x="3" y="3" width="32" height="32" rx="3"></rect>
        </g>
      </g>
    </g>
  </svg>
`

const getImageFromSVGSource = (svgSource: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()

    image.onerror = reject
    image.onload = () => resolve(image)

    const svg = new Blob([svgSource], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svg)
    image.src = url
  })

const getPremiumImage = async (publicId?: string): Promise<HTMLImageElement | null> => {
  if (!publicId) {
    return null
  }

  return new Promise(resolve => {
    const image = new Image()

    image.onerror = () => resolve(null)
    image.onload = () => resolve(image)

    image.crossOrigin = 'Anonymous'
    image.width = premiumImageSize
    image.height = premiumImageSize

    image.src = mediaSrc({
      publicId: publicId.replace(/(\.jpg)?$/, '.png'),
      dpr: '3.0',
      width: premiumImageSize,
      height: premiumImageSize,
      radius: 3,
    })
  })
}

const icons = {
  '': defaultIcon,
  restaurant: restaurantIcon,
  theatre: theatreIcon,
  'town-hall': townHallIcon,
  shop: shopIcon,
  lodging: lodgingIcon,
  farm: farmIcon,
}

const getIconImage = async (icon?: string): Promise<HTMLImageElement | null> => {
  if (!icon) {
    return null
  }

  return getImageFromSVGSource(icons[icon])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMarker = async ({ width, height, src, publicId, icon }) => {
  const [markerImage, iconImage, premiumImage] = await Promise.all([
    getImageFromSVGSource(src),
    getIconImage(icon),
    getPremiumImage(publicId),
  ])

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx && markerImage) {
    ctx.drawImage(markerImage, 0, 0)

    if (iconImage) {
      ctx.drawImage(iconImage, 7, 7)
    }

    if (premiumImage) {
      ctx.drawImage(premiumImage, 5, 5, premiumImageSize, premiumImageSize)
    }

    return ctx.getImageData(0, 0, width, height)
  }

  return null
}

const getTransparentPlaceholderImage = ({ width, height }) => {
  const bytesPerPixel = 4
  const data = new Uint8Array(width * height * bytesPerPixel)
  data.fill(0)

  return data
}

interface MarkerData {
  height: number
  width: number
  src: string
  icon?: string
  publicId?: string
}

const parseMarkerData = (name: string): MarkerData => {
  if (name.startsWith('premium-marker')) {
    const [, color, publicId] = name.split('_')

    return { width: 42, height: 53, src: premiumMarkerSource(color), publicId }
  } else if (/^marker_.+?(_#\w+?)+$/.test(name)) {
    const [, icon, ...allColors] = name.split('_')
    const colors = uniq(allColors)

    switch (icon) {
      case 'cluster2':
        return { width: 29, height: 43, src: cluster2Source(colors) }
      case 'cluster3':
        return { width: 29, height: 49, src: cluster3Source(colors) }
      default:
        return { width: 29, height: 38, src: markerSvgSource(colors[0]), icon }
    }
  }

  throw new Error(`Could not generate image for ${name}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addMissingMarker = ({ map, name }) => {
  try {
    const { width, height, src, icon, publicId } = parseMarkerData(name)
    const placeholder = getTransparentPlaceholderImage({ width, height })

    map.addImage(name, { width, height, data: placeholder })

    getMarker({ width, height, icon, publicId, src }).then(image => map.updateImage(name, image))
  } catch (error) {
    console.error(error.message)
  }
}
