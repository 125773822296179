import get from 'lodash/get'
import * as React from 'react'
import BodyClassName from 'react-body-classname'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SimpleSchema from '../../../schemas/schema'

import { Event, Place } from '../../../models'
import Routes from '../../../startup/routes'
import { getTimeFromNow } from '../../../util/dateTime'
import { createMarkup } from '../../helpers/helpers'
import Mapbox from '../mapbox/Mapbox'
import Loading from '../misc/Loading'
import ResponsiveImage, { mediaSrc } from '../misc/MediaPresets'
import MediaSlider from '../misc/MediaSlider'
import { ActionControls } from '../misc/RecordView'
import PlaceMarker from '../places/PlaceMarker'

const socialLinkUrl = (socialNetwork, url): string => {
  if (socialNetwork === 'twitter') {
    const regexTokens = SimpleSchema.RegEx.Url.exec(url)
    // regex is not an URL
    if (regexTokens == null) {
      return `https://twitter.com/${url}`
    }
  }

  return url
}

const socialLinkText = (socialNetwork, url): string => {
  switch (socialNetwork) {
    case 'facebook':
      return 'Find us on Facebook'
    case 'googleplus':
      return 'Find us on Google+'
    case 'instagram':
      return 'Follow us on Instagram'
    case 'linkedin':
      return 'Connect on LinkedIn'
    case 'newsletter':
      return 'Subscribe to our newsletter'
    case 'pinterest':
      return 'Follow us on Pinterest'
    case 'twitter':
      return url
    case 'youtube':
      return 'Follow our YouTube channel'
    default:
      return socialNetwork
  }
}

const MetaData: React.FC<{ place: void | Place }> = ({ place }) => {
  if (!place) {
    return null
  }

  return (
    <Helmet>
      <title>{place.title}</title>
      {place.mainImage && <meta property="og:image" content={mediaSrc(place.mainImage)} />}
      {place && <link rel="canonical" href={Routes.customUrl(place.community, Routes.premiumPlace(place, place.community))} />}
    </Helmet>
  )
}

const PlaceMediaSlider: React.FC<{ place: void | Place }> = ({ place }) => {
  if (!place) {
    return null
  }

  const extraMedia = Array.isArray(place.media) ? place.media : []
  const mediaToRender = extraMedia.map(media => (media.publicId ? media : null)).filter(Boolean)
  if (mediaToRender.length) {
    const media = [place.mainImage, ...mediaToRender]
    return <MediaSlider media={media} className="place-slideshow" />
  }

  return (
    <div className="place-image">
      <ResponsiveImage publicId={get(place, 'mainImage.publicId')} />
    </div>
  )
}

const PlacePhone: React.FC<{ place: void | Place }> = ({ place }) => {
  if (place && place.contact && place.contact.phone) {
    return (
      <div className="place-phone iconWithText place-details-item">
        <i className="icon-phone-purple" />
        {place.contact.phone}
      </div>
    )
  }
  return null
}

const PlaceAddress: React.FC<{ place: void | Place }> = ({ place }) => {
  if (!place) {
    return null
  }

  const address = place.address || { streetAddress: 'Unknown address' }
  const { streetAddress, streetAddress2 = '', city = '', state = '', zipCode = '' } = address

  return (
    <div className="place-address iconWithText place-details-item">
      <i className="icon-pin-green" />
      {[streetAddress, streetAddress2].filter(Boolean).join(', ')}
      <br />
      {[[city, state].filter(Boolean).join(', '), zipCode].filter(Boolean).join(' ')}
    </div>
  )
}

const PlaceWeb: React.FC<{ place: void | Place }> = ({ place }) => {
  if (place && place.webUrl) {
    return (
      <div className="place-website iconWithText place-details-item">
        <i className="icon-link-yellow" />
        <a target="_blank" rel="noopener noreferrer" href={place.webUrl}>
          Visit our Website
        </a>
      </div>
    )
  }
  return null
}

const UpcomingEvents: React.FC<{ events: void | Event[]; communityIdParam: void | string }> = ({ events, communityIdParam }) => {
  if (events && events.length) {
    return (
      <aside className="place-upcoming-events">
        <h5 className="place-upcoming-heading">
          <i className="icon-calendar-red" />
          Upcoming Events
        </h5>

        <ul className="place-events-list">
          {events.map(event => (
            <li key={event.id} className="place-event place1">
              <Link to={Routes.event(event, communityIdParam)}>
                <div className="place-event-text">
                  <h2 className="place-event-heading">{event.title}</h2>
                  {event.startAt ? (
                    <p className="place-event-meta">
                      <i className="icon-watch-yellow" />
                      {getTimeFromNow(event.startAt)}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className="place-event-image">
                  <ResponsiveImage publicId={get(event, 'image.publicId')} width="90" height="60" />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </aside>
    )
  }
  return null
}

const PlaceSocialIcons: React.FC<{ place?: Place }> = ({ place }) => {
  if (place && place.socialUrls) {
    const socialUrls = place.socialUrls
    const alphabeticalUrls = {}

    /* social URL properties are added to the place.socialUrls object in the order the user
      adds them to the form, alphabetizing the properties ensures the social icons are always
      rendered in the same order. */
    Object.keys(socialUrls)
      .sort()
      .forEach(key => {
        alphabeticalUrls[key] = socialUrls[key]
      })

    const iconLinks = Object.keys(alphabeticalUrls).map(key => {
      const iconName = key
      const url = alphabeticalUrls[key]

      return url ? (
        <a key={key} target="_blank" rel="noopener noreferrer" href={socialLinkUrl(key, url)}>
          <i className={`icon-${iconName}`} />
          {socialLinkText(key, url)}
        </a>
      ) : null
    })

    return <div className="place-social-links place-details-item">{iconLinks}</div>
  }
  return null
}

const PlaceHours: React.FC<{ place?: Place }> = ({ place }) => {
  if (place && place.operatingHours) {
    const dailyHoursHtml = place.operatingHours.labelGroups.map(({ days, label }, index) => (
      <div key={index}>
        <dt>{days}:&nbsp;</dt>
        <dd>{label}</dd>
      </div>
    ))

    return (
      <aside>
        <h2 className="place-subhead">Hours</h2>

        <dl className="place-hours">{dailyHoursHtml}</dl>
      </aside>
    )
  }
  return null
}

const animationOptions = { duration: 800 }

interface Props {
  place?: Place
  loading?: boolean
  events?: Event[]
  communityIdParam?: string
}

const PlaceView: React.FC<Props> = ({ place, loading, events, communityIdParam }) => (
  <BodyClassName className="path-places">
    <div>
      <MetaData place={place} />
      {loading && <Loading className="isCentered isDark" />}
      <PlaceMediaSlider place={place} />
      <article className="two-col place-view">
        <div className="place-main">
          <h1 className="place-heading">{place && place.title}</h1>

          <header className="place-header">
            <h4 className="place-short-desc">{place && place.shortDescription}</h4>

            <div className="place-details row">
              <div className="small-12 medium-5 column">
                <PlacePhone place={place} />
                <PlaceAddress place={place} />
                {place && <ActionControls record={place} />}
              </div>
              <div className="small-12 medium-7 column">
                <PlaceWeb place={place} />
                <PlaceSocialIcons place={place} />
              </div>
            </div>
          </header>

          <h2 className="place-subhead">About {place && place.title}</h2>
          {place && <div className="place-long-desc" dangerouslySetInnerHTML={createMarkup(place.longDescription)} />}
        </div>
        <div className="place-sidebar">
          {place && <UpcomingEvents events={events} communityIdParam={place.community ? place.community.slug : communityIdParam} />}
          <Link to={place ? Routes.place(place, place.community) : Routes.places(communityIdParam)}>
            <Mapbox className="place-map" center={get(place, 'geo.geometry.coordinates')} animationOptions={animationOptions}>
              {place && place.geo && <PlaceMarker place={place} />}
            </Mapbox>
          </Link>
          <PlaceHours place={place} />
        </div>
      </article>
    </div>
  </BodyClassName>
)

export default PlaceView
