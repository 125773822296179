import { applyMixins } from '../../util/mixins'
import { placeCategoryIconMapping } from '../../util/placeCategoryIconMapping'
import BaseModel from '../model'
import { Address, Contact, Media, Publishable } from '../types'
import { getPlaceAddress } from './helpers'
import OperatingHoursModel from './operatingHoursModel'
import PlaceCategories from './placeCategories'

class Place extends BaseModel {
  public readonly objectType = 'place'
  public readonly __typename = 'Place'

  public address?: Address
  public geo?: GeoJSON.Feature<
    GeoJSON.Point,
    {
      id: string
      title?: string
      category?: string
      description: string
      'marker-color': string
      'marker-size': string
      'marker-symbol': string
    }
  >
  public operatingHours?: OperatingHoursModel
  public socialUrls?: {
    facebook?: string
    googleplus?: string
    instagram?: string
    linkedin?: string
    newsletter?: string
    pinterest?: string
    twitter?: string
    youtube?: string
  }

  public webUrl?: string
  public title?: string
  public shortDescription?: string
  public longDescription?: string
  public category: string

  public mainImage?: Media
  public media?: Media[]

  public contact?: Contact

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor({ id, name, title, geometry, ...doc }) {
    super()
    Object.assign(this, { ...doc, _id: id, id, name: name || title, title: name || title })

    this.category = doc.category || PlaceCategories[0]
    const categoryIconMapping = placeCategoryIconMapping[this.category] || placeCategoryIconMapping['Default']
    if (geometry) {
      this.geo = {
        type: 'Feature',
        geometry,
        properties: {
          id: this.id,
          title: this.title,
          category: this.category,
          description: getPlaceAddress(this.address),
          'marker-color': categoryIconMapping.color,
          'marker-size': this.isPremium ? 'large' : 'medium',
          'marker-symbol': categoryIconMapping.icon,
        },
      }
    }

    this.operatingHours = doc.operatingHours && new OperatingHoursModel(doc.operatingHours)
  }

  public get fullAddress(): string {
    const { address } = this
    // if there's any address field, display that
    if (address && (address.streetAddress || address.streetAddress2 || address.city || address.state || address.zipCode)) {
      return `
        ${address.streetAddress || ''}
        ${address.streetAddress2 || ''}
        ${address.city || ''}
        ${address.state || ''}
        ${address.zipCode || ''}
      `
    }
    return 'Unknown address'
  }

  public get isPremium(): boolean {
    return this.mainImage?.publicId != null
  }

  public get markerSize(): string {
    return this.isPremium ? 'large' : 'medium'
  }
}

export default Place

interface Place extends Publishable {} // eslint-disable-line @typescript-eslint/no-empty-interface
applyMixins(Place, [Publishable])
