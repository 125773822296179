import later from '@breejs/later'
import { RepeatingPatterns, RepeatingPatternsType } from '../../util/constants'

type EventSeriesType = { repeats: RepeatingPatternsType; startAt: Date }

class EventSeries {
  static MAX_EVENTS_IN_SERIES = 64

  constructor({ repeats, startAt }: EventSeriesType) {
    this.repeats = repeats
    this.startAt = startAt
  }

  repeats: RepeatingPatternsType
  startAt: Date

  private getSchedule() {
    const time = this.startAt.toTimeString().split(' ')[0]
    const pattern = later.parse.recur().on(time).time()
    let day
    let dayOfWeek
    let dayOfWeekCount
    switch (this.repeats) {
      case RepeatingPatterns.DAILY:
      case RepeatingPatterns.REQUEST_SCHEDULE:
        return later.schedule(pattern)
      case RepeatingPatterns.WEEKLY:
        dayOfWeek = later.dayOfWeek.val(this.startAt)
        return later.schedule(pattern.on(dayOfWeek).dayOfWeek())
      case RepeatingPatterns.MONTHLY_SAME_DATE:
        day = later.day.val(this.startAt)
        return later.schedule(pattern.on(day).dayOfMonth())
      case RepeatingPatterns.MONTHLY_NTH_DOW:
        dayOfWeek = later.dayOfWeek.val(this.startAt)
        dayOfWeekCount = later.dayOfWeekCount.val(this.startAt)
        return later.schedule(pattern.on(dayOfWeekCount).dayOfWeekCount().on(dayOfWeek).dayOfWeek())
      case RepeatingPatterns.MONTHLY_LAST_DOW:
        dayOfWeek = later.dayOfWeek.val(this.startAt)
        return later.schedule(pattern.on(0).dayOfWeekCount().on(dayOfWeek).dayOfWeek())
      case RepeatingPatterns.WEEKDAYS:
        return later.schedule(pattern.onWeekday())
      // case RepeatingPatterns.ANNUALLY:
      //   return later.schedule(pattern.on);
      default:
        // Days of a week, from 1 to 7. Specify 0 for the last day of the week (Saturday).
        // 1 is Sunday, 2 is Monday, etc.
        return later.schedule(pattern.on(this.repeats).dayOfWeek())
    }
  }

  next(fromDate?: Date): Date {
    // configure later to use local timezone
    later.date.localTime()
    let lastOccurrence
    if (fromDate) {
      lastOccurrence = fromDate
    } else {
      // const lastEvent = Events.findOne({ repeatingEventId: this.id }, { sort: { startAt: -1 }, limit: 1 });
      // lastOccurrence = get(lastEvent, 'startAt');
    }
    if (!lastOccurrence) return this.startAt

    const schedule = this.getSchedule()
    return schedule.next(2, lastOccurrence)[1]
  }
}

export default EventSeries
