import { css } from '@emotion/react'
import Select from 'react-select'
import { connectField } from 'uniforms'

const SelectFromOptions = ({ disabled, id, label, name, onChange, placeholder, value, options = [], multi = false }) => (
  <section
    css={css`
      width: 100%;
      @media screen and (min-width: 768px) {
        flex-wrap: nowrap !important;
      }
    `}
    className="input-row"
  >
    {label && (
      <label
        htmlFor={id}
        css={css`
          @media screen and (min-width: 768px) {
            min-width: 30.1138947236%;
          }
        `}
      >
        {label}*
      </label>
    )}

    <Select
      required
      id={id}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={v => onChange(multi ? v.map(opt => opt.value) : v ? v.value : null)}
      options={options}
      multi={false}
    />
  </section>
)

export default connectField(SelectFromOptions)
