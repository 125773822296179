import { css } from '@emotion/react'
import { colors } from '../../../styles/variables'

const Analytic: React.FC<{ number: number; label: string }> = ({ number, label }) => (
  <div
    css={css`
      border-radius: 8px;
      display: flex;
      align-items: center;

      flex-direction: row;
      gap: 4px;

      @media (min-width: 540px) {
        padding: 8px;
        background-color: ${colors.white};
        border: 1px solid ${colors.silver};
        gap: 0;
        width: 90px;
        flex-direction: column;
      }
      @media (min-width: 768px) {
        gap: 4px;
        padding: 10px;
        width: 100px;
      }
      @media (min-width: 1200px) {
        padding: 20px;
        width: 140px;
      }
    `}
  >
    <span
      css={css`
        font-size: 16px;
        font-weight: 700;

        @media (min-width: 540px) {
          font-size: 18px;
        }
      `}
    >
      {number}
    </span>
    <span
      css={css`
        font-size: 12px;
      `}
    >
      {label}
    </span>
  </div>
)

type Props = {
  numberOfImpressions?: number
  numberOfClicks?: number
}
const Analytics: React.FC<Props> = ({ numberOfImpressions, numberOfClicks }) => (
  <div
    css={css`
      flex: 1;
      display: flex;
      justify-content: center;

      flex-direction: column;
      gap: 2px;
      @media (min-width: 540px) {
        gap: 8px;
        align-items: center;
        flex-direction: row;
      }
      @media (min-width: 768px) {
        gap: 16px;
      }
      margin-right: 16px;
    `}
  >
    <Analytic number={numberOfImpressions || 0} label="Impressions" />
    <Analytic number={numberOfClicks || 0} label="Clicks" />
  </div>
)

export default Analytics
