// In Plasmic window.top === window.parent.parent.parent
// so we use that to say "we're not embedded"
export const isEmbedded = (): boolean => window.top === window.parent.parent && window.self !== window.top

const storageAvailable = (): boolean => {
  try {
    const x = '__storage_test__'
    localStorage.setItem(x, x)
    localStorage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

export class LocalStorage {
  public static getItem(key: string): string | false | null {
    return storageAvailable() && localStorage.getItem(key)
  }

  public static setItem(key: string, value: string): void {
    storageAvailable() && localStorage.setItem(key, value)
  }

  public static removeItem(key: string): false | void {
    return storageAvailable() && localStorage.removeItem(key)
  }
}
