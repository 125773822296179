import Sluggable from '../../models/concerns/sluggable'
import BaseModel from '../model'
import { Media } from '../types'

class Community extends BaseModel implements Sluggable {
  public objectType = 'community'
  public slug: string

  public customUrl?: string
  public displayName?: string
  public placesListMetaDescription?: string
  public placesListMetaKeywords?: string
  public placesListMetaTitle?: string
  public storyCategories?: string[]
  public liveState?: string
  public logo?: Media

  public geo?: GeoJSON.Feature<GeoJSON.Polygon>

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor(doc) {
    super()
    const id = doc.id || doc._id
    Object.assign(this, { ...doc, _id: id, id })

    const { geometry } = doc

    if (geometry) {
      this.geo = { type: 'Feature', geometry, properties: {} }
    }
  }
}

export default Community
