import styled from '@emotion/styled'
import React from 'react'
import { BaseField } from 'uniforms'
import { AutoField } from 'uniforms-unstyled'
import { colors } from '../../../styles/variables'
import { RepeatingPatterns, Weekdays } from '../../../util/constants'

const StyledDays = styled.div`
  display: flex;
  margin-top: 19px;
  margin-bottom: 40px;
`
const StyledDay = styled.div<{ isActive: boolean }>`
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  text-align: center;
  background: #ffffff;
  border: 1px solid ${colors.silver};
  border-radius: 50%;
  margin-right: 9px;
  cursor: pointer;

  ${props =>
    props.isActive &&
    `
    font-weight: 600;
    background: ${colors.primary};
    color: #fff;
    border-color: ${colors.primary};
  `};
`

const WeeklyRepeatingDaysSelector: React.FC = (_props, { uniforms }) => {
  const { model, onChange } = uniforms
  if (model.repeats !== RepeatingPatterns.WEEKLY) {
    return null
  }

  const repeatDays = model.repeatDays || {}

  return (
    <StyledDays>
      {Object.values(Weekdays).map(day => {
        const name = `repeatDays.${day}`
        const value = repeatDays[day]
        return (
          <AutoField key={day} name={name} component={StyledDay} isActive={value} onClick={() => onChange(name, !value)}>
            {day[0]}
          </AutoField>
        )
      })}
    </StyledDays>
  )
}

WeeklyRepeatingDaysSelector.contextTypes = BaseField.contextTypes

export default WeeklyRepeatingDaysSelector
